export const PurchaseOrderConstants = {
    CREATE_PURCHASE_ORDER_REQUEST: 'CREATE_PURCHASE_ORDER_REQUEST',
    CREATE_PURCHASE_ORDER_SUCCESS: 'CREATE_PURCHASE_ORDER_SUCCESS',
    CREATE_PURCHASE_ORDER_FAILURE: 'CREATE_PURCHASE_ORDER_FAILURE',

    UPDATE_PURCHASE_ORDER_REQUEST: 'UPDATE_PURCHASE_ORDER_REQUEST',
    UPDATE_PURCHASE_ORDER_SUCCESS: 'UPDATE_PURCHASE_ORDER_SUCCESS',
    UPDATE_PURCHASE_ORDER_FAILURE: 'UPDATE_PURCHASE_ORDER_FAILURE',

    GET_ALL_PURCHASE_ORDERS_REQUEST: 'GET_ALL_PURCHASE_ORDERS_REQUEST',
    GET_ALL_PURCHASE_ORDERS_SUCCESS: 'GET_ALL_PURCHASE_ORDERS_SUCCESS',
    GET_ALL_PURCHASE_ORDERS_FAILURE: 'GET_ALL_PURCHASE_ORDERS_FAILURE',

    GET_PURCHASE_ORDERS_FOR_PAYMENT_REQUEST: 'GET_PURCHASE_ORDERS_FOR_PAYMENT_REQUEST',
    GET_PURCHASE_ORDERS_FOR_PAYMENT_FAILURE: 'GET_PURCHASE_ORDERS_FOR_PAYMENT_FAILURE',
    GET_PURCHASE_ORDERS_FOR_PAYMENT_SUCCESS: 'GET_PURCHASE_ORDERS_FOR_PAYMENT_SUCCESS',

    APPROVE_PURCHASE_ORDER_REQUEST: 'APPROVE_PURCHASE_ORDER_REQUEST',
    APPROVE_PURCHASE_ORDER_FAILURE: 'APPROVE_PURCHASE_ORDER_FAILURE',
    APPROVE_PURCHASE_ORDER_SUCCESS: 'APPROVE_PURCHASE_ORDER_SUCCESS',
}