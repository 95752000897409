export const SLAConstants = {
    GET_ALL_SLAS_REQUEST: 'GET_ALL_SLAS_REQUEST',
    GET_ALL_SLAS_SUCCESS: 'GET_ALL_SLAS_SUCCESS',
    GET_ALL_SLAS_FAILURE: 'GET_ALL_SLAS_FAILURE',

    CREATE_SLA_REQUEST: 'CREATE_SLA_REQUEST',
    CREATE_SLA_SUCCESS: 'CREATE_SLA_SUCCESS',
    CREATE_SLA_FAILURE: 'CREATE_SLA_FAILURE',

    GET_DETAIL_SLA_REQUEST: 'GET_DETAIL_SLA_REQUEST',
    GET_DETAIL_SLA_FAILURE: 'GET_DETAIL_SLA_FAILURE',
    GET_DETAIL_SLA_SUCCESS: 'GET_DETAIL_SLA_SUCCESS',

    UPDATE_SLA_REQUEST: 'UPDATE_SLA_REQUEST',
    UPDATE_SLA_SUCCESS: 'UPDATE_SLA_SUCCESS',
    UPDATE_SLA_FAILURE: 'UPDATE_SLA_FAILURE',

    DISABLE_SLA_REQUEST: 'DISABLE_SLA_REQUEST',
    DISABLE_SLA_SUCCESS: 'DISABLE_SLA_SUCCESS',
    DISABLE_SLA_FAILURE: 'DISABLE_SLA_FAILURE',

    CHECK_SLA_CODE_REQUEST: 'CHECK_SLA_CODE_REQUEST',
    CHECK_SLA_CODE_SUCCESS: 'CHECK_SLA_CODE_SUCCESS',
    CHECK_SLA_CODE_FAILURE: 'CHECK_SLA_CODE_FAILURE',

    GET_SLA_BY_CODE_REQUEST: 'GET_SLA_BY_CODE_REQUEST',
    GET_SLA_BY_CODE_SUCCESS: 'GET_SLA_BY_CODE_SUCCESS',
    GET_SLA_BY_CODE_FAILURE: 'GET_SLA_BY_CODE_FAILURE',

    DELETE_SLA_BY_CODE_REQUEST: 'DELETE_SLA_BY_CODE_REQUEST',
    DELETE_SLA_BY_CODE_SUCCESS: 'DELETE_SLA_BY_CODE_SUCCESS',
    DELETE_SLA_BY_CODE_FAILURE: 'DELETE_SLA_BY_CODE_FAILURE',
}