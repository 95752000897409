export const CrmCareTypeConstants = {
    GET_CRM_CARETYPES_REQUEST: 'GET_CRM_CARETYPES_REQUEST',
    GET_CRM_CARETYPES_SUCCESS: 'GET_CRM_CARETYPES_SUCCESS',
    GET_CRM_CARETYPES_FAILE: 'GET_CRM_CARETYPES_FAILE',

    GET_CRM_CARETYPE_REQUEST: 'GET_CRM_CARETYPE_REQUEST',
    GET_CRM_CARETYPE_SUCCESS: 'GET_CRM_CARETYPE_SUCCESS',
    GET_CRM_CARETYPE_FAILE: 'GET_CRM_CARETYPE_FAILE',

    CREATE_CRM_CARETYPE_REQUEST: 'CREATE_CRM_CARETYPE_REQUEST',
    CREATE_CRM_CARETYPE_SUCCESS: 'CREATE_CRM_CARETYPE_SUCCESS',
    CREATE_CRM_CARETYPE_FAILE: 'CREATE_CRM_CARETYPE_FAILE',

    EDIT_CRM_CARETYPE_REQUEST: 'EDIT_CRM_CARETYPE_REQUEST',
    EDIT_CRM_CARETYPE_SUCCESS: 'EDIT_CRM_CARETYPE_SUCCESS',
    EDIT_CRM_CARETYPE_FAILE: 'EDIT_CRM_CARETYPE_FAILE',

    DELETE_CRM_CARETYPE_REQUEST: 'DELETE_CRM_CARETYPE_REQUEST',
    DELETE_CRM_CARETYPE_SUCCESS: 'DELETE_CRM_CARETYPE_SUCCESS',
    DELETE_CRM_CARETYPE_FAILE: 'DELETE_CRM_CARETYPE_FAILE',
}