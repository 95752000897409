export const RecommendProcureConstants = {
    GET_RECOMMEND_PROCURE_REQUEST: 'GET_RECOMMEND_PROCURE_REQUEST',
    GET_RECOMMEND_PROCURE_SUCCESS: 'GET_RECOMMEND_PROCURE_SUCCESS',
    GET_RECOMMEND_PROCURE_FAILURE: 'GET_RECOMMEND_PROCURE_FAILURE',

    CREATE_RECOMMEND_PROCURE_REQUEST: 'CREATE_NEW_RECOMMEND_PROCURE_REQUEST',
    CREATE_RECOMMEND_PROCURE_SUCCESS: 'CREATE_NEW_RECOMMEND_PROCURE_SUCCESS',
    CREATE_RECOMMEND_PROCURE_FAILURE: 'CREATE_NEW_RECOMMEND_PROCURE_FAILURE',

    UPDATE_RECOMMEND_PROCURE_REQUEST: 'UPDATE_RECOMMEND_PROCURE_REQUEST',
    UPDATE_RECOMMEND_PROCURE_SUCCESS: 'UPDATE_RECOMMEND_PROCURE_SUCCESS',
    UPDATE_RECOMMEND_PROCURE_FAILURE: 'UPDATE_RECOMMEND_PROCURE_FAILURE',

    DELETE_RECOMMEND_PROCURE_REQUEST: 'DELETE_RECOMMEND_PROCURE_REQUEST',
    DELETE_RECOMMEND_PROCURE_SUCCESS: 'DELETE_RECOMMEND_PROCURE_SUCCESS',
    DELETE_RECOMMEND_PROCURE_FAILURE: 'DELETE_RECOMMEND_PROCURE_FAILURE',

    GET_USER_APPROVER_REQUEST: 'GET_USER_APPROVER_REQUEST',
    GET_USER_APPROVER_SUCCESS: 'GET_USER_APPROVER_SUCCESS',
    GET_USER_APPROVER_FAILURE: 'GET_USER_APPROVER_FAILURE',
}