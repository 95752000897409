export const homeConstants = {
    GET_NEWSFEED_REQUEST: 'GET_NEWSFEED_REQUEST',
    GET_NEWSFEED_SUCCESS: 'GET_NEWSFEED_SUCCESS',
    GET_NEWSFEED_FAILURE: 'GET_NEWSFEED_FAILURE',

    RECEIVE_NEWSFEED_SUCCESS: 'RECEIVE_NEWSFEED_SUCCESS',

    CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
    CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
    CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',

    EDIT_COMMENT_REQUEST:'EDIT_COMMENT_REQUEST',
    EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
    EDIT_COMMENT_FAILURE: 'EDIT_COMMENT_FAILURE',

    DELETE_COMMENT_REQUEST:'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

    DELETE_FILE_COMMENT_REQUEST: 'DELETE_FILE_COMMENT_REQUEST',
    DELETE_FILE_COMMENT_SUCCESS: 'DELETE_FILE_COMMENT_SUCCESS',
    DELETE_FILE_COMMENT_FAILURE: 'DELETE_FILE_COMMENT_FAILURE'
}