export const createKpiSetConstants = {
    GET_EMPLOYEE_KPI_SET_REQUEST: 'GET_EMPLOYEE_KPI_SET_REQUEST',
    GET_EMPLOYEE_KPI_SET_SUCCESS: 'GET_EMPLOYEE_KPI_SET_SUCCESS',
    GET_EMPLOYEE_KPI_SET_FAILURE: 'GET_EMPLOYEE_KPI_SET_FAILURE',

    GET_ALL_EMPLOYEE_KPI_SET_BY_MONTH_REQUEST: 'GET_ALL_EMPLOYEE_KPI_SET_BY_MONTH_REQUEST',
    GET_ALL_EMPLOYEE_KPI_SET_BY_MONTH_SUCCESS: 'GET_ALL_EMPLOYEE_KPI_SET_BY_MONTH_SUCCESS',
    GET_ALL_EMPLOYEE_KPI_SET_BY_MONTH_FAILURE: 'GET_ALL_EMPLOYEE_KPI_SET_BY_MONTH_FAILURE',

    DELETE_EMPLOYEE_KPI_REQUEST: 'DELETE_EMPLOYEE_KPI_REQUEST',
    DELETE_EMPLOYEE_KPI_SUCCESS: 'DELETE_EMPLOYEE_KPI_SUCCESS',
    DELETE_EMPLOYEE_KPI_FAILURE: 'DELETE_EMPLOYEE_KPI_FAILURE',

    EDIT_EMPLOYEE_KPI_SET_REQUEST: 'EDIT_EMPLOYEE_KPI_SET_REQUEST',
    EDIT_EMPLOYEE_KPI_SET_SUCCESS: 'EDIT_EMPLOYEE_KPI_SET_SUCCESS',
    EDIT_EMPLOYEE_KPI_SET_FAILURE: 'EDIT_EMPLOYEE_KPI_SET_FAILURE',

    DELETE_EMPLOYEE_KPI_SET_REQUEST: 'DELETE_EMPLOYEE_KPI_SET_REQUEST',
    DELETE_EMPLOYEE_KPI_SET_SUCCESS: 'DELETE_EMPLOYEE_KPI_SET_SUCCESS',
    DELETE_EMPLOYEE_KPI_SET_FAILURE: 'DELETE_EMPLOYEE_KPI_SET_FAILURE',

    UPDATE_EMPLOYEE_KPI_SET_STATUS_REQUEST: 'UPDATE_EMPLOYEE_KPI_SET_STATUS_REQUEST',
    UPDATE_EMPLOYEE_KPI_SET_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_KPI_SET_STATUS_SUCCESS',
    UPDATE_EMPLOYEE_KPI_SET_STATUS_FAILURE: 'UPDATE_EMPLOYEE_KPI_SET_STATUS_FAILURE',

    GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNITS_BY_MONTH_REQUEST: 'GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNITS_BY_MONTH_REQUEST',
    GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNITS_BY_MONTH_SUCCESS: 'GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNITS_BY_MONTH_SUCCESS',
    GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNITS_BY_MONTH_FAILURE: 'GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNITS_BY_MONTH_FAILURE',

    CREATE_EMPLOYEE_KPI_REQUEST: 'CREATE_EMPLOYEE_KPI_REQUEST',
    CREATE_EMPLOYEE_KPI_SUCCESS: 'CREATE_EMPLOYEE_KPI_SUCCESS',
    CREATE_EMPLOYEE_KPI_FAILURE: 'CREATE_EMPLOYEE_KPI_FAILURE',

    EDIT_EMPLOYEE_KPI_REQUEST: 'EDIT_EMPLOYEE_KPI_REQUEST',
    EDIT_EMPLOYEE_KPI_SUCCESS: 'EDIT_EMPLOYEE_KPI_SUCCESS',
    EDIT_EMPLOYEE_KPI_FAILURE: 'EDIT_EMPLOYEE_KPI_FAILURE',

    CREATE_EMPLOYEE_KPI_SET_REQUEST: 'CREATE_EMPLOYEE_KPI_SET_REQUEST',
    CREATE_EMPLOYEE_KPI_SET_SUCCESS: 'CREATE_EMPLOYEE_KPI_SET_SUCCESS',
    CREATE_EMPLOYEE_KPI_SET_FAILURE: 'CREATE_EMPLOYEE_KPI_SET_FAILURE',

    APPROVE_EMPLOYEE_KPI_SET_REQUEST: 'APPROVE_EMPLOYEE_KPI_SET_REQUEST',
    APPROVE_EMPLOYEE_KPI_SET_SUCCESS: 'APPROVE_EMPLOYEE_KPI_SET_SUCCESS',
    APPROVE_EMPLOYEE_KPI_SET_FAILURE: 'APPROVE_EMPLOYEE_KPI_SET_FAILURE',

    CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
    CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
    CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',

    CREATE_COMMENT_OF_COMMENT_REQUEST: 'CREATE_COMMENT_OF_COMMENT_REQUEST',
    CREATE_COMMENT_OF_COMMENT_SUCCESS: 'CREATE_COMMENT_OF_COMMENT_SUCCESS',
    CREATE_COMMENT_OF_COMMENT_FAILURE: 'CREATE_COMMENT_OF_COMMENT_FAILURE',

    EDIT_COMMENT_REQUEST: 'EDIT_COMMENT_REQUEST',
    EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
    EDIT_COMMENT_FAILURE: 'EDIT_COMMENT_FAILURE',

    DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

    EDIT_COMMENT_OF_COMMENT_REQUEST: 'EDIT_COMMENT_OF_COMMENT_REQUEST',
    EDIT_COMMENT_OF_COMMENT_SUCCESS: 'EDIT_COMMENT_OF_COMMENT_SUCCESS',
    EDIT_COMMENT_OF_COMMENT_FAILURE: 'EDIT_COMMENT_OF_COMMENT_FAILURE',

    DELETE_COMMENT_OF_COMMENT_REQUEST: 'DELETE_COMMENT_OF_COMMENT_REQUEST',
    DELETE_COMMENT_OF_COMMENT_SUCCESS: 'DELETE_COMMENT_OF_COMMENT_SUCCESS',
    DELETE_COMMENT_OF_COMMENT_FAILURE: 'DELETE_COMMENT_OF_COMMENT_FAILURE',

    DELETE_FILE_COMMENT_REQUEST: 'DELETE_FILE_COMMENT_REQUEST',
    DELETE_FILE_COMMENT_SUCCESS: 'DELETE_FILE_COMMENT_SUCCESS',
    DELETE_FILE_COMMENT_FAILURE: 'DELETE_FILE_COMMENT_FAILURE',

    DELETE_FILE_CHILD_COMMENT_REQUEST: 'DELETE_FILE_CHILD_COMMENT_REQUEST',
    DELETE_FILE_CHILD_COMMENT_SUCCESS: 'DELETE_FILE_CHILD_COMMENT_SUCCESS',
    DELETE_FILE_CHILD_COMMENT_FAILURE: 'DELETE_FILE_CHILD_COMMENT_FAILURE',

}