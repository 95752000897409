import { StatisticsOfOrganizationalUnitKpiConstants } from "./constants";

const initState = {
    isLoading: false,
    error: null
}

export function statisticsOfOrganizationalUnitKpi(state = initState, action) {
    switch (action.type) {
        default:
            return state
    }
}