export const DiscountConstants = {
    GET_ALL_DISCOUNTS_REQUEST: 'GET_ALL_DISCOUNTS_REQUEST',
    GET_ALL_DISCOUNTS_SUCCESS: 'GET_ALL_DISCOUNTS_SUCCESS',
    GET_ALL_DISCOUNTS_FAILURE: 'GET_ALL_DISCOUNTS_FAILURE',

    CREATE_DISCOUNT_REQUEST: 'CREATE_DISCOUNT_REQUEST',
    CREATE_DISCOUNT_SUCCESS: 'CREATE_DISCOUNT_SUCCESS',
    CREATE_DISCOUNT_FAILURE: 'CREATE_DISCOUNT_FAILURE',
    
    EDIT_DISCOUNT_REQUEST: 'EDIT_DISCOUNT_REQUEST',
    EDIT_DISCOUNT_SUCCESS: 'EDIT_DISCOUNT_SUCCESS',
    EDIT_DISCOUNT_FAILURE: 'EDIT_DISCOUNT_FAILURE',
    
    CHANGE_DISCOUNT_STATUS_REQUEST: 'CHANGE_DISCOUNT_STATUS_REQUEST',
    CHANGE_DISCOUNT_STATUS_SUCCESS: 'CHANGE_DISCOUNT_STATUS_SUCCESS',
    CHANGE_DISCOUNT_STATUS_FAILURE: 'CHANGE_DISCOUNT_STATUS_FAILURE',
    
    GET_DISCOUNT_BY_CODE_REQUEST: 'GET_DISCOUNT_BY_CODE_REQUEST',
    GET_DISCOUNT_BY_CODE_SUCCESS: 'GET_DISCOUNT_BY_CODE_SUCCESS',
    GET_DISCOUNT_BY_CODE_FAILURE: 'GET_DISCOUNT_BY_CODE_FAILURE',

    DELETE_DISCOUNT_REQUEST: 'DELETE_DISCOUNT_REQUEST',
    DELETE_DISCOUNT_FAILURE: 'DELETE_DISCOUNT_FAILURE',
    DELETE_DISCOUNT_SUCCESS: 'DELETE_DISCOUNT_SUCCESS',

    GET_DISCOUNT_BY_ORDER_VALUE_REQUEST: 'GET_DISCOUNT_BY_ORDER_VALUE_REQUEST',
    GET_DISCOUNT_BY_ORDER_VALUE_SUCCESS: 'GET_DISCOUNT_BY_ORDER_VALUE_SUCCESS',
    GET_DISCOUNT_BY_ORDER_VALUE_FAILURE: 'GET_DISCOUNT_BY_ORDER_VALUE_FAILURE',
}