export const dashboardOrganizationalUnitKpiConstants = {
    GET_ALL_EMPLOYEEKPI_IN_ORGANIZATIONALUNIT_REQUEST: 'GET_ALL_EMPLOYEEKPI_IN_ORGANIZATIONALUNIT_REQUEST',
    GET_ALL_EMPLOYEEKPI_IN_ORGANIZATIONALUNIT_SUCCESS: 'GET_ALL_EMPLOYEEKPI_IN_ORGANIZATIONALUNIT_SUCCESS',
    GET_ALL_EMPLOYEEKPI_IN_ORGANIZATIONALUNIT_FAILURE: 'GET_ALL_EMPLOYEEKPI_IN_ORGANIZATIONALUNIT_FAILURE',

    GET_ALL_TASK_OF_ORGANIZATIONALUNIT_REQUEST: 'GET_ALL_TASK_OF_ORGANIZATIONALUNIT_REQUEST',
    GET_ALL_TASK_OF_ORGANIZATIONALUNIT_SUCCESS: 'GET_ALL_TASK_OF_ORGANIZATIONALUNIT_SUCCESS',
    GET_ALL_TASK_OF_ORGANIZATIONALUNIT_FAILURE: 'GET_ALL_TASK_OF_ORGANIZATIONALUNIT_FAILURE',

    GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNIT_REQUEST: 'GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNIT_REQUEST',
    GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNIT_SUCCESS: 'GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNIT_SUCCESS',
    GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNIT_FAILURE: 'GET_ALL_EMPLOYEE_KPI_SET_IN_ORGANIZATIONALUNIT_FAILURE',

    GET_ALL_EMPLOYEEKPI_IN_CHILDREN_ORGANIZATIONALUNIT_REQUEST: 'GET_ALL_EMPLOYEEKPI_IN_CHILDREN_ORGANIZATIONALUNIT_REQUEST',
    GET_ALL_EMPLOYEEKPI_IN_CHILDREN_ORGANIZATIONALUNIT_SUCCESS: 'GET_ALL_EMPLOYEEKPI_IN_CHILDREN_ORGANIZATIONALUNIT_SUCCESS',
    GET_ALL_EMPLOYEEKPI_IN_CHILDREN_ORGANIZATIONALUNIT_FAILURE: 'GET_ALL_EMPLOYEEKPI_IN_CHILDREN_ORGANIZATIONALUNIT_FAILURE',

    GET_ALL_TASK_OF_CHILDREN_ORGANIZATIONALUNIT_REQUEST: 'GET_ALL_TASK_OF_CHILDREN_ORGANIZATIONALUNIT_REQUEST',
    GET_ALL_TASK_OF_CHILDREN_ORGANIZATIONALUNIT_SUCCESS: 'GET_ALL_TASK_OF_CHILDREN_ORGANIZATIONALUNIT_SUCCESS',
    GET_ALL_TASK_OF_CHILDREN_ORGANIZATIONALUNIT_FAILURE: 'GET_ALL_TASK_OF_CHILDREN_ORGANIZATIONALUNIT_FAILURE'
}