export const exampleConstants = {
    GET_ALL_EXAMPLES_REQUEST: "GET_ALL_EXAMPLES_REQUEST",
    GET_ALL_EXAMPLES_SUCCESS: "GET_ALL_EXAMPLES_SUCCESS",
    GET_ALL_EXAMPLES_FAILURE: "GET_ALL_EXAMPLES_FAILURE",

    DELETE_EXAMPLE_REQUEST: "DELETE_DELETE_EXAMPLE_REQUEST",
    DELETE_EXAMPLE_SUCCESS: "DELETE_EXAMPLE_SUCCESS",
    DELETE_EXAMPLE_FAILURE: "DELETE_EXAMPLE_FAILURE",

    CREATE_EXAMPLE_REQUEST: "CREATE_EXAMPLE_REQUEST",
    CREATE_EXAMPLE_SUCCESS: "CREATE_EXAMPLE_SUCCESS",
    CREATE_EXAMPLE_FAILURE: "CREATE_EXAMPLE_FAILURE",

    EDIT_EXAMPLE_REQUEST: "EDIT_EXAMPLE_REQUEST",
    EDIT_EXAMPLE_SUCCESS: "EDIT_EXAMPLE_SUCCESS",
    EDIT_EXAMPLE_FAILURE: "EDIT_EXAMPLE_FAILURE",

    GET_DETAIL_EXAMPLE_REQUEST: "GET_DETAIL_EXAMPLE_REQUEST",
    GET_DETAIL_EXAMPLE_SUCCESS: "GET_DETAIL_EXAMPLE_SUCCESS",
    GET_DETAIL_EXAMPLE_FAILURE: "GET_DETAIL_EXAMPLE_FAILURE"


}