export const MajorConstant  = {
    GET_MAJOR_REQUEST: 'GET_MAJOR_REQUEST',
    GET_MAJOR_SUCCESS: 'GET_MAJOR_SUCCESS',
    GET_MAJOR_FAILURE: 'GET_MAJOR_FAILURE',

    CREATE_MAJOR_REQUEST: 'CREATE_NEW_MAJOR_REQUEST',
    CREATE_MAJOR_SUCCESS: 'CREATE_NEW_MAJOR_SUCCESS',
    CREATE_MAJOR_FAILURE: 'CREATE_NEW_MAJOR_FAILURE',

    DELETE_MAJOR_REQUEST: 'DELETE_MAJOR_REQUEST',
    DELETE_MAJOR_SUCCESS:  'DELETE_MAJOR_SUCCESS',
    DELETE_MAJOR_FAILURE:  'DELETE_MAJOR_FAILURE',

    UPDATE_MAJOR_REQUEST: 'UPDATE_MAJOR_REQUEST',
    UPDATE_MAJOR_SUCCESS: 'UPDATE_MAJOR_SUCCESS',
    UPDATE_MAJOR_FAILURE: 'UPDATE_MAJOR_FAILURE',

};