export const PurchaseInvoiceConstants = {
    SEARCH_PURCHASE_INVOICE_REQUEST: 'SEARCH_PURCHASE_INVOICE_REQUEST',
    SEARCH_PURCHASE_INVOICE_SUCCESS: 'SEARCH_PURCHASE_INVOICE_SUCCESS',
    SEARCH_PURCHASE_INVOICE_FAILURE: 'SEARCH_PURCHASE_INVOICE_FAILURE',

    CREATE_PURCHASE_INVOICES_REQUEST: 'CREATE_PURCHASE_INVOICES_REQUEST',
    CREATE_PURCHASE_INVOICES_SUCCESS: 'CREATE_PURCHASE_INVOICES_SUCCESS',
    CREATE_PURCHASE_INVOICES_FAILURE: 'CREATE_PURCHASE_INVOICES_FAILURE',

    UPDATE_PURCHASE_INVOICE_REQUEST: 'UPDATE_PURCHASE_INVOICE_REQUEST',
    UPDATE_PURCHASE_INVOICE_SUCCESS: 'UPDATE_PURCHASE_INVOICE_SUCCESS',
    UPDATE_PURCHASE_INVOICE_FAILURE: 'UPDATE_PURCHASE_INVOICE_FAILURE',

    DELETE_PURCHASE_INVOICES_REQUEST: 'DELETE_PURCHASE_INVOICES_REQUEST',
    DELETE_PURCHASE_INVOICES_SUCCESS: 'DELETE_PURCHASE_INVOICES_SUCCESS',
    DELETE_PURCHASE_INVOICES_FAILURE: 'DELETE_PURCHASE_INVOICES_FAILURE',

    GET_PURCHASE_INVOICE_BY_ID_REQUEST: 'GET_PURCHASE_INVOICE_BY_ID_REQUEST',
    GET_PURCHASE_INVOICE_BY_ID_SUCCESS: 'GET_PURCHASE_INVOICE_BY_ID_SUCCESS',
    GET_PURCHASE_INVOICE_BY_ID_FAILURE: 'GET_PURCHASE_INVOICE_BY_ID_FAILURE',
}