export const CrmEvaluationConstants = {
    GET_CRM_EVALUATIONS_REQUEST: 'GET_CRM_EVALUATION_REQUEST',
    GET_CRM_EVALUATIONS_SUCCESS: 'GET_CRM_EVALUATION_SUCCESS',
    GET_CRM_EVALUATIONS_FAILE: 'GET_CRM_EVALUATION_FAILE',

    GET_CRM_CARE_INFO_BY_EMPLOYEE_REQUEST: 'GET_CRM_CARE_INFO_BY_EMPLOYEE_REQUEST',
    GET_CRM_CARE_INFO_BY_EMPLOYEE_SUCCESS: 'GET_CRM_CARE_INFO_BY_EMPLOYEE_SUCCESS',
    GET_CRM_CARE_INFO_BY_EMPLOYEE_FAILE: 'GET_CRM_CARE_INFO_BY_EMPLOYEE_FAILE',

    GET_CRM_CARE_INFO_BY_UNIT_REQUEST: 'GET_CRM_CARE_INFO_BY_UNIT_REQUEST',
    GET_CRM_CARE_INFO_BY_UNIT_SUCCESS: 'GET_CRM_CARE_INFO_BY_UNIT_SUCCESS',
    GET_CRM_CARE_INFO_BY_UNIT_FAILE: 'GET_CRM_CARE_INFO_BY_UNIT_FAILE',

    // CREATE_CRM_CARE_REQUEST: 'CREATE_CRM_CARE_REQUEST',
    // CREATE_CRM_CARE_SUCCESS: 'CREATE_CRM_CARE_SUCCESS',
    // CREATE_CRM_CARE_FAILE: 'CREATE_CRM_CARE_FAILE',

    // EDIT_CRM_CARE_REQUEST: 'EDIT_CRM_CARE_REQUEST',
    // EDIT_CRM_CARE_SUCCESS: 'EDIT_CRM_CARE_SUCCESS',
    // EDIT_CRM_CARE_FAILE: 'EDIT_CRM_CARE_FAILE',

    // DELETE_CRM_CARE_REQUEST: 'DELETE_CRM_CARE_REQUEST',
    // DELETE_CRM_CARE_SUCCESS: 'DELETE_CRM_CARE_SUCCESS',
    // DELETE_CRM_CARE_FAILE: 'DELETE_CRM_CARE_FAILE',
}