export const MaintainanceConstants = {
    
    GET_MAINTAINANCE_REQUEST: 'GET_MAINTAINANCE_REQUEST',
    GET_MAINTAINANCE_SUCCESS: 'GET_MAINTAINANCE_SUCCESS',
    GET_MAINTAINANCE_FAILURE: 'GET_MAINTAINANCE_FAILURE',

    CREATE_MAINTAINANCE_REQUEST: 'CREATE_NEW_MAINTAINANCE_REQUEST',
    CREATE_MAINTAINANCE_SUCCESS: 'CREATE_NEW_MAINTAINANCE_SUCCESS',
    CREATE_MAINTAINANCE_FAILURE: 'CREATE_NEW_MAINTAINANCE_FAILURE',

    UPDATE_MAINTAINANCE_REQUEST: 'UPDATE_MAINTAINANCE_REQUEST',
    UPDATE_MAINTAINANCE_SUCCESS: 'UPDATE_MAINTAINANCE_SUCCESS',
    UPDATE_MAINTAINANCE_FAILURE: 'UPDATE_MAINTAINANCE_FAILURE',

    DELETE_MAINTAINANCE_REQUEST: 'DELETE_MAINTAINANCE_REQUEST',
    DELETE_MAINTAINANCE_SUCCESS: 'DELETE_MAINTAINANCE_SUCCESS',
    DELETE_MAINTAINANCE_FAILURE: 'DELETE_MAINTAINANCE_FAILURE',

};
