export const DisciplineConstants = {
    GET_DISCIPLINE_REQUEST: 'GET_DISCIPLINE_REQUEST',
    GET_DISCIPLINE_SUCCESS: 'GET_DISCIPLINE_SUCCESS',
    GET_DISCIPLINE_FAILURE: 'GET_DISCIPLINE_FAILURE',

    CREATE_DISCIPLINE_REQUEST: 'CREATE_NEW_DISCIPLINE_REQUEST',
    CREATE_DISCIPLINE_SUCCESS: 'CREATE_NEW_DISCIPLINE_SUCCESS',
    CREATE_DISCIPLINE_FAILURE: 'CREATE_NEW_DISCIPLINE_FAILURE',

    DELETE_DISCIPLINE_REQUEST: 'DELETE_DISCIPLINE_REQUEST',
    DELETE_DISCIPLINE_SUCCESS:  'DELETE_DISCIPLINE_SUCCESS',
    DELETE_DISCIPLINE_FAILURE:  'DELETE_DISCIPLINE_FAILURE',

    UPDATE_DISCIPLINE_REQUEST: 'UPDATE_DISCIPLINE_REQUEST',
    UPDATE_DISCIPLINE_SUCCESS: 'UPDATE_DISCIPLINE_SUCCESS',
    UPDATE_DISCIPLINE_FAILURE: 'UPDATE_DISCIPLINE_FAILURE',


    GET_PRAISE_REQUEST: 'GET_PRAISE_REQUEST',
    GET_PRAISE_SUCCESS: 'GET_PRAISE_SUCCESS',
    GET_PRAISE_FAILURE: 'GET_PRAISE_FAILURE',

    CREATE_PRAISE_REQUEST: 'CREATE_NEW_PRAISE_REQUEST',
    CREATE_PRAISE_SUCCESS: 'CREATE_NEW_PRAISE_SUCCESS',
    CREATE_PRAISE_FAILURE: 'CREATE_NEW_PRAISE_FAILURE',

    DELETE_PRAISE_REQUEST: 'DELETE_PRAISE_REQUEST',
    DELETE_PRAISE_SUCCESS:  'DELETE_PRAISE_SUCCESS',
    DELETE_PRAISE_FAILURE:  'DELETE_PRAISE_FAILURE',

    UPDATE_PRAISE_REQUEST: 'UPDATE_PRAISE_REQUEST',
    UPDATE_PRAISE_SUCCESS: 'UPDATE_PRAISE_SUCCESS',
    UPDATE_PRAISE_FAILURE: 'UPDATE_PRAISE_FAILURE',
};