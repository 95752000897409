export const TimesheetsConstants = {
    GET_TIMESHEETS_REQUEST: 'GET_TIMESHEETS_REQUEST',
    GET_TIMESHEETS_SUCCESS: 'GET_TIMESHEETS_SUCCESS',
    GET_TIMESHEETS_FAILURE: 'GET_TIMESHEETS_FAILURE',

    CREATE_TIMESHEETS_REQUEST: 'CREATE_NEW_TIMESHEETS_REQUEST',
    CREATE_TIMESHEETS_SUCCESS: 'CREATE_NEW_TIMESHEETS_SUCCESS',
    CREATE_TIMESHEETS_FAILURE: 'CREATE_NEW_TIMESHEETS_FAILURE',

    DELETE_TIMESHEETS_REQUEST: 'DELETE_TIMESHEETS_REQUEST',
    DELETE_TIMESHEETS_SUCCESS:  'DELETE_TIMESHEETS_SUCCESS',
    DELETE_TIMESHEETS_FAILURE:  'DELETE_TIMESHEETS_FAILURE',

    UPDATE_TIMESHEETS_REQUEST: 'UPDATE_TIMESHEETS_REQUEST',
    UPDATE_TIMESHEETS_SUCCESS: 'UPDATE_TIMESHEETS_SUCCESS',
    UPDATE_TIMESHEETS_FAILURE: 'UPDATE_TIMESHEETS_FAILURE',

    IMPORT_TIMESHEETS_REQUEST: "IMPORT_TIMESHEETS_EMPLOYEE_REQUEST",
    IMPORT_TIMESHEETS_SUCCESS: "IMPORT_TIMESHEETS_EMPLOYEE_SUCCESS",
    IMPORT_TIMESHEETS_FAILURE: "IMPORT_TIMESHEETS_EMPLOYEE_FAILURE",
};