export const delegationConstants = {
    GET_ALL_DELEGATIONS_REQUEST: "GET_ALL_DELEGATIONS_REQUEST",
    GET_ALL_DELEGATIONS_SUCCESS: "GET_ALL_DELEGATIONS_SUCCESS",
    GET_ALL_DELEGATIONS_FAILURE: "GET_ALL_DELEGATIONS_FAILURE",

    CONFIRM_DELEGATION_REQUEST: "CONFIRM_DELEGATION_REQUEST",
    CONFIRM_DELEGATION_SUCCESS: "CONFIRM_DELEGATION_SUCCESS",
    CONFIRM_DELEGATION_FAILURE: "CONFIRM_DELEGATION_FAILURE",

    REJECT_DELEGATION_REQUEST: "REJECT_DELEGATION_REQUEST",
    REJECT_DELEGATION_SUCCESS: "REJECT_DELEGATION_SUCCESS",
    REJECT_DELEGATION_FAILURE: "REJECT_DELEGATION_FAILURE",

    GET_ALL_DELEGATIONS_TASK_REQUEST: "GET_ALL_DELEGATIONS_TASK_REQUEST",
    GET_ALL_DELEGATIONS_TASK_SUCCESS: "GET_ALL_DELEGATIONS_TASK_SUCCESS",
    GET_ALL_DELEGATIONS_TASK_FAILURE: "GET_ALL_DELEGATIONS_TASK_FAILURE",


}