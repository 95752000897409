export const CompanyConstants = {
    GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
    GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
    GET_COMPANIES_FAILURE: 'GET_COMPANIES_FAILURE',

    GET_COMPANIES_PAGINATE_REQUEST: 'GET_COMPANIES_PAGINATE_REQUEST',
    GET_COMPANIES_PAGINATE_SUCCESS: 'GET_COMPANIES_PAGINATE_SUCCESS',
    GET_COMPANIES_PAGINATE_FAILURE: 'GET_COMPANIES_PAGINATE_FAILURE',

    CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST',
    CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
    CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',
    
    EDIT_COMPANY_REQUEST: 'EDIT_COMPANY_REQUEST',
    EDIT_COMPANY_SUCCESS: 'EDIT_COMPANY_SUCCESS',
    EDIT_COMPANY_FAILURE: 'EDIT_COMPANY_FAILURE',   

    GET_LINKS_LIST_OF_COMPANY_REQUEST: 'GET_LINKS_LIST_OF_COMPANY_REQUEST',
    GET_LINKS_LIST_OF_COMPANY_SUCCESS: 'GET_LINKS_LIST_OF_COMPANY_SUCCESS',
    GET_LINKS_LIST_OF_COMPANY_FAILURE: 'GET_LINKS_LIST_OF_COMPANY_FAILURE',

    GET_COMPONENTS_LIST_OF_COMPANY_REQUEST: 'GET_COMPONENTS_LIST_OF_COMPANY_REQUEST',
    GET_COMPONENTS_LIST_OF_COMPANY_SUCCESS: 'GET_COMPONENTS_LIST_OF_COMPANY_SUCCESS',
    GET_COMPONENTS_LIST_OF_COMPANY_FAILURE: 'GET_COMPONENTS_LIST_OF_COMPANY_FAILURE',
    
    GET_LINKS_PAGINATE_OF_COMPANY_REQUEST: 'GET_LINKS_PAGINATE_OF_COMPANY_REQUEST',
    GET_LINKS_PAGINATE_OF_COMPANY_SUCCESS: 'GET_LINKS_PAGINATE_OF_COMPANY_SUCCESS',
    GET_LINKS_PAGINATE_OF_COMPANY_FAILURE: 'GET_LINKS_PAGINATE_OF_COMPANY_FAILURE',
    
    GET_COMPONENTS_PAGINATE_OF_COMPANY_REQUEST: 'GET_COMPONENTS_PAGINATE_OF_COMPANY_REQUEST',
    GET_COMPONENTS_PAGINATE_OF_COMPANY_SUCCESS: 'GET_COMPONENTS_PAGINATE_OF_COMPANY_SUCCESS',
    GET_COMPONENTS_PAGINATE_OF_COMPANY_FAILURE: 'GET_COMPONENTS_PAGINATE_OF_COMPANY_FAILURE',

    ADD_NEW_LINK_FOR_COMPANY_REQUEST: 'ADD_NEW_LINK_FOR_COMPANY_REQUEST',
    ADD_NEW_LINK_FOR_COMPANY_SUCCESS: 'ADD_NEW_LINK_FOR_COMPANY_SUCCESS',
    ADD_NEW_LINK_FOR_COMPANY_FAILURE: 'ADD_NEW_LINK_FOR_COMPANY_FAILURE',
    
    DELETE_LINK_FOR_COMPANY_REQUEST: 'DELETE_LINK_FOR_COMPANY_REQUEST',
    DELETE_LINK_FOR_COMPANY_SUCCESS: 'DELETE_LINK_FOR_COMPANY_SUCCESS',
    DELETE_LINK_FOR_COMPANY_FAILURE: 'DELETE_LINK_FOR_COMPANY_FAILURE',

    ADD_NEW_COMPONENT_FOR_COMPANY_REQUEST: 'ADD_NEW_COMPONENT_FOR_COMPANY_REQUEST',
    ADD_NEW_COMPONENT_FOR_COMPANY_SUCCESS: 'ADD_NEW_COMPONENT_FOR_COMPANY_SUCCESS',
    ADD_NEW_COMPONENT_FOR_COMPANY_FAILURE: 'ADD_NEW_COMPONENT_FOR_COMPANY_FAILURE',

    DELETE_COMPONENT_FOR_COMPANY_REQUEST: 'DELETE_COMPONENT_FOR_COMPANY_REQUEST',
    DELETE_COMPONENT_FOR_COMPANY_SUCCESS: 'DELETE_COMPONENT_FOR_COMPANY_SUCCESS',
    DELETE_COMPONENT_FOR_COMPANY_FAILURE: 'DELETE_COMPONENT_FOR_COMPANY_FAILURE',
    
    GET_IMPORT_CONFIGURATION_REQUEST: 'GET_IMPORT_CONFIGURATION_REQUEST',
    GET_IMPORT_CONFIGURATION_SUCCESS: 'GET_IMPORT_CONFIGURATION_SUCCESS',
    GET_IMPORT_CONFIGURATION_FAILURE: 'GET_IMPORT_CONFIGURATION_FAILURE',

    EDIT_IMPORT_CONFIGURATION_REQUEST: 'EDIT_IMPORT_CONFIGURATION_REQUEST',
    EDIT_IMPORT_CONFIGURATION_SUCCESS: 'EDIT_IMPORT_CONFIGURATION_SUCCESS',
    EDIT_IMPORT_CONFIGURATION_FAILURE: 'EDIT_IMPORT_CONFIGURATION_FAILURE',

    ADD_IMPORT_CONFIGURATION_REQUEST: 'ADD_IMPORT_CONFIGURATION_REQUEST',
    ADD_IMPORT_CONFIGURATION_SUCCESS: 'ADD_IMPORT_CONFIGURATION_SUCCESS',
    ADD_IMPORT_CONFIGURATION_FAILURE: 'ADD_IMPORT_CONFIGURATION_FAILURE',

    UPDATE_COMPANY_LINKS_REQUEST: 'UPDATE_COMPANY_LINKS_REQUEST',
    UPDATE_COMPANY_LINKS_SUCCESS: 'UPDATE_COMPANY_LINKS_SUCCESS',
    UPDATE_COMPANY_LINKS_FAILURE: 'UPDATE_COMPANY_LINKS_FAILURE',

    UPDATE_COMPANY_COMPONENTS_REQUEST: 'UPDATE_COMPANY_COMPONENTS_REQUEST',
    UPDATE_COMPANY_COMPONENTS_SUCCESS: 'UPDATE_COMPANY_COMPONENTS_SUCCESS',
    UPDATE_COMPANY_COMPONENTS_FAILURE: 'UPDATE_COMPANY_COMPONENTS_FAILURE',

    UPLOAD_ORGANIZATIONAL_UNIT_REQUEST: 'UPLOAD_ORGANIZATIONAL_UNIT_REQUEST',
    UPLOAD_ORGANIZATIONAL_UNIT_SUCCESS: 'UPLOAD_ORGANIZATIONAL_UNIT_SUCCESS',
    UPLOAD_ORGANIZATIONAL_UNIT_FAILURE: 'UPLOAD_ORGANIZATIONAL_UNIT_FAILURE',

    GET_COMPANY_INFOMATION_REQUES: 'GET_COMPANY_INFOMATION_REQUES',
    GET_COMPANY_INFOMATION_SUCCESS: 'GET_COMPANY_INFOMATION_SUCCESS',
    GET_COMPANY_INFOMATION_FAILURE: 'GET_COMPANY_INFOMATION_FAILURE',
    
    REQUEST_SERVICE_REQUEST: 'REQUEST_SERVICE_REQUEST',
    REQUEST_SERVICE_SUCCESS: 'REQUEST_SERVICE_SUCCESS',
    REQUEST_SERVICE_FAILURE: 'REQUEST_SERVICE_FAILURE',
}