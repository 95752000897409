export const BillConstants = {

    GET_BILL_BY_TYPE_REQUEST: 'GET_BILL_BY_TYPE_REQUEST',
    GET_BILL_BY_TYPE_SUCCESS: 'GET_BILL_BY_TYPE_SUCCESS',
    GET_BILL_BY_TYPE_FAILURE: 'GET_BILL_BY_TYPE_FAILURE',

    GET_NUMBER_BILL_REQUEST: 'GET_NUMBER_BILL_REQUEST',
    GET_NUMBER_BILL_SUCCESS: 'GET_NUMBER_BILL_SUCCESS',
    GET_NUMBER_BILL_FAILURE: 'GET_NUMBER_BILL_FAILURE',

    GET_PAGINATE_REQUEST: 'GET_PAGINATE_REQUEST',
    GET_PAGINATE_SUCCESS: 'GET_PAGINATE_SUCCESS',
    GET_PAGINATE_FAILURE: 'GET_PAGINATE_FAILURE',

    GET_ALL_BILLS_BY_GROUP_REQUEST: 'GET_ALL_BILLS_BY_GROUP_REQUEST',
    GET_ALL_BILLS_BY_GROUP_SUCCESS: 'GET_ALL_BILLS_BY_GROUP_SUCCESS',
    GET_ALL_BILLS_BY_GROUP_FAILURE: 'GET_ALL_BILLS_BY_GROUP_FAILURE',

    GET_BILL_BY_GOOD_REQUEST: 'GET_BILL_BY_GOOD_REQUEST',
    GET_BILL_BY_GOOD_SUCCESS: 'GET_BILL_BY_GOOD_SUCCESS',
    GET_BILL_BY_GOOD_FAILURE: 'GET_BILL_BY_GOOD_FAILURE',

    GET_BILL_BY_STATUS_REQUEST: 'GET_BILL_BY_STATUS_REQUEST',
    GET_BILL_BY_STATUS_SUCCESS: 'GET_BILL_BY_STATUS_SUCCESS',
    GET_BILL_BY_STATUS_FAILURE: 'GET_BILL_BY_STATUS_FAILURE',

    GET_BILL_BY_COMMAND_REQUEST: 'GET_BILL_BY_COMMAND_REQUEST',
    GET_BILL_BY_COMMAND_SUCCESS: 'GET_BILL_BY_COMMAND_SUCCESS',
    GET_BILL_BY_COMMAND_FAILURE: 'GET_BILL_BY_COMMAND_FAILURE',

    GET_BILL_DETAIL_REQUEST: 'GET_BILL_DETAIL_REQUEST',
    GET_BILL_DETAIL_SUCCESS: 'GET_BILL_DETAIL_SUCCESS',
    GET_BILL_DETAIL_FAILURE: 'GET_BILL_DETAIL_FAILURE',

    CREATE_BILL_REQUEST: 'CREATE_BILL_REQUEST',
    CREATE_BILL_SUCCESS: 'CREATE_BILL_SUCCESS',
    CREATE_BILL_FAILURE: 'CREATE_BILL_FAILURE',

    UPDATE_BILL_REQUEST: 'UPDATE_BILL_REQUEST',
    UPDATE_BILL_SUCCESS: 'UPDATE_BILL_SUCCESS',
    UPDATE_BILL_FAILURE: 'UPDATE_BILL_FAILURE',

    CREATE_MANY_PRODUCT_BILL_REQUEST: 'CREATE_MANY_PRODUCT_BILL_REQUEST',
    CREATE_MANY_PRODUCT_BILL_SUCCESS: 'CREATE_MANY_PRODUCT_BILL_SUCCESS',
    CREATE_MANY_PRODUCT_BILL_FAILURE: 'CREATE_MANY_PRODUCT_BILL_FAILURE'
}
