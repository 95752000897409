export const purchasingRequestConstants = {
    GET_ALL_PURCHASING_REQUEST_REQUEST: 'GET_ALL_PURCHASING_REQUEST_REQUEST',
    GET_ALL_PURCHASING_REQUEST_SUCCESS: 'GET_ALL_PURCHASING_REQUEST_SUCCESS',
    GET_ALL_PURCHASING_REQUEST_FAILURE: 'GET_ALL_PURCHASING_REQUEST_FAILURE',

    CREATE_PURCHASING_REQUEST_REQUEST: 'CREATE_PURCHASING_REQUEST_REQUEST',
    CREATE_PURCHASING_REQUEST_SUCCESS: 'CREATE_PURCHASING_REQUEST_SUCCESS',
    CREATE_PURCHASING_REQUEST_FAILURE: 'CREATE_PURCHASING_REQUEST_FAILURE',

    GET_DETAIL_PURCHASING_REQUEST_REQUEST: 'GET_DETAIL_PURCHASING_REQUEST_REQUEST',
    GET_DETAIL_PURCHASING_REQUEST_SUCCESS: 'GET_DETAIL_PURCHASING_REQUEST_SUCCESS',
    GET_DETAIL_PURCHASING_REQUEST_FAILURE: 'GET_DETAIL_PURCHASING_REQUEST_FAILURE',

    UPDATE_PURCHASING_REQUEST_REQUEST: 'UPDATE_PURCHASING_REQUEST_REQUEST',
    UPDATE_PURCHASING_REQUEST_SUCCESS: 'UPDATE_PURCHASING_REQUEST_SUCCESS',
    UPDATE_PURCHASING_REQUEST_FAILURE: 'UPDATE_PURCHASING_REQUEST_FAILURE',

    GET_NUMBER_PURCHASING_REQUEST_REQUEST: 'GET_NUMBER_PURCHASING_REQUEST_REQUEST',
    GET_NUMBER_PURCHASING_REQUEST_SUCCESS: 'GET_NUMBER_PURCHASING_REQUEST_SUCCESS',
    GET_NUMBER_PURCHASING_REQUEST_FAILURE: 'GET_NUMBER_PURCHASING_REQUEST_FAILURE',
}