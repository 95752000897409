export const SystemPageConstants = {
    GET_SYSTEM_PAGE_APIS: 'GET_SYSTEM_PAGE_APIS',

    GET_SYSTEM_ADMIN_PAGE_REQUEST: 'GET_SYSTEM_ADMIN_PAGE_REQUEST',
    GET_SYSTEM_ADMIN_PAGE_SUCCESS: 'GET_SYSTEM_ADMIN_PAGE_SUCCESS',
    GET_SYSTEM_ADMIN_PAGE_FAILURE: 'GET_SYSTEM_ADMIN_PAGE_FAILURE',


    ADD_SYSTEM_ADMIN_PAGE_REQUEST: 'ADD_SYSTEM_ADMIN_PAGE_REQUEST',
    ADD_SYSTEM_ADMIN_PAGE_SUCCESS: 'ADD_SYSTEM_ADMIN_PAGE_SUCCESS',
    ADD_SYSTEM_ADMIN_PAGE_FAILURE: 'ADD_SYSTEM_ADMIN_PAGE_FAILURE',


    DEL_SYSTEM_ADMIN_PAGE_REQUEST: 'DEL_SYSTEM_ADMIN_PAGE_REQUEST',
    DEL_SYSTEM_ADMIN_PAGE_SUCCESS: 'DEL_SYSTEM_ADMIN_PAGE_SUCCESS',
    DEL_SYSTEM_ADMIN_PAGE_FAILURE: 'DEL_SYSTEM_ADMIN_PAGE_FAILURE',
}