export const CrmStatusConstants = {
    GET_CRM_STATUS_REQUEST: 'GET_CRM_STATUS_REQUEST',
    GET_CRM_STATUS_SUCCESS: 'GET_CRM_STATUS_SUCCESS',
    GET_CRM_STATUS_FAILE: 'GET_CRM_STATUS_FAILE',

    GET_CRM_STATUS_BY_ID_REQUEST: 'GET_CRM_STATUS_BY_ID_REQUEST',
    GET_CRM_STATUS_BY_ID_SUCCESS: 'GET_CRM_STATUS_BY_ID_SUCCESS',
    GET_CRM_STATUS_BY_ID_FAILE: 'GET_CRM_STATUS_BY_ID_FAILE',

    CREATE_CRM_STATUS_REQUEST: 'CREATE_CRM_STATUS_REQUEST',
    CREATE_CRM_STATUS_SUCCESS: 'CREATE_CRM_STATUS_SUCCESS',
    CREATE_CRM_STATUS_FAILE: 'CREATE_CRM_STATUS_FAILE',

    EDIT_CRM_STATUS_REQUEST: 'EDIT_CRM_STATUS_REQUEST',
    EDIT_CRM_STATUS_SUCCESS: 'EDIT_CRM_STATUS_SUCCESS',
    EDIT_CRM_STATUS_FAILE: 'EDIT_CRM_STATUS_FAILE',

    DELETE_CRM_STATUS_REQUEST: 'DELETE_CRM_STATUS_REQUEST',
    DELETE_CRM_STATUS_SUCCESS: 'DELETE_CRM_STATUS_SUCCESS',
    DELETE_CRM_STATUS_FAILE: 'DELETE_CRM_STATUS_FAILE',
}