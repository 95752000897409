export const exampleConstants = {
    GET_ALL_ONLY_EXAMPLE_NAME_REQUEST: "GET_ALL_ONLY_EXAMPLE_NAME_REQUEST",
    GET_ALL_ONLY_EXAMPLE_NAME_SUCCESS: "GET_ALL_ONLY_EXAMPLE_NAME_SUCCESS",
    GET_ALL_ONLY_EXAMPLE_NAME_FAILURE: "GET_ALL_ONLY_EXAMPLE_NAME_FAILURE",

    DELETE_EXAMPLE_REQUEST: "DELETE_DELETE_EXAMPLE_REQUEST",
    DELETE_EXAMPLE_SUCCESS: "DELETE_EXAMPLE_SUCCESS",
    DELETE_EXAMPLE_FAILURE: "DELETE_EXAMPLE_FAILURE",

    CREATE_EXAMPLE_REQUEST: "CREATE_EXAMPLE_REQUEST",
    CREATE_EXAMPLE_SUCCESS: "CREATE_EXAMPLE_SUCCESS",
    CREATE_EXAMPLE_FAILURE: "CREATE_EXAMPLE_FAILURE",

    EDIT_EXAMPLE_REQUEST: "EDIT_EXAMPLE_REQUEST",
    EDIT_EXAMPLE_SUCCESS: "EDIT_EXAMPLE_SUCCESS",
    EDIT_EXAMPLE_FAILURE: "EDIT_EXAMPLE_FAILURE",

    GET_EXAMPLE_DETAIL_REQUEST: "GET_EXAMPLE_DETAIL_REQUEST",
    GET_EXAMPLE_DETAIL_SUCCESS: "GET_EXAMPLE_DETAIL_SUCCESS",
    GET_EXAMPLE_DETAIL_FAILURE: "GET_EXAMPLE_DETAIL_FAILURE"
}