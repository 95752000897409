export const PurchaseRequestConstants = {
    GET_PURCHASE_REQUEST_REQUEST: 'GET_PURCHASE_REQUEST_REQUEST',
    GET_PURCHASE_REQUEST_SUCCESS: 'GET_PURCHASE_REQUEST_SUCCESS',
    GET_PURCHASE_REQUEST_FAILURE: 'GET_PURCHASE_REQUEST_FAILURE',

    CREATE_PURCHASE_REQUEST_REQUEST: 'CREATE_NEW_PURCHASE_REQUEST_REQUEST',
    CREATE_PURCHASE_REQUEST_SUCCESS: 'CREATE_NEW_PURCHASE_REQUEST_SUCCESS',
    CREATE_PURCHASE_REQUEST_FAILURE: 'CREATE_NEW_PURCHASE_REQUEST_FAILURE',

    UPDATE_PURCHASE_REQUEST_REQUEST: 'UPDATE_PURCHASE_REQUEST_REQUEST',
    UPDATE_PURCHASE_REQUEST_SUCCESS: 'UPDATE_PURCHASE_REQUEST_SUCCESS',
    UPDATE_PURCHASE_REQUEST_FAILURE: 'UPDATE_PURCHASE_REQUEST_FAILURE',

    DELETE_PURCHASE_REQUEST_REQUEST: 'DELETE_PURCHASE_REQUEST_REQUEST',
    DELETE_PURCHASE_REQUEST_SUCCESS: 'DELETE_PURCHASE_REQUEST_SUCCESS',
    DELETE_PURCHASE_REQUEST_FAILURE: 'DELETE_PURCHASE_REQUEST_FAILURE',

    GET_USER_APPROVER_REQUEST: 'GET_USER_APPROVER_REQUEST',
    GET_USER_APPROVER_SUCCESS: 'GET_USER_APPROVER_SUCCESS',
    GET_USER_APPROVER_FAILURE: 'GET_USER_APPROVER_FAILURE',
}