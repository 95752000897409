export const BiddingContractConstant = {
    GET_BIDDING_CONTRACT_REQUEST: 'GET_BIDDING_CONTRACT_REQUEST',
    GET_BIDDING_CONTRACT_SUCCESS: 'GET_BIDDING_CONTRACT_SUCCESS',
    GET_BIDDING_CONTRACT_FAILURE: 'GET_BIDDING_CONTRACT_FAILURE',

    CREATE_BIDDING_CONTRACT_REQUEST: 'CREATE_NEW_BIDDING_CONTRACT_REQUEST',
    CREATE_BIDDING_CONTRACT_SUCCESS: 'CREATE_NEW_BIDDING_CONTRACT_SUCCESS',
    CREATE_BIDDING_CONTRACT_FAILURE: 'CREATE_NEW_BIDDING_CONTRACT_FAILURE',

    DELETE_BIDDING_CONTRACT_REQUEST: 'DELETE_BIDDING_CONTRACT_REQUEST',
    DELETE_BIDDING_CONTRACT_SUCCESS: 'DELETE_BIDDING_CONTRACT_SUCCESS',
    DELETE_BIDDING_CONTRACT_FAILURE: 'DELETE_BIDDING_CONTRACT_FAILURE',

    UPDATE_BIDDING_CONTRACT_REQUEST: 'UPDATE_BIDDING_CONTRACT_REQUEST',
    UPDATE_BIDDING_CONTRACT_SUCCESS: 'UPDATE_BIDDING_CONTRACT_SUCCESS',
    UPDATE_BIDDING_CONTRACT_FAILURE: 'UPDATE_BIDDING_CONTRACT_FAILURE',

    CREATE_PROJECT_BY_BIDDING_CONTRACT_REQUEST: 'CREATE_PROJECT_BY_BIDDING_CONTRACT_REQUEST',
    CREATE_PROJECT_BY_BIDDING_CONTRACT_SUCCESS: 'CREATE_PROJECT_BY_BIDDING_CONTRACT_SUCCESS',
    CREATE_PROJECT_BY_BIDDING_CONTRACT_FAILURE: 'CREATE_PROJECT_BY_BIDDING_CONTRACT_FAILURE',

};