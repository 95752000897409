export const CrmCustomerConstants = {
    GET_CRM_CUSTOMERS_REQUEST: 'GET_CRM_CUSTOMERS_REQUEST',
    GET_CRM_CUSTOMERS_SUCCESS: 'GET_CRM_CUSTOMERS_SUCCESS',
    GET_CRM_CUSTOMERS_FAILE: 'GET_CRM_CUSTOMERS_FAILE',

    GET_CRM_CUSTOMER_REQUEST: 'GET_CRM_CUSTOMER_REQUEST',
    GET_CRM_CUSTOMER_SUCCESS: 'GET_CRM_CUSTOMER_SUCCESS',
    GET_CRM_CUSTOMER_FAILE: 'GET_CRM_CUSTOMER_FAILE',

    GET_CRM_CUSTOMER_POINT_REQUEST: 'GET_CRM_CUSTOMER_POINT_REQUEST',
    GET_CRM_CUSTOMER_POINT_SUCCESS: 'GET_CRM_CUSTOMER_POINT_SUCCESS',
    GET_CRM_CUSTOMER_POINT_FAILE: 'GET_CRM_CUSTOMER_POINT_FAILE',

    CREATE_CRM_CUSTOMER_REQUEST: 'CREATE_CRM_CUSTOMER_REQUEST',
    CREATE_CRM_CUSTOMER_SUCCESS: 'CREATE_CRM_CUSTOMER_SUCCESS',
    CREATE_CRM_CUSTOMER_FAILE: 'CREATE_CRM_CUSTOMER_FAILE',

    IMPORT_CRM_CUSTOMER_REQUEST: 'IMPORT_CRM_CUSTOMER_REQUEST',
    IMPORT_CRM_CUSTOMER_SUCCESS: 'IMPORT_CRM_CUSTOMER_SUCCESS',
    IMPORT_CRM_CUSTOMER_FAILE: 'IMPORT_CRM_CUSTOMER_FAILE',

    EDIT_CRM_CUSTOMER_REQUEST: 'EDIT_CRM_CUSTOMER_REQUEST',
    EDIT_CRM_CUSTOMER_SUCCESS: 'EDIT_CRM_CUSTOMER_SUCCESS',
    EDIT_CRM_CUSTOMER_FAILE: 'EDIT_CRM_CUSTOMER_FAILE',

    EDIT_CRM_CUSTOMER_POINT_REQUEST: 'EDIT_CRM_CUSTOMER_POINT_REQUEST',
    EDIT_CRM_CUSTOMER_POINT_SUCCESS: 'EDIT_CRM_CUSTOMER_POINT_SUCCESS',
    EDIT_CRM_CUSTOMER_POINT_FAILE: 'EDIT_CRM_CUSTOMER_POINT_FAILE',

    GET_CRM_CUSTOMER_PROMOTIONS_REQUEST: 'GET_CRM_CUSTOMER_PROMOTIONS_REQUEST',
    GET_CRM_CUSTOMER_PROMOTIONS_SUCCESS: 'GET_CRM_CUSTOMER_PROMOTIONS_SUCCESS',
    GET_CRM_CUSTOMER_PROMOTIONS_FAILE: 'GET_CRM_CUSTOMER_PROMOTIONS_FAILE',

    EDIT_CRM_CUSTOMER_PROMOTION_REQUEST: 'EDIT_CRM_CUSTOMER_PROMOTION_REQUEST',
    EDIT_CRM_CUSTOMER_PROMOTION_SUCCESS: 'EDIT_CRM_CUSTOMER_PROMOTION_SUCCESS',
    EDIT_CRM_CUSTOMER_PROMOTION_FAILE: 'EDIT_CRM_CUSTOMER_PROMOTION_FAILE',

    USE_CRM_CUSTOMER_PROMOTION_REQUEST: 'USE_CRM_CUSTOMER_PROMOTION_REQUEST',
    USE_CRM_CUSTOMER_PROMOTION_SUCCESS: 'USE_CRM_CUSTOMER_PROMOTION_SUCCESS',
    USE_CRM_CUSTOMER_PROMOTION_FAILE: 'USE_CRM_CUSTOMER_PROMOTION_FAILE',

    DELETE_CRM_CUSTOMER_PROMOTION_REQUEST: 'DELETE_CRM_CUSTOMER_PROMOTION_REQUEST',
    DELETE_CRM_CUSTOMER_PROMOTION_SUCCESS: 'DELETE_CRM_CUSTOMER_PROMOTION_SUCCESS',
    DELETE_CRM_CUSTOMER_PROMOTION_FAILE: 'DELETE_CRM_CUSTOMER_PROMOTION_FAILE',

    ADD_CRM_CUSTOMER_PROMOTION_REQUEST: 'ADD_CRM_CUSTOMER_PROMOTION_REQUEST',
    ADD_CRM_CUSTOMER_PROMOTION_SUCCESS: 'ADD_CRM_CUSTOMER_PROMOTION_SUCCESS',
    ADD_CRM_CUSTOMER_PROMOTION_FAILE: 'ADD_CRM_CUSTOMER_PROMOTION_FAILE',



    DELETE_CRM_CUSTOMER_REQUEST: 'DELETE_CRM_CUSTOMER_REQUEST',
    DELETE_CRM_CUSTOMER_SUCCESS: 'DELETE_CRM_CUSTOMER_SUCCESS',
    DELETE_CRM_CUSTOMER_FAILE: 'DELETE_CRM_CUSTOMER_FAILE',
}