export const SystemApiConstants = {
    CREATE_SYSTEM_API_REQUEST: 'CREATE_SYSTEM_API_REQUEST',
    CREATE_SYSTEM_API_SUCCESS: 'CREATE_SYSTEM_API_SUCCESS',
    CREATE_SYSTEM_API_FAILURE: 'CREATE_SYSTEM_API_FAILURE',
    
    GET_SYSTEM_API_REQUEST: 'GET_SYSTEM_API_REQUEST',
    GET_SYSTEM_API_SUCCESS: 'GET_SYSTEM_API_SUCCESS',
    GET_SYSTEM_API_FAILURE: 'GET_SYSTEM_API_FAILURE',

    UPDATE_AUTO_SYSTEM_API_REQUEST: 'UPDATE_AUTO_SYSTEM_API_REQUEST',
    UPDATE_AUTO_SYSTEM_API_SUCCESS: 'UPDATE_AUTO_SYSTEM_API_SUCCESS',
    UPDATE_AUTO_SYSTEM_API_FAILURE: 'UPDATE_AUTO_SYSTEM_API_FAILURE',

    EDIT_SYSTEM_API_REQUEST: 'EDIT_SYSTEM_API_REQUEST',
    EDIT_SYSTEM_API_SUCCESS: 'EDIT_SYSTEM_API_SUCCESS',
    EDIT_SYSTEM_API_FAILURE: 'EDIT_SYSTEM_API_FAILURE',

    DELETE_SYSTEM_API_REQUEST: 'DELETE_SYSTEM_API_REQUEST',
    DELETE_SYSTEM_API_SUCCESS: 'DELETE_SYSTEM_API_SUCCESS',
    DELETE_SYSTEM_API_FAILURE: 'DELETE_SYSTEM_API_FAILURE',

}