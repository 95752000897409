export const TaxConstants = {
    GET_ALL_TAXS_REQUEST: 'GET_ALL_TAXS_REQUEST',
    GET_ALL_TAXS_SUCCESS: 'GET_ALL_TAXS_SUCCESS',
    GET_ALL_TAXS_FAILURE: 'GET_ALL_TAXS_FAILURE',

    CREATE_TAX_REQUEST: 'CREATE_TAX_REQUEST',
    CREATE_TAX_SUCCESS: 'CREATE_TAX_SUCCESS',
    CREATE_TAX_FAILURE: 'CREATE_TAX_FAILURE',

    GET_DETAIL_TAX_REQUEST: 'GET_DETAIL_TAX_REQUEST',
    GET_DETAIL_TAX_FAILURE: 'GET_DETAIL_TAX_FAILURE',
    GET_DETAIL_TAX_SUCCESS: 'GET_DETAIL_TAX_SUCCESS',

    UPDATE_TAX_REQUEST: 'UPDATE_TAX_REQUEST',
    UPDATE_TAX_SUCCESS: 'UPDATE_TAX_SUCCESS',
    UPDATE_TAX_FAILURE: 'UPDATE_TAX_FAILURE',

    DISABLE_TAX_REQUEST: 'DISABLE_TAX_REQUEST',
    DISABLE_TAX_SUCCESS: 'DISABLE_TAX_SUCCESS',
    DISABLE_TAX_FAILURE: 'DISABLE_TAX_FAILURE',

    CHECK_TAX_CODE_REQUEST: 'CHECK_TAX_CODE_REQUEST',
    CHECK_TAX_CODE_SUCCESS: 'CHECK_TAX_CODE_SUCCESS',
    CHECK_TAX_CODE_FAILURE: 'CHECK_TAX_CODE_FAILURE',

    GET_TAX_BY_CODE_REQUEST: 'GET_TAX_BY_CODE_REQUEST',
    GET_TAX_BY_CODE_SUCCESS: 'GET_TAX_BY_CODE_SUCCESS',
    GET_TAX_BY_CODE_FAILURE: 'GET_TAX_BY_CODE_FAILURE',

    DELETE_TAX_BY_CODE_REQUEST: 'DELETE_TAX_BY_CODE_REQUEST',
    DELETE_TAX_BY_CODE_SUCCESS: 'DELETE_TAX_BY_CODE_SUCCESS',
    DELETE_TAX_BY_CODE_FAILURE: 'DELETE_TAX_BY_CODE_FAILURE',
}