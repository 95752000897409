export const DepartmentConstants = {
    GET_DEPARTMENTS_REQUEST: 'GET_DEPARTMENTS_REQUEST',
    GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
    GET_DEPARTMENTS_FAILE: 'GET_DEPARTMENTS_FAILE',

    GET_DEPARTMENT_REQUEST: 'GET_DEPARTMENT_REQUEST',
    GET_DEPARTMENT_SUCCESS: 'GET_DEPARTMENT_SUCCESS',
    GET_DEPARTMENT_FAILURE: 'GET_DEPARTMENT_FAILURE',
    
    CREATE_DEPARTMENT_REQUEST: 'CREATE_DEPARTMENT_REQUEST',
    CREATE_DEPARTMENT_SUCCESS: 'CREATE_DEPARTMENT_SUCCESS',
    CREATE_DEPARTMENT_FAILE: 'CREATE_DEPARTMENT_FAILE',

    EDIT_DEPARTMENT_REQUEST: 'EDIT_DEPARTMENT_REQUEST',
    EDIT_DEPARTMENT_SUCCESS: 'EDIT_DEPARTMENT_SUCCESS',
    EDIT_DEPARTMENT_FAILE: 'EDIT_DEPARTMENT_FAILE',

    DELETE_DEPARTMENT_REQUEST: 'DELETE_DEPARTMENT_REQUEST',
    DELETE_DEPARTMENT_SUCCESS: 'DELETE_DEPARTMENT_SUCCESS',
    DELETE_DEPARTMENT_FAILE: 'DELETE_DEPARTMENT_FAILE',

    GET_DEPARTMENTS_THAT_USER_IS_MANAGER_REQUEST: 'GET_DEPARTMENTS_THAT_USER_IS_MANAGER_REQUEST',
    GET_DEPARTMENTS_THAT_USER_IS_MANAGER_SUCCESS: 'GET_DEPARTMENTS_THAT_USER_IS_MANAGER_SUCCESS',
    GET_DEPARTMENTS_THAT_USER_IS_MANAGER_FAILURE: 'GET_DEPARTMENTS_THAT_USER_IS_MANAGER_FAILURE',

    IMPORT_DEPARTMENT_REQUEST: 'IMPORT_DEPARTMENT_REQUEST',
    IMPORT_DEPARTMENT_SUCCESS: 'IMPORT_DEPARTMENT_SUCCESS',
    IMPORT_DEPARTMENT_FAILURE: 'IMPORT_DEPARTMENT_FAILURE',
}