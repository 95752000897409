export const CertificateConstant  = {
    GET_CERTIFICATE_REQUEST: 'GET_CERTIFICATE_REQUEST',
    GET_CERTIFICATE_SUCCESS: 'GET_CERTIFICATE_SUCCESS',
    GET_CERTIFICATE_FAILURE: 'GET_CERTIFICATE_FAILURE',

    CREATE_CERTIFICATE_REQUEST: 'CREATE_NEW_CERTIFICATE_REQUEST',
    CREATE_CERTIFICATE_SUCCESS: 'CREATE_NEW_CERTIFICATE_SUCCESS',
    CREATE_CERTIFICATE_FAILURE: 'CREATE_NEW_CERTIFICATE_FAILURE',

    DELETE_CERTIFICATE_REQUEST: 'DELETE_CERTIFICATE_REQUEST',
    DELETE_CERTIFICATE_SUCCESS: 'DELETE_CERTIFICATE_SUCCESS',
    DELETE_CERTIFICATE_FAILURE: 'DELETE_CERTIFICATE_FAILURE',

    UPDATE_CERTIFICATE_REQUEST: 'UPDATE_CERTIFICATE_REQUEST',
    UPDATE_CERTIFICATE_SUCCESS: 'UPDATE_CERTIFICATE_SUCCESS',
    UPDATE_CERTIFICATE_FAILURE: 'UPDATE_CERTIFICATE_FAILURE',

};