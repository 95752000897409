export const EmployeeConstants = {
    GETALL_REQUEST: 'EMPLOYEE_GETALL_REQUEST',
    GETALL_SUCCESS: 'EMPLOYEE_GETALL_SUCCESS',
    GETALL_FAILURE: 'EMPLOYEE_GETALL_FAILURE',

    ADDEMPLOYEE_REQUEST: 'EMPLOYEE_ADDEMPLOYEE_REQUEST',
    ADDEMPLOYEE_SUCCESS: 'EMPLOYEE_ADDEMPLOYEE_SUCCESS',
    ADDEMPLOYEE_FAILURE: 'EMPLOYEE_ADDEMPLOYEE_FAILURE',

    UPDATE_INFOR_EMPLOYEE_REQUEST: 'EMPLOYEE_UPDATE_INFOR_EMPLOYEE_REQUEST',
    UPDATE_INFOR_EMPLOYEE_SUCCESS: 'EMPLOYEE_UPDATE_INFOR_EMPLOYEE_SUCCESS',
    UPDATE_INFOR_EMPLOYEE_FAILURE: 'EMPLOYEE_UPDATE_INFOR_EMPLOYEE_FAILURE',

    DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
    DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
    DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE',

    IMPORT_EMPLOYEE_REQUEST: 'IMPORT_EMPLOYEE_REQUEST',
    IMPORT_EMPLOYEE_SUCCESS: 'IMPORT_EMPLOYEE_SUCCESS',
    IMPORT_EMPLOYEE_FAILURE: 'IMPORT_EMPLOYEE_FAILURE',

    SEARCH_FOR_PACKAGE_REQUEST: 'SEARCH_FOR_PACKAGE_REQUEST',
    SEARCH_FOR_PACKAGE_SUCCESS: 'SEARCH_FOR_PACKAGE_SUCCESS',
    SEARCH_FOR_PACKAGE_FAILURE: 'SEARCH_FOR_PACKAGE_FAILURE',

};