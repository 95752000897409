export const dashboardEmployeeKpiConstants = {
    GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ROLE_REQUEST: 'GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ROLE_REQUEST',
    GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ROLE_SUCCESS: 'GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ROLE_SUCCESS',
    GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ROLE_FAILURE: 'GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ROLE_FAILURE',

    GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ID_REQUEST: 'GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ID_REQUEST',
    GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ID_SUCCESS: 'GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ID_SUCCESS',
    GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ID_FAILURE: 'GET_ALL_EMPLOYEE_KPI_SET_OF_UNIT_BY_ID_FAILURE',

    GET_ALL_CHILDREN_OF_UNIT_REQUEST: 'GET_ALL_CHILDREN_OF_UNIT_REQUEST',
    GET_ALL_CHILDREN_OF_UNIT_SUCCESS: 'GET_ALL_CHILDREN_OF_UNIT_SUCCESS',
    GET_ALL_CHILDREN_OF_UNIT_FAILURE: 'GET_ALL_CHILDREN_OF_UNIT_FAILURE',

    GET_EMPLOYEE_KPI_PERFORMANCE_REQUEST: 'GET_EMPLOYEE_KPI_PERFORMANCE_REQUEST',
    GET_EMPLOYEE_KPI_PERFORMANCE_SUCCESS: 'GET_EMPLOYEE_KPI_PERFORMANCE_SUCCESS',
    GET_EMPLOYEE_KPI_PERFORMANCE_FAILURE: 'GET_EMPLOYEE_KPI_PERFORMANCE_FAILURE',

    CREATE_EMPLOYEE_KPI_SET_AUTO_REQUEST: 'CREATE_EMPLOYEE_KPI_SET_AUTO_REQUEST',
    CREATE_EMPLOYEE_KPI_SET_AUTO_SUCCESS: 'CREATE_EMPLOYEE_KPI_SET_AUTO_SUCCESS',
    CREATE_EMPLOYEE_KPI_SET_AUTO_FAILURE: 'CREATE_EMPLOYEE_KPI_SET_AUTO_FAILURE',

    BALANCE_EMPLOYEE_KPI_SET_AUTO_REQUEST: 'BALANCE_EMPLOYEE_KPI_SET_AUTO_REQUEST',
    BALANCE_EMPLOYEE_KPI_SET_AUTO_SUCCESS: 'BALANCE_EMPLOYEE_KPI_SET_AUTO_SUCCESS',
    BALANCE_EMPLOYEE_KPI_SET_AUTO_FAILURE: 'BALANCE_EMPLOYEE_KPI_SET_AUTO_FAILURE',
}