export const BusinessDepartmentConstants = {
    GET_ALL_BUSINESS_DEPARTMENT_REQUEST: 'GET_ALL_BUSINESS_DEPARTMENT_REQUEST',
    GET_ALL_BUSINESS_DEPARTMENT_SUCCESS: 'GET_ALL_BUSINESS_DEPARTMENT_SUCCESS',
    GET_ALL_BUSINESS_DEPARTMENT_FAILURE: 'GET_ALL_BUSINESS_DEPARTMENT_FAILURE',

    CREATE_BUSINESS_DEPARTMENT_REQUEST: 'CREATE_BUSINESS_DEPARTMENT_REQUEST',
    CREATE_BUSINESS_DEPARTMENT_SUCCESS: 'CREATE_BUSINESS_DEPARTMENT_SUCCESS',
    CREATE_BUSINESS_DEPARTMENT_FAILURE: 'CREATE_BUSINESS_DEPARTMENT_FAILURE',

    UPDATE_BUSINESS_DEPARTMENT_REQUEST: 'UPDATE_BUSINESS_DEPARTMENT_REQUEST',
    UPDATE_BUSINESS_DEPARTMENT_SUCCESS: 'UPDATE_BUSINESS_DEPARTMENT_SUCCESS',
    UPDATE_BUSINESS_DEPARTMENT_FAILURE: 'UPDATE_BUSINESS_DEPARTMENT_FAILURE'
}