export const manufacturingPlanConstants = {
    GET_ALL_MANUFACTURING_PLANS_REQUEST: "GET_ALL_MANUFACTURING_PLANS_REQUEST",
    GET_ALL_MANUFACTURING_PLANS_SUCCESS: "GET_ALL_MANUFACTURING_PLANS_SUCCESS",
    GET_ALL_MANUFACTURING_PLANS_FAILURE: "GET_ALL_MANUFACTURING_PLANS_FAILURE",

    GET_ALL_APPROVERS_OF_PLAN_REQUEST: 'GET_ALL_APPROVERS_OF_PLAN_REQUEST',
    GET_ALL_APPROVERS_OF_PLAN_SUCCESS: 'GET_ALL_APPROVERS_OF_PLAN_SUCCESS',
    GET_ALL_APPROVERS_OF_PLAN_FAILURE: 'GET_ALL_APPROVERS_OF_PLAN_FAILURE',

    CREATE_MANUFACTURING_PLAN_REQUEST: 'CREATE_MANUFACTURING_PLAN_REQUEST',
    CREATE_MANUFACTURING_PLAN_FAILURE: 'CREATE_MANUFACTURING_PLAN_FAILURE',
    CREATE_MANUFACTURING_PLAN_SUCCESS: 'CREATE_MANUFACTURING_PLAN_SUCCESS',

    GET_MANUFACTURING_PLAN_BY_ID_REQUEST: 'GET_MANUFACTURING_PLAN_BY_ID_REQUEST',
    GET_MANUFACTURING_PLAN_BY_ID_FAILURE: 'GET_MANUFACTURING_PLAN_BY_ID_FAILURE',
    GET_MANUFACTURING_PLAN_BY_ID_SUCCESS: 'GET_MANUFACTURING_PLAN_BY_ID_SUCCESS',

    EDIT_MANUFACTURING_PLAN_REQUEST: 'EDIT_MANUFACTURING_PLAN_REQUEST',
    EDIT_MANUFACTURING_PLAN_FAILURE: 'EDIT_MANUFACTURING_PLAN_FAILURE',
    EDIT_MANUFACTURING_PLAN_SUCCESS: 'EDIT_MANUFACTURING_PLAN_SUCCESS',

    GET_NUMBER_PLAN_REQUEST: 'GET_NUMBER_PLAN_REQUEST',
    GET_NUMBER_PLAN_FAILURE: 'GET_NUMBER_PLAN_FAILURE',
    GET_NUMBER_PLAN_SUCCESS: 'GET_NUMBER_PLAN_SUCCESS',

    GET_NUMBER_PLAN_BY_STATUS_REQUEST: 'GET_NUMBER_PLAN_BY_STATUS_REQUEST',
    GET_NUMBER_PLAN_BY_STATUS_FAILURE: 'GET_NUMBER_PLAN_BY_STATUS_FAILURE',
    GET_NUMBER_PLAN_BY_STATUS_SUCCESS: 'GET_NUMBER_PLAN_BY_STATUS_SUCCESS',
}