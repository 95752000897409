export const transportRequirementsConstants = {
    GET_ALL_TRANSPORT_REQUIREMENTS_REQUEST: "GET_ALL_TRANSPORT_REQUIREMENTS_REQUEST",
    GET_ALL_TRANSPORT_REQUIREMENTS_SUCCESS: "GET_ALL_TRANSPORT_REQUIREMENTS_SUCCESS",
    GET_ALL_TRANSPORT_REQUIREMENTS_FAILURE: "GET_ALL_TRANSPORT_REQUIREMENTS_FAILURE",

    CREATE_TRANSPORT_REQUIREMENT_REQUEST: "CREATE_TRANSPORT_REQUIREMENT_REQUEST",
    CREATE_TRANSPORT_REQUIREMENT_SUCCESS: "CREATE_TRANSPORT_REQUIREMENT_SUCCESS",
    CREATE_TRANSPORT_REQUIREMENT_FAILURE: "CREATE_TRANSPORT_REQUIREMENT_FAILURE",

    GET_DETAIL_TRANSPORT_REQUIREMENT_REQUEST: "GET_DETAIL_TRANSPORT_REQUIREMENT_REQUEST",
    GET_DETAIL_TRANSPORT_REQUIREMENT_SUCCESS: "GET_DETAIL_TRANSPORT_REQUIREMENT_SUCCESS",
    GET_DETAIL_TRANSPORT_REQUIREMENT_FAILURE: "GET_DETAIL_TRANSPORT_REQUIREMENT_FAILURE",

    EDIT_TRANSPORT_REQUIREMENT_REQUEST: "EDIT_TRANSPORT_REQUIREMENT_REQUEST",
    EDIT_TRANSPORT_REQUIREMENT_SUCCESS: "EDIT_TRANSPORT_REQUIREMENT_SUCCESS",
    EDIT_TRANSPORT_REQUIREMENT_FAILURE: "EDIT_TRANSPORT_REQUIREMENT_FAILURE",

    DELETE_TRANSPORT_REQUIREMENT_REQUEST: "DELETE_TRANSPORT_REQUIREMENT_REQUEST",
    DELETE_TRANSPORT_REQUIREMENT_SUCCESS: "DELETE_TRANSPORT_REQUIREMENT_SUCCESS",
    DELETE_TRANSPORT_REQUIREMENT_FAILURE: "DELETE_TRANSPORT_REQUIREMENT_FAILURE",
}
