export const transportVehicleConstants = {
    GET_ALL_TRANSPORT_VEHICLES_REQUEST: "GET_ALL_TRANSPORT_VEHICLES_REQUEST",
    GET_ALL_TRANSPORT_VEHICLES_SUCCESS: "GET_ALL_TRANSPORT_VEHICLES_SUCCESS",
    GET_ALL_TRANSPORT_VEHICLES_FAILURE: "GET_ALL_TRANSPORT_VEHICLES_FAILURE",
    
    CREATE_TRANSPORT_VEHICLE_REQUEST: "CREATE_TRANSPORT_VEHICLE_REQUEST",
    CREATE_TRANSPORT_VEHICLE_SUCCESS: "CREATE_TRANSPORT_VEHICLE_SUCCESS",
    CREATE_TRANSPORT_VEHICLE_FAILURE: "CREATE_TRANSPORT_VEHICLE_FAILURE",

    CREATE_TRANSPORT_PLAN_VEHICLE_NOT_DUPLICATE_REQUEST: "CREATE_TRANSPORT_PLAN_VEHICLE_NOT_DUPLICATE_REQUEST",
    CREATE_TRANSPORT_PLAN_VEHICLE_NOT_DUPLICATE_SUCCESS: "CREATE_TRANSPORT_PLAN_VEHICLE_NOT_DUPLICATE_SUCCESS",
    CREATE_TRANSPORT_PLAN_VEHICLE_NOT_DUPLICATE_FAILURE: "CREATE_TRANSPORT_PLAN_VEHICLE_NOT_DUPLICATE_FAILURE",
    
    EDIT_TRANSPORT_VEHICLE_REQUEST: "EDIT_TRANSPORT_VEHICLE_REQUEST",
    EDIT_TRANSPORT_VEHICLE_SUCCESS: "EDIT_TRANSPORT_VEHICLE_SUCCESS",
    EDIT_TRANSPORT_VEHICLE_FAILURE: "EDIT_TRANSPORT_VEHICLE_FAILURE",
}
