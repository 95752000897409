export const BankAccountConstants = {
    CREATE_BANK_ACCOUNT_REQUEST: 'CREATE_BANK_ACCOUNT_REQUEST',
    CREATE_BANK_ACCOUNT_SUCCESS: 'CREATE_BANK_ACCOUNT_SUCCESS',
    CREATE_BANK_ACCOUNT_FAILURE: 'CREATE_BANK_ACCOUNT_FAILURE',

    UPDATE_BANK_ACCOUNT_REQUEST: 'UPDATE_BANK_ACCOUNT_REQUEST',
    UPDATE_BANK_ACCOUNT_SUCCESS: 'UPDATE_BANK_ACCOUNT_SUCCESS',
    UPDATE_BANK_ACCOUNT_FAILURE: 'UPDATE_BANK_ACCOUNT_FAILURE',

    GET_ALL_BANK_ACCOUNTS_REQUEST: 'GET_ALL_BANK_ACCOUNTS_REQUEST',
    GET_ALL_BANK_ACCOUNTS_SUCCESS: 'GET_ALL_BANK_ACCOUNTS_SUCCESS',
    GET_ALL_BANK_ACCOUNTS_FAILURE: 'GET_ALL_BANK_ACCOUNTS_FAILURE',
}