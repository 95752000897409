export const ProjectConstants = {
    GET_PROJECTS_REQUEST: 'GET_PROJECTS_REQUEST',
    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILE: 'GET_PROJECTS_FAILE',

    CREATE_PROJECTS_REQUEST: 'CREATE_PROJECTS_REQUEST',
    CREATE_PROJECTS_SUCCESS: 'CREATE_PROJECTS_SUCCESS',
    CREATE_PROJECTS_FAILE: 'CREATE_PROJECTS_FAILE',

    EDIT_PROJECTS_REQUEST: 'EDIT_PROJECTS_REQUEST',
    EDIT_PROJECTS_SUCCESS: 'EDIT_PROJECTS_SUCCESS',
    EDIT_PROJECTS_FAILE: 'EDIT_PROJECTS_FAILE',


    DELETE_PROJECTS_REQUEST: 'DELETE_PROJECTS_REQUEST',
    DELETE_PROJECTS_SUCCESS: 'DELETE_PROJECTS_SUCCESS',
    DELETE_PROJECTS_FAILE: 'DELETE_PROJECTS_FAILE',

    GET_UNITS_REQUEST: 'GET_UNITS_REQUEST',
    GET_UNITS_SUCCESS: 'GET_UNITS_SUCCESS',
    GET_UNITS_FAILE: 'GET_UNITS_FAILE',

    GET_SALARY_MEMBER: 'GET_SALARY_MEMBER',
    GET_SALARY_MEMBER_SUCCESS: 'GET_SALARY_MEMBER_SUCCESS',
    GET_SALARY_MEMBER_FAILE: 'GET_SALARY_MEMBER_FAILE',

}
