export const PaymentConstants = {
    CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
    CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
    CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE',

    GET_ALL_PAYMENTS_REQUEST: 'GET_ALL_PAYMENTS_REQUEST',
    GET_ALL_PAYMENTS_SUCCESS: 'GET_ALL_PAYMENTS_SUCCESS',
    GET_ALL_PAYMENTS_FAILURE: 'GET_ALL_PAYMENTS_FAILURE',

    GET_PAYMENT_DETAIL_REQUEST: 'GET_PAYMENT_DETAIL_REQUEST',
    GET_PAYMENT_DETAIL_SUCCESS: 'GET_PAYMENT_DETAIL_SUCCESS',
    GET_PAYMENT_DETAIL_FAILURE: 'GET_PAYMENT_DETAIL_FAILURE',

    GET_PAYMENT_FOR_ORDER_REQUEST: 'GET_PAYMENT_FOR_ORDER_REQUEST',
    GET_PAYMENT_FOR_ORDER_SUCCESS: 'GET_PAYMENT_FOR_ORDER_SUCCESS',
    GET_PAYMENT_FOR_ORDER_FAILURE: 'GET_PAYMENT_FOR_ORDER_FAILURE',
}