export const PrivilegeApiContants = {
    CREATE_PRIVILEGE_API_REQUEST: 'CREATE_PRIVILEGE_API_REQUEST',
    CREATE_PRIVILEGE_API_SUCCESS: 'CREATE_PRIVILEGE_API_SUCCESS',
    CREATE_PRIVILEGE_API_FAILURE: 'CREATE_PRIVILEGE_API_FAILURE',

    GET_PRIVILEGE_API_REQUEST: 'GET_PRIVILEGE_API_REQUEST',
    GET_PRIVILEGE_API_SUCCESS: 'GET_PRIVILEGE_API_SUCCESS',
    GET_PRIVILEGE_API_FAILURE: 'GET_PRIVILEGE_API_FAILURE',

    UPDATE_STATUS_PRIVILEGE_API_REQUEST: 'UPDATE_STATUS_PRIVILEGE_API_REQUEST',
    UPDATE_STATUS_PRIVILEGE_API_SUCCESS: 'UPDATE_STATUS_PRIVILEGE_API_SUCCESS',
    UPDATE_STATUS_PRIVILEGE_API_FAILURE: 'UPDATE_STATUS_PRIVILEGE_API_FAILURE',
    
    DELETE_PRIVILEGE_APIS_REQUEST: 'DELETE_PRIVILEGE_APIS_REQUEST',
    DELETE_PRIVILEGE_APIS_SUCCESS: 'DELETE_PRIVILEGE_APIS_SUCCESS',
    DELETE_PRIVILEGE_APIS_FAILURE: 'DELETE_PRIVILEGE_APIS_FAILURE',
}