export const CrmUnitConstants = {
    GET_CRM_UNIT_REQUEST: 'GET_CRM_UNIT_REQUEST',
    GET_CRM_UNIT_SUCCESS: 'GET_CRM_UNIT_SUCCESS',
    GET_CRM_UNIT_FAILE: 'GET_CRM_UNIT_FAILE',

    CREATE_CRM_UNIT_REQUEST: 'CREATE_CRM_UNIT_REQUEST',
    CREATE_CRM_UNIT_SUCCESS: 'CREATE_CRM_UNIT_SUCCESS',
    CREATE_CRM_UNIT_FAILE: 'CREATE_CRM_UNIT_FAILE',

    DELETE_CRM_UNIT_REQUEST: 'DELETE_CRM_UNIT_REQUEST',
    DELETE_CRM_UNIT_SUCCESS: 'DELETE_CRM_UNIT_SUCCESS',
    DELETE_CRM_UNIT_FAILE: 'DELETE_CRM_UNIT_FAILE',
}