export const transportDepartmentConstants = {
    GET_ALL_TRANSPORT_DEPARTMENTS_REQUEST: "GET_ALL_TRANSPORT_DEPARTMENTS_REQUEST",
    GET_ALL_TRANSPORT_DEPARTMENTS_SUCCESS: "GET_ALL_TRANSPORT_DEPARTMENTS_SUCCESS",
    GET_ALL_TRANSPORT_DEPARTMENTS_FAILURE: "GET_ALL_TRANSPORT_DEPARTMENTS_FAILURE",

    GET_USER_BY_ROLE_REQUEST: "GET_USER_BY_ROLE_REQUEST",
    GET_USER_BY_ROLE_SUCCESS: "GET_USER_BY_ROLE_SUCCESS",
    GET_USER_BY_ROLE_FAILURE: "GET_USER_BY_ROLE_FAILURE",

    CREATE_TRANSPORT_DEPARTMENT_REQUEST: "CREATE_TRANSPORT_DEPARTMENT_REQUEST",
    CREATE_TRANSPORT_DEPARTMENT_SUCCESS: "CREATE_TRANSPORT_DEPARTMENT_SUCCESS",
    CREATE_TRANSPORT_DEPARTMENT_FAILURE: "CREATE_TRANSPORT_DEPARTMENT_FAILURE",

    DELETE_TRANSPORT_DEPARTMENT_REQUEST: "DELETE_TRANSPORT_DEPARTMENT_REQUEST",
    DELETE_TRANSPORT_DEPARTMENT_SUCCESS: "DELETE_TRANSPORT_DEPARTMENT_SUCCESS",
    DELETE_TRANSPORT_DEPARTMENT_FAILURE: "DELETE_TRANSPORT_DEPARTMENT_FAILURE",
}
