export const worksConstants = {
    GET_ALL_WORKS_REQUEST: 'GET_ALL_WORKS_REQUEST',
    GET_ALL_WORKS_SUCCESS: 'GET_ALL_WORKS_SUCCESS',
    GET_ALL_WORKS_FAILURE: 'GET_ALL_WORKS_FAILURE',

    CREATE_WORKS_REQUEST: 'CREATE_WORKS_REQUEST',
    CREATE_WORKS_SUCCESS: 'CREATE_WORKS_SUCCESS',
    CREATE_WORKS_FAILURE: 'CREATE_WORKS_FAILURE',

    GET_DETAIL_WORKS_REQUEST: 'GET_DETAIL_WORKS_REQUEST',
    GET_DETAIL_WORKS_FAILURE: 'GET_DETAIL_WORKS_FAILURE',
    GET_DETAIL_WORKS_SUCCESS: 'GET_DETAIL_WORKS_SUCCESS',

    UPDATE_WORKS_REQUEST: 'UPDATE_WORKS_REQUEST',
    UPDATE_WORKS_SUCCESS: 'UPDATE_WORKS_SUCCESS',
    UPDATE_WORKS_FAILURE: 'UPDATE_WORKS_FAILURE',

    GET_ALL_USERS_BY_WORKS_MANAGE_ROLEST_REQUEST: 'GET_ALL_USERS_BY_WORKS_MANAGE_ROLEST_REQUEST',
    GET_ALL_USERS_BY_WORKS_MANAGE_ROLEST_SUCCESS: 'GET_ALL_USERS_BY_WORKS_MANAGE_ROLEST_SUCCESS',
    GET_ALL_USERS_BY_WORKS_MANAGE_ROLEST_FAILURE: 'GET_ALL_USERS_BY_WORKS_MANAGE_ROLEST_FAILURE'
}