export const ChangeRequestConstants = {
    CREATE_PROJECT_CHANGE_REQUEST: 'CREATE_PROJECT_CHANGE_REQUEST',
    CREATE_PROJECT_CHANGE_REQUEST_SUCCESS: 'CREATE_PROJECT_CHANGE_REQUEST_SUCCESS',
    CREATE_PROJECT_CHANGE_REQUEST_FAILE: 'CREATE_PROJECT_CHANGE_REQUEST_FAILE',

    UPDATE_STATUS_PROJECT_CHANGE_REQUEST: 'UPDATE_STATUS_PROJECT_CHANGE_REQUEST',
    UPDATE_STATUS_PROJECT_CHANGE_REQUEST_SUCCESS: 'UPDATE_STATUS_PROJECT_CHANGE_REQUEST_SUCCESS',
    UPDATE_STATUS_PROJECT_CHANGE_REQUEST_FAILE: 'UPDATE_STATUS_PROJECT_CHANGE_REQUEST_FAILE',
    
    GET_LIST_PROJECT_CHANGE_REQUESTS: 'GET_LIST_PROJECT_CHANGE_REQUESTS',
    GET_LIST_PROJECT_CHANGE_REQUESTS_SUCCESS: 'GET_LIST_PROJECT_CHANGE_REQUESTS_SUCCESS',
    GET_LIST_PROJECT_CHANGE_REQUESTS_FAILE: 'GET_LIST_PROJECT_CHANGE_REQUESTS_FAILE',

    GET_PAGINATE_LIST_PROJECT_CHANGE_REQUESTS: 'GET_PAGINATE_LIST_PROJECT_CHANGE_REQUESTS',
    GET_PAGINATE_LIST_PROJECT_CHANGE_REQUESTS_SUCCESS: 'GET_PAGINATE_LIST_PROJECT_CHANGE_REQUESTS_SUCCESS',
    GET_PAGINATE_LIST_PROJECT_CHANGE_REQUESTS_FAILE: 'GET_PAGINATE_LIST_PROJECT_CHANGE_REQUESTS_FAILE',
}