export const QuoteConstants = {
    GET_ALL_QUOTES_REQUEST: 'GET_ALL_QUOTES_REQUEST',
    GET_ALL_QUOTES_SUCCESS: 'GET_ALL_QUOTES_SUCCESS',
    GET_ALL_QUOTES_FAILURE: 'GET_ALL_QUOTES_FAILURE',

    CREATE_QUOTE_REQUEST: 'CREATE_QUOTE_REQUEST',
    CREATE_QUOTE_SUCCESS: 'CREATE_QUOTE_SUCCESS',
    CREATE_QUOTE_FAILURE: 'CREATE_QUOTE_FAILURE',

    EDIT_QUOTE_REQUEST: 'EDIT_QUOTE_REQUEST',
    EDIT_QUOTE_SUCCESS: 'EDIT_QUOTE_SUCCESS',
    EDIT_QUOTE_FAILURE: 'EDIT_QUOTE_FAILURE',

    DELETE_QUOTE_REQUEST: 'DELETE_QUOTE_REQUEST',
    DELETE_QUOTE_FAILURE: 'DELETE_QUOTE_FAILURE',
    DELETE_QUOTE_SUCCESS: 'DELETE_QUOTE_SUCCESS',

    APPROVE_QUOTE_REQUEST: 'APPROVE_QUOTE_REQUEST',
    APPROVE_QUOTE_FAILURE: 'APPROVE_QUOTE_FAILURE',
    APPROVE_QUOTE_SUCCESS: 'APPROVE_QUOTE_SUCCESS',

    GET_QUOTES_TO_MAKE_ORDER_REQUEST: 'GET_QUOTES_TO_MAKE_ORDER_REQUEST',
    GET_QUOTES_TO_MAKE_ORDER_FAILURE: 'GET_QUOTES_TO_MAKE_ORDER_FAILURE',
    GET_QUOTES_TO_MAKE_ORDER_SUCCESS: 'GET_QUOTES_TO_MAKE_ORDER_SUCCESS',

    GET_QUOTE_DETAIL_REQUEST: 'GET_QUOTE_DETAIL_REQUEST',
    GET_QUOTE_DETAIL_SUCCESS: 'GET_QUOTE_DETAIL_SUCCESS',
    GET_QUOTE_DETAIL_FAILURE: 'GET_QUOTE_DETAIL_FAILURE',

    COUNT_QUOTE_REQUEST: 'COUNT_QUOTE_REQUEST',
    COUNT_QUOTE_SUCCESS: 'COUNT_QUOTE_SUCCESS',
    COUNT_QUOTE_FAILURE: 'COUNT_QUOTE_FAILURE',

    GET_TOP_GOODS_CARE_REQUEST: 'GET_TOP_GOODS_CARE_REQUEST',
    GET_TOP_GOODS_CARE_SUCCESS: 'GET_TOP_GOODS_CARE_SUCCESS',
    GET_TOP_GOODS_CARE_FAILURE: 'GET_TOP_GOODS_CARE_FAILURE',
}