export const taskTemplateConstants = {
    GETALL_TEMPLATE_REQUEST: 'GETALL_TEMPLATE_REQUEST',
    GETALL_TEMPLATE_SUCCESS: 'GETALL_TEMPLATE_SUCCESS',
    GETALL_TEMPLATE_FAILURE: 'GETALL_TEMPLATE_FAILURE',
    
    GETTEMPLATE_BYID_REQUEST: 'GETTEMPLATE_BYID_REQUEST',
    GETTEMPLATE_BYID_SUCCESS: 'GETTEMPLATE_BYID_SUCCESS',
    GETTEMPLATE_BYID_FAILURE: 'GETTEMPLATE_BYID_FAILURE',
    
    GETTEMPLATE_BYROLE_REQUEST: 'GETTEMPLATE_BYROLE_REQUEST',
    GETTEMPLATE_BYROLE_SUCCESS: 'GETTEMPLATE_BYROLE_SUCCESS',
    GETTEMPLATE_BYROLE_FAILURE: 'GETTEMPLATE_BYROLE_FAILURE',
    
    GETTEMPLATE_BYUSER_REQUEST: 'GETTEMPLATE_BYUSER_REQUEST',
    GETTEMPLATE_BYUSER_SUCCESS: 'GETTEMPLATE_BYUSER_SUCCESS',
    GETTEMPLATE_BYUSER_FAILURE: 'GETTEMPLATE_BYUSER_FAILURE',
    
    ADDNEW_TEMPLATE_REQUEST: 'ADD_TEMPLATE_REQUEST',
    ADDNEW_TEMPLATE_SUCCESS: 'ADD_TEMPLATE_SUCCESS',
    ADDNEW_TEMPLATE_FAILURE: 'ADD_TEMPLATE_FAILURE',
    
    EDIT_TEMPLATE_REQUEST: 'EDIT_TEMPLATE_REQUEST',
    EDIT_TEMPLATE_SUCCESS: 'EDIT_TEMPLATE_SUCCESS',
    EDIT_TEMPLATE_FAILURE: 'EDIT_TEMPLATE_FAILURE',
    
    DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
    DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
    DELETE_TEMPLATE_FAILURE: 'DELETE_TEMPLATE_FAILURE',

    IMPORT_TEMPLATE_REQUEST: 'IMPORT_TEMPLATE_REQUEST',
    IMPORT_TEMPLATE_SUCCESS: 'IMPORT_TEMPLATE_SUCCESS',
    IMPORT_TEMPLATE_FAILURE: 'IMPORT_TEMPLATE_FAILURE',
};