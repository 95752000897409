export const SalesOrderConstants = {
    GET_ALL_SALES_ORDERS_REQUEST: 'GET_ALL_SALES_ORDERS_REQUEST',
    GET_ALL_SALES_ORDERS_FAILURE: 'GET_ALL_SALES_ORDERS_FAILURE',
    GET_ALL_SALES_ORDERS_SUCCESS: 'GET_ALL_SALES_ORDERS_SUCCESS',

    CREATE_SALES_ORDER_REQUEST: 'CREATE_SALES_ORDER_REQUEST',
    CREATE_SALES_ORDER_SUCCESS: 'CREATE_SALES_ORDER_SUCCESS',
    CREATE_SALES_ORDER_FAILURE: 'CREATE_SALES_ORDER_FAILURE',

    EDIT_SALES_ORDER_REQUEST: 'EDIT_SALES_ORDER_REQUEST',
    EDIT_SALES_ORDER_SUCCESS: 'EDIT_SALES_ORDER_SUCCESS',
    EDIT_SALES_ORDER_FAILURE: 'EDIT_SALES_ORDER_FAILURE',

    APPROVE_SALES_ORDER_REQUEST: 'APPROVE_SALES_ORDER_REQUEST',
    APPROVE_SALES_ORDER_FAILURE: 'APPROVE_SALES_ORDER_FAILURE',
    APPROVE_SALES_ORDER_SUCCESS: 'APPROVE_SALES_ORDER_SUCCESS',

    ADD_MANUFACTURING_PLAN_FOR_GOOD_REQUEST: 'ADD_MANUFACTURING_PLAN_FOR_GOOD_REQUEST',
    ADD_MANUFACTURING_PLAN_FOR_GOOD_FAILURE: 'ADD_MANUFACTURING_PLAN_FOR_GOOD_FAILURE',
    ADD_MANUFACTURING_PLAN_FOR_GOOD_SUCCESS: 'ADD_MANUFACTURING_PLAN_FOR_GOOD_SUCCESS',

    GET_SALES_ORDER_BY_MANUFACTURING_WORKS_REQUEST: 'GET_SALES_ORDER_BY_MANUFACTURING_WORKS_REQUEST',
    GET_SALES_ORDER_BY_MANUFACTURING_WORKS_FAILURE: 'GET_SALES_ORDER_BY_MANUFACTURING_WORKS_FAILURE',
    GET_SALES_ORDER_BY_MANUFACTURING_WORKS_SUCCESS: 'GET_SALES_ORDER_BY_MANUFACTURING_WORKS_SUCCESS',

    GET_SALES_ORDERS_FOR_PAYMENT_REQUEST: 'GET_SALES_ORDERS_FOR_PAYMENT_REQUEST',
    GET_SALES_ORDERS_FOR_PAYMENT_FAILURE: 'GET_SALES_ORDERS_FOR_PAYMENT_FAILURE',
    GET_SALES_ORDERS_FOR_PAYMENT_SUCCESS: 'GET_SALES_ORDERS_FOR_PAYMENT_SUCCESS',

    GET_SALES_ORDER_DETAIL_REQUEST: 'GET_SALES_ORDER_DETAIL_REQUEST',
    GET_SALES_ORDER_DETAIL_SUCCESS: 'GET_SALES_ORDER_DETAIL_SUCCESS',
    GET_SALES_ORDER_DETAIL_FAILURE: 'GET_SALES_ORDER_DETAIL_FAILURE',

    COUNT_SALES_ORDER_REQUEST: 'COUNT_SALES_ORDER_REQUEST',
    COUNT_SALES_ORDER_SUCCESS: 'COUNT_SALES_ORDER_SUCCESS',
    COUNT_SALES_ORDER_FAILURE: 'COUNT_SALES_ORDER_FAILURE',

    GET_TOP_GOODS_SOLD_REQUEST: 'GET_TOP_GOODS_SOLD_REQUEST',
    GET_TOP_GOODS_SOLD_SUCCESS: 'GET_TOP_GOODS_SOLD_SUCCESS',
    GET_TOP_GOODS_SOLD_FAILURE: 'GET_TOP_GOODS_SOLD_FAILURE',

    GET_SALES_FOR_DEPARTMENTS_REQUEST: 'GET_SALES_FOR_DEPARTMENTS_REQUEST',
    GET_SALES_FOR_DEPARTMENTS_SUCCESS: 'GET_SALES_FOR_DEPARTMENTS_SUCCESS',
    GET_SALES_FOR_DEPARTMENTS_FAILURE: 'GET_SALES_FOR_DEPARTMENTS_FAILURE',

    GET_NUMBER_SALES_ORDER_WORKS_REQUEST: 'GET_NUMBER_SALES_ORDER_WORKS_REQUEST',
    GET_NUMBER_SALES_ORDER_WORKS_SUCCESS: 'GET_NUMBER_SALES_ORDER_WORKS_SUCCESS',
    GET_NUMBER_SALES_ORDER_WORKS_FAILURE: 'GET_NUMBER_SALES_ORDER_WORKS_FAILURE',
}