export const CrmCustomerRankPointConstants = {
    GET_CRM_CUSTOMERRANKPOINTS_REQUEST: 'GET_CRM_CUSTOMERRANKPOINTS_REQUEST',
    GET_CRM_CUSTOMERRANKPOINTS_SUCCESS: 'GET_CRM_CUSTOMERRANKPOINTS_SUCCESS',
    GET_CRM_CUSTOMERRANKPOINTS_FAILE: 'GET_CRM_CARETYPES_FAILE',

    GET_CRM_CUSTOMERRANKPOINT_REQUEST: 'GET_CRM_CUSTOMERRANKPOINT_REQUEST',
    GET_CRM_CUSTOMERRANKPOINT_SUCCESS: 'GET_CRM_CUSTOMERRANKPOINT_SUCCESS',
    GET_CRM_CUSTOMERRANKPOINT_FAILE: 'GET_CRM_CUSTOMERRANKPOINT_FAILE',

    CREATE_CRM_CUSTOMERRANKPOINT_REQUEST: 'CREATE_CRM_CUSTOMERRANKPOINT_REQUEST',
    CREATE_CRM_CUSTOMERRANKPOINT_SUCCESS: 'CREATE_CRM_CUSTOMERRANKPOINT_SUCCESS',
    CREATE_CRM_CUSTOMERRANKPOINT_FAILE: 'CREATE_CRM_CUSTOMERRANKPOINT_FAILE',

    EDIT_CRM_CUSTOMERRANKPOINT_REQUEST: 'EDIT_CRM_CUSTOMERRANKPOINT_REQUEST',
    EDIT_CRM_CUSTOMERRANKPOINT_SUCCESS: 'EDIT_CRM_CUSTOMERRANKPOINT_SUCCESS',
    EDIT_CRM_CUSTOMERRANKPOINT_FAILE: 'EDIT_CRM_CUSTOMERRANKPOINT_FAILE',

    DELETE_CRM_CUSTOMERRANKPOINT_REQUEST: 'DELETE_CRM_CUSTOMERRANKPOINT_REQUEST',
    DELETE_CRM_CUSTOMERRANKPOINT_SUCCESS: 'DELETE_CRM_CUSTOMERRANKPOINT_SUCCESS',
    DELETE_CRM_CUSTOMERRANKPOINT_FAILE: 'DELETE_CRM_CUSTOMERRANKPOINT_FAILE',
}