export const TaskReportConstants ={
    
    GET_TASK_REPORT_REQUEST: 'GET_TASK_REPORT_REQUEST',
    GET_TASK_REPORT_SUCCESS: 'GET_TASK_REPORT_SUCCESS',
    GET_TASK_REPORT_FAILURE: 'GET_TASK_REPORT_FAILURE',

    EDIT_TASK_REPORT_REQUEST: 'EDIT_TASK_REPORT_REQUEST',
    EDIT_TASK_REPORT_SUCCESS: 'EDIT_TASK_REPORT_SUCCESS',
    EDIT_TASK_REPORT_FAILURE: 'EDIT_TASK_REPORT_FAILURE',

    CREATE_TASK_REPORT_REQUEST: 'CREATE_TASK_REPORT_REQUEST',
    CREATE_TASK_REPORT_SUCCESS: 'CREATE_TASK_REPORT_SUCCESS',
    CREATE_TASK_REPORT_FAILURE: 'CREATE_TASK_REPORT_FAILURE',

    DELETE_TASK_REPORT_REQUEST: 'DELETE_TASK_REPORT_REQUEST',
    DELETE_TASK_REPORT_SUCCESS: 'DELETE_TASK_REPORT_SUCCESS',
    DELETE_TASK_REPORT_FAILURE: 'DELETE_TASK_REPORT_FAILURE',

    GET_TASK_REPORT_BY_ID_REQUEST: 'GET_TASK_REPORT_BY_ID_REQUEST',
    GET_TASK_REPORT_BY_ID_SUCCESS: 'GET_TASK_REPORT_BY_ID_SUCCESS',
    GET_TASK_REPORT_BY_ID_FAILURE: 'GET_TASK_REPORT_BY_ID_FAILURE',
}