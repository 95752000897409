export const delegationConstants = {
    GET_ALL_DELEGATIONS_REQUEST: "GET_ALL_DELEGATIONS_REQUEST",
    GET_ALL_DELEGATIONS_SUCCESS: "GET_ALL_DELEGATIONS_SUCCESS",
    GET_ALL_DELEGATIONS_FAILURE: "GET_ALL_DELEGATIONS_FAILURE",

    DELETE_DELEGATION_REQUEST: "DELETE_DELETE_DELEGATION_REQUEST",
    DELETE_DELEGATION_SUCCESS: "DELETE_DELEGATION_SUCCESS",
    DELETE_DELEGATION_FAILURE: "DELETE_DELEGATION_FAILURE",

    REVOKE_DELEGATION_REQUEST: "REVOKE_REVOKE_DELEGATION_REQUEST",
    REVOKE_DELEGATION_SUCCESS: "REVOKE_DELEGATION_SUCCESS",
    REVOKE_DELEGATION_FAILURE: "REVOKE_DELEGATION_FAILURE",

    CREATE_DELEGATION_REQUEST: "CREATE_DELEGATION_REQUEST",
    CREATE_DELEGATION_SUCCESS: "CREATE_DELEGATION_SUCCESS",
    CREATE_DELEGATION_FAILURE: "CREATE_DELEGATION_FAILURE",

    EDIT_DELEGATION_REQUEST: "EDIT_DELEGATION_REQUEST",
    EDIT_DELEGATION_SUCCESS: "EDIT_DELEGATION_SUCCESS",
    EDIT_DELEGATION_FAILURE: "EDIT_DELEGATION_FAILURE",

    GET_DETAIL_DELEGATION_REQUEST: "GET_DETAIL_DELEGATION_REQUEST",
    GET_DETAIL_DELEGATION_SUCCESS: "GET_DETAIL_DELEGATION_SUCCESS",
    GET_DETAIL_DELEGATION_FAILURE: "GET_DETAIL_DELEGATION_FAILURE",

    GET_ALL_DELEGATIONS_TASK_REQUEST: "GET_ALL_DELEGATIONS_TASK_REQUEST",
    GET_ALL_DELEGATIONS_TASK_SUCCESS: "GET_ALL_DELEGATIONS_TASK_SUCCESS",
    GET_ALL_DELEGATIONS_TASK_FAILURE: "GET_ALL_DELEGATIONS_TASK_FAILURE",

    DELETE_TASK_DELEGATION_REQUEST: "DELETE_DELETE_TASK_DELEGATION_REQUEST",
    DELETE_TASK_DELEGATION_SUCCESS: "DELETE_TASK_DELEGATION_SUCCESS",
    DELETE_TASK_DELEGATION_FAILURE: "DELETE_TASK_DELEGATION_FAILURE",

    REVOKE_TASK_DELEGATION_REQUEST: "REVOKE_REVOKE_TASK_DELEGATION_REQUEST",
    REVOKE_TASK_DELEGATION_SUCCESS: "REVOKE_TASK_DELEGATION_SUCCESS",
    REVOKE_TASK_DELEGATION_FAILURE: "REVOKE_TASK_DELEGATION_FAILURE",

    CREATE_TASK_DELEGATION_REQUEST: "CREATE_TASK_DELEGATION_REQUEST",
    CREATE_TASK_DELEGATION_SUCCESS: "CREATE_TASK_DELEGATION_SUCCESS",
    CREATE_TASK_DELEGATION_FAILURE: "CREATE_TASK_DELEGATION_FAILURE",

    EDIT_TASK_DELEGATION_REQUEST: "EDIT_TASK_DELEGATION_REQUEST",
    EDIT_TASK_DELEGATION_SUCCESS: "EDIT_TASK_DELEGATION_SUCCESS",
    EDIT_TASK_DELEGATION_FAILURE: "EDIT_TASK_DELEGATION_FAILURE",


}