export const AnnualLeaveConstants = {
    GET_ANNUAL_LEAVE_REQUEST: 'GET_ANNUAL_LEAVE_REQUEST',
    GET_ANNUAL_LEAVE_SUCCESS: 'GET_ANNUAL_LEAVE_SUCCESS',
    GET_ANNUAL_LEAVE_FAILURE: 'GET_ANNUAL_LEAVE_FAILURE',

    GET_ANNUAL_LEAVE_BY_ID_REQUEST: 'GET_ANNUAL_LEAVE_BY_ID_REQUEST',
    GET_ANNUAL_LEAVE_BY_ID_SUCCESS: 'GET_ANNUAL_LEAVE_BY_ID_SUCCESS',
    GET_ANNUAL_LEAVE_BY_ID_FAILURE: 'GET_ANNUAL_LEAVE_BY_ID_FAILURE',

    CREATE_ANNUAL_LEAVE_REQUEST: 'CREATE_NEW_ANNUAL_LEAVE_REQUEST',
    CREATE_ANNUAL_LEAVE_SUCCESS: 'CREATE_NEW_ANNUAL_LEAVE_SUCCESS',
    CREATE_ANNUAL_LEAVE_FAILURE: 'CREATE_NEW_ANNUAL_LEAVE_FAILURE',

    DELETE_ANNUAL_LEAVE_REQUEST: 'DELETE_ANNUAL_LEAVE_REQUEST',
    DELETE_ANNUAL_LEAVE_SUCCESS:  'DELETE_ANNUAL_LEAVE_SUCCESS',
    DELETE_ANNUAL_LEAVE_FAILURE:  'DELETE_ANNUAL_LEAVE_FAILURE',

    UPDATE_ANNUAL_LEAVE_REQUEST: 'UPDATE_ANNUAL_LEAVE_REQUEST',
    UPDATE_ANNUAL_LEAVE_SUCCESS: 'UPDATE_ANNUAL_LEAVE_SUCCESS',
    UPDATE_ANNUAL_LEAVE_FAILURE: 'UPDATE_ANNUAL_LEAVE_FAILURE',

    IMPORT_ANNUAL_LEAVE_REQUEST: "IMPORT_ANNUAL_LEAVE_EMPLOYEE_REQUEST",
    IMPORT_ANNUAL_LEAVE_SUCCESS: "IMPORT_ANNUAL_LEAVE_EMPLOYEE_SUCCESS",
    IMPORT_ANNUAL_LEAVE_FAILURE: "IMPORT_ANNUAL_LEAVE_EMPLOYEE_FAILURE",

    REQUEST_TO_CHANGE_ANNUALEAVE_REQUEST: 'REQUEST_TO_CHANGE_ANNUALEAVE_REQUEST',
    REQUEST_TO_CHANGE_ANNUALEAVE_SUCCESS: 'REQUEST_TO_CHANGE_ANNUALEAVE_SUCCESS',
    REQUEST_TO_CHANGE_ANNUALEAVE_FAILURE: 'REQUEST_TO_CHANGE_ANNUALEAVE_FAILURE',
};