export const transportScheduleConstants = {
    GET_TRANSPORT_SCHEDULE_BY_PLAN_ID_REQUEST: "GET_TRANSPORT_SCHEDULE_BY_PLAN_ID_REQUEST",
    GET_TRANSPORT_SCHEDULE_BY_PLAN_ID_SUCCESS: "GET_TRANSPORT_SCHEDULE_BY_PLAN_ID_SUCCESS",
    GET_TRANSPORT_SCHEDULE_BY_PLAN_ID_FAILURE: "GET_TRANSPORT_SCHEDULE_BY_PLAN_ID_FAILURE",

    EDIT_TRANSPORT_SCHEDULE_BY_PLAN_ID_REQUEST: "EDIT_TRANSPORT_SCHEDULE_BY_PLAN_ID_REQUEST",
    EDIT_TRANSPORT_SCHEDULE_BY_PLAN_ID_SUCCESS: "EDIT_TRANSPORT_SCHEDULE_BY_PLAN_ID_SUCCESS",
    EDIT_TRANSPORT_SCHEDULE_BY_PLAN_ID_FAILURE: "EDIT_TRANSPORT_SCHEDULE_BY_PLAN_ID_FAILURE",

    CHANGE_TRANSPORT_REQUIREMENT_PROCESS_REQUEST: "CHANGE_TRANSPORT_REQUIREMENT_PROCESS_REQUEST",
    CHANGE_TRANSPORT_REQUIREMENT_PROCESS_SUCCESS: "CHANGE_TRANSPORT_REQUIREMENT_PROCESS_SUCCESS",
    CHANGE_TRANSPORT_REQUIREMENT_PROCESS_FAILURE: "CHANGE_TRANSPORT_REQUIREMENT_PROCESS_FAILURE",

    GET_ALL_TRANSOPRT_SCHEDULE_ROUTE_BY_CARRIER_ID_REQUEST: "GET_ALL_TRANSOPRT_SCHEDULE_ROUTE_BY_CARRIER_ID_REQUEST",
    GET_ALL_TRANSOPRT_SCHEDULE_ROUTE_BY_CARRIER_ID_SUCCESS: "GET_ALL_TRANSOPRT_SCHEDULE_ROUTE_BY_CARRIER_ID_SUCCESS",
    GET_ALL_TRANSOPRT_SCHEDULE_ROUTE_BY_CARRIER_ID_FAILURE: "GET_ALL_TRANSOPRT_SCHEDULE_ROUTE_BY_CARRIER_ID_FAILURE",
    
    CHANGE_TRANSPORT_STATUS_BY_CARRIER_ID_REQUEST: "CHANGE_TRANSPORT_STATUS_BY_CARRIER_ID_REQUEST",
    CHANGE_TRANSPORT_STATUS_BY_CARRIER_ID_SUCCESS: "CHANGE_TRANSPORT_STATUS_BY_CARRIER_ID_SUCCESS",
    CHANGE_TRANSPORT_STATUS_BY_CARRIER_ID_FAILURE: "CHANGE_TRANSPORT_STATUS_BY_CARRIER_ID_FAILURE",

    DRIVER_SEND_MESSAGE_REQUEST: "DRIVER_SEND_MESSAGE_REQUEST",
    DRIVER_SEND_MESSAGE_SUCCESS: "DRIVER_SEND_MESSAGE_SUCCESS",
    DRIVER_SEND_MESSAGE_FAILURE: "DRIVER_SEND_MESSAGE_FAILURE",
}
