export const millConstants = {
    GET_ALL_MANUFACTURING_MILLS_REQUEST: 'GET_ALL_MANUFACTURING_MILLS_REQUEST',
    GET_ALL_MANUFACTURING_MILLS_SUCCESS: 'GET_ALL_MANUFACTURING_MILLS_SUCCESS',
    GET_ALL_MANUFACTURING_MILLS_FAILURE: 'GET_ALL_MANUFACTURING_MILLS_FAILURE',

    CREATE_MANUFACTURING_MILL_REQUEST: 'CREATE_MANUFACTURING_MILL_REQUEST',
    CREATE_MANUFACTURING_MILL_SUCCESS: 'CREATE_MANUFACTURING_MILL_SUCCESS',
    CREATE_MANUFACTURING_MILL_FAILURE: 'CREATE_MANUFACTURING_MILL_FAILURE',

    EDIT_MANUFACTURING_MILL_REQUEST: 'EDIT_MANUFACTURING_MILL_REQUEST',
    EDIT_MANUFACTURING_MILL_SUCCESS: 'EDIT_MANUFACTURING_MILL_SUCCESS',
    EDIT_MANUFACTURING_MILL_FAILURE: 'EDIT_MANUFACTURING_MILL_FAILURE',

    GET_DETAIL_MANUFACTURING_MILL_REQUEST: 'GET_DETAIL_MANUFACTURING_MILL_REQUEST',
    GET_DETAIL_MANUFACTURING_MILL_SUCCESS: 'GET_DETAIL_MANUFACTURING_MILL_SUCCESS',
    GET_DETAIL_MANUFACTURING_MILL_FAILURE: 'GET_DETAIL_MANUFACTURING_MILL_FAILURE'
}