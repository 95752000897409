export const RoleConstants = {
    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILE: 'GET_ROLES_FAILE',

    GET_ROLES_PAGINATE_REQUEST: 'GET_ROLES_PAGINATE_REQUEST',
    GET_ROLES_PAGINATE_SUCCESS: 'GET_ROLES_PAGINATE_SUCCESS',
    GET_ROLES_PAGINATE_FAILE: 'GET_ROLES_PAGINATE_FAILE',

    SHOW_ROLE_REQUEST: 'SHOW_ROLE_REQUEST',
    SHOW_ROLE_SUCCESS: 'SHOW_ROLE_SUCCESS',
    SHOW_ROLE_FAILE: 'SHOW_ROLE_FAILE',

    CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
    CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
    CREATE_ROLE_FAILE: 'CREATE_ROLE_FAILE',

    EDIT_ROLE_REQUEST: 'EDIT_ROLE_REQUEST',
    EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
    EDIT_ROLE_FAILE: 'EDIT_ROLE_FAILE',

    DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_FAILE: 'DELETE_ROLE_FAILE',

    SET_FILTER: 'SET_FILTER',

    IMPORT_ROLE_REQUEST: 'IMPORT_ROLE_REQUEST',
    IMPORT_ROLE_SUCCESS: 'IMPORT_ROLE_SUCCESS',
    IMPORT_ROLE_FAILE: 'IMPORT_ROLE_FAILE',

    CREATE_ROLE_ATTRIBUTE_REQUEST: 'CREATE_ROLE_ATTRIBUTE_REQUEST',
    CREATE_ROLE_ATTRIBUTE_SUCCESS: 'CREATE_ROLE_ATTRIBUTE_SUCCESS',
    CREATE_ROLE_ATTRIBUTE_FAILE: 'CREATE_ROLE_ATTRIBUTE_FAILE'

}