export const transportPlanConstants = {
    GET_ALL_TRANSPORT_PLANS_REQUEST: "GET_ALL_TRANSPORT_PLANS_REQUEST",
    GET_ALL_TRANSPORT_PLANS_SUCCESS: "GET_ALL_TRANSPORT_PLANS_SUCCESS",
    GET_ALL_TRANSPORT_PLANS_FAILURE: "GET_ALL_TRANSPORT_PLANS_FAILURE",

    CREATE_TRANSPORT_PLAN_REQUEST: "CREATE_TRANSPORT_PLAN_REQUEST",
    CREATE_TRANSPORT_PLAN_SUCCESS: "CREATE_TRANSPORT_PLAN_SUCCESS",
    CREATE_TRANSPORT_PLAN_FAILURE: "CREATE_TRANSPORT_PLAN_FAILURE",

    GET_DETAIL_TRANSPORT_PLAN_REQUEST: "GET_DETAIL_TRANSPORT_PLAN_REQUEST",
    GET_DETAIL_TRANSPORT_PLAN_SUCCESS: "GET_DETAIL_TRANSPORT_PLAN_SUCCESS",
    GET_DETAIL_TRANSPORT_PLAN_FAILURE: "GET_DETAIL_TRANSPORT_PLAN_FAILURE",

    GET_DETAIL_TRANSPORT_PLAN_2_REQUEST: "GET_DETAIL_TRANSPORT_PLAN_REQUEST",
    GET_DETAIL_TRANSPORT_PLAN_2_SUCCESS: "GET_DETAIL_TRANSPORT_PLAN_SUCCESS",
    GET_DETAIL_TRANSPORT_PLAN_2_FAILURE: "GET_DETAIL_TRANSPORT_PLAN_FAILURE",

    EDIT_TRANSPORT_PLAN_REQUEST: "EDIT_TRANSPORT_PLAN_REQUEST",
    EDIT_TRANSPORT_PLAN_SUCCESS: "EDIT_TRANSPORT_PLAN_SUCCESS",
    EDIT_TRANSPORT_PLAN_FAILURE: "EDIT_TRANSPORT_PLAN_FAILURE",

    ADD_TRANSPORT_REQUIREMENT_TO_PLAN_REQUEST: "ADD_TRANSPORT_REQUIREMENT_TO_PLAN_REQUEST",
    ADD_TRANSPORT_REQUIREMENT_TO_PLAN_SUCCESS: "ADD_TRANSPORT_REQUIREMENT_TO_PLAN_SUCCESS",
    ADD_TRANSPORT_REQUIREMENT_TO_PLAN_FAILURE: "ADD_TRANSPORT_REQUIREMENT_TO_PLAN_FAILURE",

    ADD_TRANSPORT_VEHICLE_TO_PLAN_REQUEST: "ADD_TRANSPORT_VEHICLE_TO_PLAN_REQUEST",
    ADD_TRANSPORT_VEHICLE_TO_PLAN_SUCCESS: "ADD_TRANSPORT_VEHICLE_TO_PLAN_SUCCESS",
    ADD_TRANSPORT_VEHICLE_TO_PLAN_FAILURE: "ADD_TRANSPORT_VEHICLE_TO_PLAN_FAILURE",

    DELETE_TRANSPORT_PLAN_REQUEST: "DELETE_TRANSPORT_PLAN_REQUEST",
    DELETE_TRANSPORT_PLAN_SUCCESS: "DELETE_TRANSPORT_PLAN_SUCCESS",
    DELETE_TRANSPORT_PLAN_FAILURE: "DELETE_TRANSPORT_PLAN_FAILURE",
}
