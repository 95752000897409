export const DocumentConstants = {
    // Administration
    GET_DOCUMENTS_REQUEST: 'GET_DOCUMENTS_REQUEST',
    GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
    GET_DOCUMENTS_FAILE: 'GET_DOCUMENTS_FAILE',

    PAGINATE_DOCUMENTS_REQUEST: 'PAGINATE_DOCUMENTS_REQUEST',
    PAGINATE_DOCUMENTS_SUCCESS: 'PAGINATE_DOCUMENTS_SUCCESS',
    PAGINATE_DOCUMENTS_FAILE: 'PAGINATE_DOCUMENTS_FAILE',

    CREATE_DOCUMENT_REQUEST: 'CREATE_DOCUMENT_REQUEST',
    CREATE_DOCUMENT_SUCCESS: 'CREATE_DOCUMENT_SUCCESS',
    CREATE_DOCUMENT_FAILE: 'CREATE_DOCUMENT_FAILE',

    IMPORT_DOCUMENT_REQUEST: 'IMPORT_DOCUMENT_REQUEST',
    IMPORT_DOCUMENT_SUCCESS: 'IMPORT_DOCUMENT_SUCCESS',
    IMPORT_DOCUMENT_FAILE: 'IMPORT_DOCUMENT_FAILE',

    INCREASE_NUMBER_VIEW_DOCUMENT_REQUEST: 'INCREASE_NUMBER_VIEW_DOCUMENT_REQUEST',
    INCREASE_NUMBER_VIEW_DOCUMENT_SUCCESS: 'INCREASE_NUMBER_VIEW_DOCUMENT_SUCCESS',
    INCREASE_NUMBER_VIEW_DOCUMENT_FAILE: 'INCREASE_NUMBER_VIEW_DOCUMENT_FAILE',

    EDIT_DOCUMENT_REQUEST: 'EDIT_DOCUMENT_REQUEST',
    EDIT_DOCUMENT_SUCCESS: 'EDIT_DOCUMENT_SUCCESS',
    EDIT_DOCUMENT_FAILE: 'EDIT_DOCUMENT_FAILE',

    ADD_VERSION_DOCUMENT_SUCCESS: 'ADD_VERSION_DOCUMENT_SUCCESS',
    ADD_VERSION_DOCUMENT_FAILE: 'ADD_VERSION_DOCUMENT_FAILE',
    EDIT_VERSION_DOCUMENT_SUCCESS: 'EDIT_VERSION_DOCUMENT_SUCCESS',

    EDIT_VERSION_DOCUMENT_FAILE: 'EDIT_VERSION_DOCUMENT_FAILE',
    DELETE_VERSION_DOCUMENT_SUCCESS: 'DELETE_VERSION_DOCUMENT_SUCCESS',
    DELETE_VERSION_DOCUMENT_FAILE: 'DELETE_VERSION_DOCUMENT_FAILE',

    DELETE_DOCUMENT_REQUEST: 'DELETE_DOCUMENT_REQUEST',
    DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
    DELETE_DOCUMENT_FAILE: 'DELETE_DOCUMENT_FAILE',

    DOWNLOAD_DOCUMENT_FILE_REQUEST: 'DOWNLOAD_DOCUMENT_FILE_REQUEST',
    DOWNLOAD_DOCUMENT_FILE_SUCCESS: 'DOWNLOAD_DOCUMENT_FILE_SUCCESS',
    DOWNLOAD_DOCUMENT_FILE_FAILE: 'DOWNLOAD_DOCUMENT_FILE_FAILE',

    DOWNLOAD_DOCUMENT_FILE_SCAN_REQUEST: 'DOWNLOAD_DOCUMENT_FILE_SCAN_REQUEST',
    DOWNLOAD_DOCUMENT_FILE_SCAN_SUCCESS: 'DOWNLOAD_DOCUMENT_FILE_SCAN_SUCCESS',
    DOWNLOAD_DOCUMENT_FILE_SCAN_FAILE: 'DOWNLOAD_DOCUMENT_FILE_SCAN_FAILE',

    GET_DOCUMENT_CATEGORIES_REQUEST: 'GET_DOCUMENT_CATEGORIES_REQUEST',
    GET_DOCUMENT_CATEGORIES_SUCCESS: 'GET_DOCUMENT_CATEGORIES_SUCCESS',
    GET_DOCUMENT_CATEGORIES_FAILE: 'GET_DOCUMENT_CATEGORIES_FAILE',

    CREATE_DOCUMENT_CATEGORY_REQUEST: 'CREATE_DOCUMENT_CATEGORY_REQUEST',
    CREATE_DOCUMENT_CATEGORY_SUCCESS: 'CREATE_DOCUMENT_CATEGORY_SUCCESS',
    CREATE_DOCUMENT_CATEGORY_FAILE: 'CREATE_DOCUMENT_CATEGORY_FAILE',

    PAGINATE_DOCUMENT_CATEGORIES_REQUEST: 'PAGINATE_DOCUMENT_CATEGORIES_REQUEST',
    PAGINATE_DOCUMENT_CATEGORIES_SUCCESS: 'PAGINATE_DOCUMENT_CATEGORIES_SUCCESS',
    PAGINATE_DOCUMENT_CATEGORIES_FAILE: 'PAGINATE_DOCUMENT_CATEGORIES_FAILE',

    EDIT_DOCUMENT_CATEGORY_REQUEST: 'EDIT_DOCUMENT_CATEGORY_REQUEST',
    EDIT_DOCUMENT_CATEGORY_SUCCESS: 'EDIT_DOCUMENT_CATEGORY_SUCCESS',
    EDIT_DOCUMENT_CATEGORY_FAILE: 'EDIT_DOCUMENT_CATEGORY_FAILE',

    DELETE_DOCUMENT_CATEGORY_REQUEST: 'DELETE_DOCUMENT_CATEGORY_REQUEST',
    DELETE_DOCUMENT_CATEGORY_SUCCESS: 'DELETE_DOCUMENT_CATEGORY_SUCCESS',
    DELETE_DOCUMENT_CATEGORY_FAILE: 'DELETE_DOCUMENT_CATEGORY_FAILE',

    IMPORT_DOCUMENT_CATEGORY_REQUEST: 'IMPORT_DOCUMENT_CATEGORY_REQUEST',
    IMPORT_DOCUMENT_CATEGORY_SUCCESS: 'IMPORT_DOCUMENT_CATEGORY_SUCCESS',
    IMPORT_DOCUMENT_CATEGORY_FAILE: 'IMPORT_DOCUMENT_CATEGORY_FAILE',

    GET_DOCUMENT_DOMAINS_REQUEST: 'GET_DOCUMENT_DOMAINS_REQUEST',
    GET_DOCUMENT_DOMAINS_SUCCESS: 'GET_DOCUMENT_DOMAINS_SUCCESS',
    GET_DOCUMENT_DOMAINS_FAILE: 'GET_DOCUMENT_DOMAINS_FAILE',

    CREATE_DOCUMENT_DOMAIN_REQUEST: 'CREATE_DOCUMENT_DOMAIN_REQUEST',
    CREATE_DOCUMENT_DOMAIN_SUCCESS: 'CREATE_DOCUMENT_DOMAIN_SUCCESS',
    CREATE_DOCUMENT_DOMAIN_FAILE: 'CREATE_DOCUMENT_DOMAIN_FAILE',

    EDIT_DOCUMENT_DOMAIN_REQUEST: 'EDIT_DOCUMENT_DOMAIN_REQUEST',
    EDIT_DOCUMENT_DOMAIN_SUCCESS: 'EDIT_DOCUMENT_DOMAIN_SUCCESS',
    EDIT_DOCUMENT_DOMAIN_FAILE: 'EDIT_DOCUMENT_DOMAIN_FAILE',

    DELETE_DOCUMENT_DOMAIN_REQUEST: 'DELETE_DOCUMENT_DOMAIN_REQUEST',
    DELETE_DOCUMENT_DOMAIN_SUCCESS: 'DELETE_DOCUMENT_DOMAIN_SUCCESS',
    DELETE_DOCUMENT_DOMAIN_FAILE: 'DELETE_DOCUMENT_DOMAIN_FAILE',

    IMPORT_DOCUMENT_DOMAIN_REQUEST: 'IMPORT_DOCUMENT_DOMAIN_REQUEST',
    IMPORT_DOCUMENT_DOMAIN_SUCCESS: 'IMPORT_DOCUMENT_DOMAIN_SUCCESS',
    IMPORT_DOCUMENT_DOMAIN_FAILE: 'IMPORT_DOCUMENT_DOMAIN_FAILE',




    // User
    GET_DOCUMENTS_USER_CAN_VIEW_REQUEST: 'GET_DOCUMENTS_USER_CAN_VIEW_REQUEST',
    GET_DOCUMENTS_USER_CAN_VIEW_SUCCESS: 'GET_DOCUMENTS_USER_CAN_VIEW_SUCCESS',
    GET_DOCUMENTS_USER_CAN_VIEW_FAILE: 'GET_DOCUMENTS_USER_CAN_VIEW_FAILE',

    PAGINATE_DOCUMENTS_USER_CAN_VIEW_REQUEST: 'PAGINATE_DOCUMENTS_USER_CAN_VIEW_REQUEST',
    PAGINATE_DOCUMENTS_USER_CAN_VIEW_SUCCESS: 'PAGINATE_DOCUMENTS_USER_CAN_VIEW_SUCCESS',
    PAGINATE_DOCUMENTS_USER_CAN_VIEW_FAILE: 'PAGINATE_DOCUMENTS_USER_CAN_VIEW_FAILE',

    GET_DOCUMENT_STATISTICS_DOWNLOADED_REQUEST: 'GET_DOCUMENT_STATISTICS_DOWNLOADED_REQUEST',
    GET_DOCUMENT_STATISTICS_DOWNLOADED_SUCCESS: 'GET_DOCUMENT_STATISTICS_DOWNLOADED_SUCCESS',
    GET_DOCUMENT_STATISTICS_DOWNLOADED_FAILE: 'GET_DOCUMENT_STATISTICS_DOWNLOADED_FAILE',

    GET_DOCUMENT_STATISTICS_COMMON_REQUEST: 'GET_DOCUMENT_STATISTICS_COMMON_REQUEST',
    GET_DOCUMENT_STATISTICS_COMMON_SUCCESS: 'GET_DOCUMENT_STATISTICS_COMMON_SUCCESS',
    GET_DOCUMENT_STATISTICS_COMMON_FAILE: 'GET_DOCUMENT_STATISTICS_COMMON_FAILE',

    GET_DOCUMENT_STATISTICS_LATEST_REQUEST: 'GET_DOCUMENT_STATISTICS_LATEST_REQUEST',
    GET_DOCUMENT_STATISTICS_LATEST_SUCCESS: 'GET_DOCUMENT_STATISTICS_LATEST_SUCCESS',
    GET_DOCUMENT_STATISTICS_LATEST_FAILE: 'GET_DOCUMENT_STATISTICS_LATEST_FAILE',

    GET_DATA_CHART_REQUEST : 'GET_DATA_CHART_REQUEST',
    GET_DATA_CHART_SUCCESS : 'GET_DATA_CHART_SUCCESS',
    GET_DATA_CHART_FAILE : 'GET_DATA_CHART_FAILE',
    
    GET_DOCUMENT_ARCHIVE_REQUEST: 'GET_DOCUMENT_ARCHIVE_REQUEST',
    GET_DOCUMENT_ARCHIVE_SUCCESS: 'GET_DOCUMENT_ARCHIVE_SUCCESS',
    GET_DOCUMENT_ARCHIVE_FAILE: 'GET_DOCUMENT_ARCHIVE_FAILE',

    CREATE_DOCUMENT_ARCHIVE_REQUEST: 'CREATE_DOCUMENT_ARCHIVE_REQUEST',
    CREATE_DOCUMENT_ARCHIVE_SUCCESS: 'CREATE_DOCUMENT_ARCHIVE_SUCCESS',
    CREATE_DOCUMENT_ARCHIVE_FAILE: 'CREATE_DOCUMENT_ARCHIVE_FAILE',

    EDIT_DOCUMENT_ARCHIVE_REQUEST: 'EDIT_DOCUMENT_ARCHIVE_REQUEST',
    EDIT_DOCUMENT_ARCHIVE_SUCCESS: 'EDIT_DOCUMENT_ARCHIVE_SUCCESS',
    EDIT_DOCUMENT_ARCHIVE_FAILE: 'EDIT_DOCUMENT_ARCHIVE_FAILE',

    DELETE_DOCUMENT_ARCHIVE_REQUEST: 'DELETE_DOCUMENT_ARCHIVE_REQUEST',
    DELETE_DOCUMENT_ARCHIVE_SUCCESS: 'DELETE_DOCUMENT_ARCHIVE_SUCCESS',
    DELETE_DOCUMENT_ARCHIVE_FAILE: 'DELETE_DOCUMENT_ARCHIVE_FAILE',

    IMPORT_DOCUMENT_ARCHIVE_REQUEST: 'IMPORT_DOCUMENT_ARCHIVE_REQUEST',
    IMPORT_DOCUMENT_ARCHIVE_SUCCESS: 'IMPORT_DOCUMENT_ARCHIVE_SUCCESS',
    IMPORT_DOCUMENT_ARCHIVE_FAILE: 'IMPORT_DOCUMENT_ARCHIVE_FAILE',

    DOWNLOAD_ALL_FILE_OF_DOCUMENT_REQUEST: 'DOWNLOAD_ALL_FILE_OF_DOCUMENT_REQUEST',
    DOWNLOAD_ALL_FILE_OF_DOCUMENT_SUCCESS: 'DOWNLOAD_ALL_FILE_OF_DOCUMENT_SUCCESS',
    DOWNLOAD_ALL_FILE_OF_DOCUMENT_FAILE: 'DOWNLOAD_ALL_FILE_OF_DOCUMENT_FAILE',
}