export const TagConstant = {
    GET_TAG_REQUEST: 'GET_TAG_REQUEST',
    GET_TAG_SUCCESS: 'GET_TAG_SUCCESS',
    GET_TAG_FAILURE: 'GET_TAG_FAILURE',

    CREATE_TAG_REQUEST: 'CREATE_NEW_TAG_REQUEST',
    CREATE_TAG_SUCCESS: 'CREATE_NEW_TAG_SUCCESS',
    CREATE_TAG_FAILURE: 'CREATE_NEW_TAG_FAILURE',

    DELETE_TAG_REQUEST: 'DELETE_TAG_REQUEST',
    DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
    DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',

    UPDATE_TAG_REQUEST: 'UPDATE_TAG_REQUEST',
    UPDATE_TAG_SUCCESS: 'UPDATE_TAG_SUCCESS',
    UPDATE_TAG_FAILURE: 'UPDATE_TAG_FAILURE',

};