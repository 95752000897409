export const BiddingPackageConstants = {
    GETALL_REQUEST: 'GET_BIDDING_PACKAGE_REQUEST',
    GETALL_SUCCESS: 'BIDDING_PACKAGE_GETALL_SUCCESS',
    GETALL_FAILURE: 'BIDDING_PACKAGE_GETALL_FAILURE',

    GET_DETAIL_REQUEST: 'BIDDING_PACKAGE_GET_DETAIL_REQUEST',
    GET_DETAIL_SUCCESS: 'BIDDING_PACKAGE_GET_DETAIL_SUCCESS',
    GET_DETAIL_FAILURE: 'BIDDING_PACKAGE_GET_DETAIL_FAILURE',

    ADD_BIDDING_PACKAGE_REQUEST: 'BIDDING_PACKAGE_ADD_BIDDING_PACKAGE_REQUEST',
    ADD_BIDDING_PACKAGE_SUCCESS: 'BIDDING_PACKAGE_ADD_BIDDING_PACKAGE_SUCCESS',
    ADD_BIDDING_PACKAGE_FAILURE: 'BIDDING_PACKAGE_ADD_BIDDING_PACKAGE_FAILURE',

    UPDATE_INFOR_BIDDING_PACKAGE_REQUEST: 'BIDDING_PACKAGE_UPDATE_INFOR_BIDDING_PACKAGE_REQUEST',
    UPDATE_INFOR_BIDDING_PACKAGE_SUCCESS: 'BIDDING_PACKAGE_UPDATE_INFOR_BIDDING_PACKAGE_SUCCESS',
    UPDATE_INFOR_BIDDING_PACKAGE_FAILURE: 'BIDDING_PACKAGE_UPDATE_INFOR_BIDDING_PACKAGE_FAILURE',

    DELETE_BIDDING_PACKAGE_REQUEST: 'DELETE_BIDDING_PACKAGE_REQUEST',
    DELETE_BIDDING_PACKAGE_SUCCESS: 'DELETE_BIDDING_PACKAGE_SUCCESS',
    DELETE_BIDDING_PACKAGE_FAILURE: 'DELETE_BIDDING_PACKAGE_FAILURE',

    IMPORT_BIDDING_PACKAGE_REQUEST: 'IMPORT_BIDDING_PACKAGE_REQUEST',
    IMPORT_BIDDING_PACKAGE_SUCCESS: 'IMPORT_BIDDING_PACKAGE_SUCCESS',
    IMPORT_BIDDING_PACKAGE_FAILURE: 'IMPORT_BIDDING_PACKAGE_FAILURE',

    GET_DOCUMENT_REQUEST: 'GET_DOCUMENT_REQUEST',
    GET_DOCUMENT_SUCCESS: 'GET_DOCUMENT_SUCCESS',
    GET_DOCUMENT_FAILURE: 'GET_DOCUMENT_FAILURE',

    PROPOSE_EMPLOYEE_FOR_TASK_REQUEST: 'PROPOSE_EMPLOYEE_FOR_TASK_REQUEST',
    PROPOSE_EMPLOYEE_FOR_TASK_SUCCESS: 'PROPOSE_EMPLOYEE_FOR_TASK_SUCCESS',
    PROPOSE_EMPLOYEE_FOR_TASK_FAILURE: 'PROPOSE_EMPLOYEE_FOR_TASK_FAILURE',
};