export const BinLocationConstants = {

    GET_ALL_BIN_LOCATION_REQUEST: 'GET_ALL_BIN_LOCATION_REQUEST',
    GET_ALL_BIN_LOCATION_SUCCESS: 'GET_ALL_BIN_LOCATION_SUCCESS',
    GET_ALL_BIN_LOCATION_FAILURE: 'GET_ALL_BIN_LOCATION_FAILURE',

    GET_BIN_LOCATION_STOCK_REQUEST: 'GET_BIN_LOCATION_STOCK_REQUEST',
    GET_BIN_LOCATION_STOCK_SUCCESS: 'GET_BIN_LOCATION_STOCK_SUCCESS',
    GET_BIN_LOCATION_STOCK_FAILURE: 'GET_BIN_LOCATION_STOCK_FAILURE',

    GET_BIN_LOCATION_CHILD_REQUEST: 'GET_BIN_LOCATION_CHILD_REQUEST',
    GET_BIN_LOCATION_CHILD_SUCCESS: 'GET_BIN_LOCATION_CHILD_SUCCESS',
    GET_BIN_LOCATION_CHILD_FAILURE: 'GET_BIN_LOCATION_CHILD_FAILURE',

    GET_PAGINATE_REQUEST: 'GET_PAGINATE_REQUEST',
    GET_PAGINATE_SUCCESS: 'GET_PAGINATE_SUCCESS',
    GET_PAGINATE_FAILURE: 'GET_PAGINATE_FAILURE',

    GET_DETAIL_BIN_REQUEST: 'GET_DETAIL_BIN_REQUEST',
    GET_DETAIL_BIN_SUCCESS: 'GET_DETAIL_BIN_SUCCESS',
    GET_DETAIL_BIN_FAILURE: 'GET_DETAIL_BIN_FAILURE',

    CREATE_BIN_LOCATION_REQUEST: 'CREATE_BIN_LOCATION_REQUEST',
    CREATE_BIN_LOCATION_SUCCESS: 'CREATE_BIN_LOCATION_SUCCESS',
    CREATE_BIN_LOCATION_FAILURE: 'CREATE_BIN_LOCATION_FAILURE',

    UPDATE_BIN_LOCATION_REQUEST: 'UPDATE_BIN_LOCATION_REQUEST',
    UPDATE_BIN_LOCATION_SUCCESS: 'UPDATE_BIN_LOCATION_SUCCESS',
    UPDATE_BIN_LOCATION_FAILURE: 'UPDATE_BIN_LOCATION_FAILURE',

    DELETE_BIN_LOCATION_REQUEST: 'DELETE_BIN_LOCATION_REQUEST',
    DELETE_BIN_LOCATION_SUCCESS: 'DELETE_BIN_LOCATION_SUCCESS',
    DELETE_BIN_LOCATION_FAILURE: 'DELETE_BIN_LOCATION_FAILURE',

    IMPORT_BIN_LOCATION_REQUEST: 'IMPORT_BIN_LOCATION_REQUEST',
    IMPORT_BIN_LOCATION_SUCCESS: 'IMPORT_BIN_LOCATION_SUCCESS',
    IMPORT_BIN_LOCATION_FAILURE: 'IMPORT_BIN_LOCATION_FAILURE',
}
