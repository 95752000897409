export const AllocationHistoryConstants = {
    SEARCH_ALLOCATION_REQUEST: 'SEARCH_ALLOCATION_REQUEST',
    SEARCH_ALLOCATION_SUCCESS: 'SEARCH_ALLOCATION_SUCCESS',
    SEARCH_ALLOCATION_FAILURE: 'SEARCH_ALLOCATION_FAILURE',

    CREATE_ALLOCATIONS_REQUEST: 'CREATE_ALLOCATIONS_REQUEST',
    CREATE_ALLOCATIONS_SUCCESS: 'CREATE_ALLOCATIONS_SUCCESS',
    CREATE_ALLOCATIONS_FAILURE: 'CREATE_ALLOCATIONS_FAILURE',

    UPDATE_ALLOCATION_REQUEST: 'UPDATE_ALLOCATION_REQUEST',
    UPDATE_ALLOCATION_SUCCESS: 'UPDATE_ALLOCATION_SUCCESS',
    UPDATE_ALLOCATION_FAILURE: 'UPDATE_ALLOCATION_FAILURE',

    DELETE_ALLOCATIONS_REQUEST: 'DELETE_ALLOCATIONS_REQUEST',
    DELETE_ALLOCATIONS_SUCCESS: 'DELETE_ALLOCATIONS_SUCCESS',
    DELETE_ALLOCATIONS_FAILURE: 'DELETE_ALLOCATIONS_FAILURE',

    GET_ALLOCATION_BY_ID_REQUEST: 'GET_ALLOCATION_BY_ID_REQUEST',
    GET_ALLOCATION_BY_ID_SUCCESS: 'GET_ALLOCATION_BY_ID_SUCCESS',
    GET_ALLOCATION_BY_ID_FAILURE: 'GET_ALLOCATION_BY_ID_FAILURE',
}