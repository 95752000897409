export const AssetLotConstants = {
    GETALL_REQUEST: 'ASSET_LOT_GETALL_REQUEST',
    GETALL_SUCCESS: 'ASSET_LOT_GETALL_SUCCESS',
    GETALL_FAILURE: 'ASSET_LOT_GETALL_FAILURE',

    CREATE_ASSETLOT_REQUEST: 'CREATE_ASSETLOT_REQUEST',
    CREATE_ASSETLOT_SUCCESS: 'CREATE_ASSETLOT_SUCCESS',
    CREATE_ASSETLOT_FAILURE: 'CREATE_ASSETLOT_FAILURE',

    UPDATE_ASSETLOT_REQUEST: 'UPDATE_ASSETLOT_REQUEST',
    UPDATE_ASSETLOT_SUCCESS: 'UPDATE_ASSETLOT_SUCCESS',
    UPDATE_ASSETLOT_FAILURE: 'UPDATE_ASSETLOT_FAILURE',

    DELETE_ASSETLOT_REQUEST: 'DELETE_ASSETLOT_REQUEST',
    DELETE_ASSETLOT_SUCCESS: 'DELETE_ASSETLOT_SUCCESS',
    DELETE_ASSETLOT_FAILURE: 'DELETE_ASSETLOT_FAILURE',

    GET_ASSET_LOT_INFOR_REQUEST: 'GET_ASSET_LOT_INFOR_REQUEST',
    GET_ASSET_LOT_INFOR_SUCCESS: 'GET_ASSET_LOT_INFOR_SUCCESS',
    GET_ASSET_LOT_INFOR_FAILURE: 'GET_ASSET_LOT_INFOR_FAILURE',

    UPDATE_LIST_ASSETS_ACTION : "UPDATE_LIST_ASSETS_ACTION",
    UPDATE_ASSET_LOT_ACTION: "UPDATE_ASSET_LOT_ACTION",

    PUT_ASSETLOT_CURRENT_ROW: "PUT_ASSETLOT_CURRENT_ROW"

};