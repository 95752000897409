export const UserConstants = {
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILE: 'GET_USERS_FAILE',

    GET_ALL_EMPLOYEE_OF_UNIT_BY_ROLE_REQUEST: 'GET_ALL_EMPLOYEE_OF_UNIT_BY_ROLE_REQUEST',
    GET_ALL_EMPLOYEE_OF_UNIT_BY_ROLE_SUCCESS: 'GET_ALL_EMPLOYEE_OF_UNIT_BY_ROLE_SUCCESS',
    GET_ALL_EMPLOYEE_OF_UNIT_BY_ROLE_FAILURE: 'GET_ALL_EMPLOYEE_OF_UNIT_BY_ROLE_FAILURE',

    GET_ALL_EMPLOYEE_OF_UNIT_BY_ID_REQUEST: 'GET_ALL_EMPLOYEE_OF_UNIT_BY_ID_REQUEST',
    GET_ALL_EMPLOYEE_OF_UNIT_BY_ID_SUCCESS: 'GET_ALL_EMPLOYEE_OF_UNIT_BY_ID_SUCCESS',
    GET_ALL_EMPLOYEE_OF_UNIT_BY_ID_FAILURE: 'GET_ALL_EMPLOYEE_OF_UNIT_BY_ID_FAILURE',
    
    GET_USERS_PAGINATE_REQUEST: 'GET_USERS_PAGINATE_REQUEST',
    GET_USERS_PAGINATE_SUCCESS: 'GET_USERS_PAGINATE_SUCCESS',
    GET_USERS_PAGINATE_FAILE: 'GET_USERS_PAGINATE_FAILE',

    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILE: 'EDIT_USER_FAILE',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILE: 'CREATE_USER_FAILE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILE: 'DELETE_USER_FAILE',

    SEARCH_USER_BY_NAME_REQUEST: 'SEARCH_USER_BY_NAME_REQUEST',
    SEARCH_USER_BY_NAME_SUCCESS: 'SEARCH_USER_BY_NAME_SUCCESS',
    SEARCH_USER_BY_NAME_FAILE: 'SEARCH_USER_BY_NAME_FAILE',
    
    GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
    GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
    GET_USER_ROLES_FAILE: 'GET_USER_ROLES_FAILE',

    GET_LINK_OF_ROLE_REQUEST: 'GET_LINK_OF_ROLE_REQUEST',
    GET_LINK_OF_ROLE_SUCCESS: 'GET_LINK_OF_ROLE_SUCCESS',
    GET_LINK_OF_ROLE_FAILE: 'GET_LINK_OF_ROLE_FAILE',

    SET_ROLE_REQUEST: 'SET_ROLE_REQUEST',
    SET_ROLE_FAILE: 'SET_ROLE_FAILE',
    SET_ROLE_SUCCESS: 'SET_ROLE_SUCCESS',

    GETROLE_SAMEDEPARTMENT_REQUEST: 'GETROLE_SAMEDEPARTMENT_REQUEST',
    GETROLE_SAMEDEPARTMENT_SUCCESS: 'GETROLE_SAMEDEPARTMENT_SUCCESS',
    GETROLE_SAMEDEPARTMENT_FAILURE: 'GETROLE_SAMEDEPARTMENT_FAILURE',

    GETALLUSER_OFDEPARTMENT_REQUEST: 'GETALLUSER_OFDEPARTMENT_REQUEST',
    GETALLUSER_OFDEPARTMENT_SUCCESS: 'GETALLUSER_OFDEPARTMENT_SUCCESS',
    GETALLUSER_OFDEPARTMENT_FAILURE: 'GETALLUSER_OFDEPARTMENT_FAILURE',

    GETALLUSER_SAMEDEPARTMENT_REQUEST: 'GETALLUSER_SAMEDEPARTMENT_REQUEST',
    GETALLUSER_SAMEDEPARTMENT_SUCCESS: 'GETALLUSER_SAMEDEPARTMENT_SUCCESS',
    GETALLUSER_SAMEDEPARTMENT_FAILURE: 'GETALLUSER_SAMEDEPARTMENT_FAILURE',

    GETALLUSER_OFCOMPANY_REQUEST: 'GETALLUSER_OFCOMPANY_REQUEST',
    GETALLUSER_OFCOMPANY_SUCCESS: 'GETALLUSER_OFCOMPANY_SUCCESS',
    GETALLUSER_OFCOMPANY_FAILURE: 'GETALLUSER_OFCOMPANY_FAILURE',


    GETDEPARTMENT_OFUSER_REQUEST: 'GETDEPARTMENT_OFUSER_REQUEST',
    GETDEPARTMENT_OFUSER_SUCCESS: 'GETDEPARTMENT_OFUSER_SUCCESS',
    GETDEPARTMENT_OFUSER_FAILURE: 'GETDEPARTMENT_OFUSER_FAILURE',

    GET_ALL_USERS_OF_UNIT_AND_ITS_SUB_UNITS_REQUEST: 'GET_ALL_USERS_OF_UNIT_AND_ITS_SUB_UNITS_REQUEST',
    GET_ALL_USERS_OF_UNIT_AND_ITS_SUB_UNITS_SUCCESS: 'GET_ALL_USERS_OF_UNIT_AND_ITS_SUB_UNITS_SUCCESS',
    GET_ALL_USERS_OF_UNIT_AND_ITS_SUB_UNITS_FAILURE: 'GET_ALL_USERS_OF_UNIT_AND_ITS_SUB_UNITS_FAILURE',

    GET_ALL_USERS_IN_UNITS_OF_COMPANY_REQUEST:'GET_ALL_USERS_IN_UNITS_OF_COMPANY_REQUEST',
    GET_ALL_USERS_IN_UNITS_OF_COMPANY_SUCCESS:'GET_ALL_USERS_IN_UNITS_OF_COMPANY_SUCCESS',
    GET_ALL_USERS_IN_UNITS_OF_COMPANY_FAILURE:'GET_ALL_USERS_IN_UNITS_OF_COMPANY_FAILURE',

    GET_ALL_USERS_WITH_ROLE_REQUEST: 'GET_ALL_USERS_WITH_ROLE_REQUEST',
    GET_ALL_USERS_WITH_ROLE_SUCCESS: 'GET_ALL_USERS_WITH_ROLE_SUCCESS',
    GET_ALL_USERS_WITH_ROLE_FAIL:'GET_ALL_USERS_WITH_ROLE_FAIL',

    IMPORT_USERS_REQUEST: 'IMPORT_USERS_REQUEST',
    IMPORT_USERS_SUCCESS: 'IMPORT_USERS_SUCCESS',
    IMPORT_USERS_FAILE:'IMPORT_USERS_FAILE',

    SEND_EMAIL_RESET_PASSWORD_USER_REQUEST: 'SEND_EMAIL_RESET_PASSWORD_USER_REQUEST',
    SEND_EMAIL_RESET_PASSWORD_USER_SUCCESS: 'SEND_EMAIL_RESET_PASSWORD_USER_SUCCESS',
    SEND_EMAIL_RESET_PASSWORD_USER_FAILE:'SEND_EMAIL_RESET_PASSWORD_USER_FAILE',

    CREATE_USER_ATTRIBUTE_REQUEST: 'CREATE_USER_ATTRIBUTE_REQUEST',
    CREATE_USER_ATTRIBUTE_SUCCESS: 'CREATE_USER_ATTRIBUTE_SUCCESS',
    CREATE_USER_ATTRIBUTE_FAILE: 'CREATE_USER_ATTRIBUTE_FAILE'

}