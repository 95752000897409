export const planConstants = {
    GET_ALL_PLANS_REQUEST: "GET_ALL_PLANS_REQUEST",
    GET_ALL_PLANS_SUCCESS: "GET_ALL_PLANS_SUCCESS",
    GET_ALL_PLANS_FAILURE: "GET_ALL_PLANS_FAILURE",

    DELETE_PLAN_REQUEST: "DELETE_PLAN_REQUEST",
    DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
    DELETE_PLAN_FAILURE: "DELETE_PLAN_FAILURE",

    CREATE_PLAN_REQUEST: "CREATE_PLAN_REQUEST",
    CREATE_PLAN_SUCCESS: "CREATE_PLAN_SUCCESS",
    CREATE_PLAN_FAILURE: "CREATE_PLAN_FAILURE",

    EDIT_PLAN_REQUEST: "EDIT_PLAN_REQUEST",
    EDIT_PLAN_SUCCESS: "EDIT_PLAN_SUCCESS",
    EDIT_PLAN_FAILURE: "EDIT_PLAN_FAILURE",

    GET_DETAIL_PLAN_REQUEST: "GET_DETAIL_PLAN_REQUEST",
    GET_DETAIL_PLAN_SUCCESS: "GET_DETAIL_PLAN_SUCCESS",
    GET_DETAIL_PLAN_FAILURE: "GET_DETAIL_PLAN_FAILURE"


}