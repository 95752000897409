export const CrmLoyalCustomerConstants = {
    GET_CRM_LOYALCUSTOMERS_REQUEST: 'GET_CRM_LOYALCUSTOMER_REQUEST',
    GET_CRM_LOYALCUSTOMERS_SUCCESS: 'GET_CRM_LOYALCUSTOMER_SUCCESS',
    GET_CRM_LOYALCUSTOMERS_FAILE: 'GET_CRM_LOYALCUSTOMER_FAILE',

    // GET_CRM_CARE_REQUEST: 'GET_CRM_CARE_REQUEST',
    // GET_CRM_CARE_SUCCESS: 'GET_CRM_CARE_SUCCESS',
    // GET_CRM_CARE_FAILE: 'GET_CRM_CARE_FAILE',

    // CREATE_CRM_CARE_REQUEST: 'CREATE_CRM_CARE_REQUEST',
    // CREATE_CRM_CARE_SUCCESS: 'CREATE_CRM_CARE_SUCCESS',
    // CREATE_CRM_CARE_FAILE: 'CREATE_CRM_CARE_FAILE',

    // EDIT_CRM_CARE_REQUEST: 'EDIT_CRM_CARE_REQUEST',
    // EDIT_CRM_CARE_SUCCESS: 'EDIT_CRM_CARE_SUCCESS',
    // EDIT_CRM_CARE_FAILE: 'EDIT_CRM_CARE_FAILE',

    // DELETE_CRM_CARE_REQUEST: 'DELETE_CRM_CARE_REQUEST',
    // DELETE_CRM_CARE_SUCCESS: 'DELETE_CRM_CARE_SUCCESS',
    // DELETE_CRM_CARE_FAILE: 'DELETE_CRM_CARE_FAILE',
}