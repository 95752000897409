export const RecommendDistributeConstants = {
    GET_RECOMMEND_DISTRIBUTE_REQUEST: 'GET_RECOMMEND_DISTRIBUTE_REQUEST',
    GET_RECOMMEND_DISTRIBUTE_SUCCESS: 'GET_RECOMMEND_DISTRIBUTE_SUCCESS',
    GET_RECOMMEND_DISTRIBUTE_FAILURE: 'GET_RECOMMEND_DISTRIBUTE_FAILURE',

    CREATE_RECOMMEND_DISTRIBUTE_REQUEST: 'CREATE_NEW_RECOMMEND_DISTRIBUTE_REQUEST',
    CREATE_RECOMMEND_DISTRIBUTE_SUCCESS: 'CREATE_NEW_RECOMMEND_DISTRIBUTE_SUCCESS',
    CREATE_RECOMMEND_DISTRIBUTE_FAILURE: 'CREATE_NEW_RECOMMEND_DISTRIBUTE_FAILURE',

    UPDATE_RECOMMEND_DISTRIBUTE_REQUEST: 'UPDATE_RECOMMEND_DISTRIBUTE_REQUEST',
    UPDATE_RECOMMEND_DISTRIBUTE_SUCCESS: 'UPDATE_RECOMMEND_DISTRIBUTE_SUCCESS',
    UPDATE_RECOMMEND_DISTRIBUTE_FAILURE: 'UPDATE_RECOMMEND_DISTRIBUTE_FAILURE',

    DELETE_RECOMMEND_DISTRIBUTE_REQUEST: 'DELETE_RECOMMEND_DISTRIBUTE_REQUEST',
    DELETE_RECOMMEND_DISTRIBUTE_SUCCESS:  'DELETE_RECOMMEND_DISTRIBUTE_SUCCESS',
    DELETE_RECOMMEND_DISTRIBUTE_FAILURE:  'DELETE_RECOMMEND_DISTRIBUTE_FAILURE',

    GET_RECOMMEND_DISTRIBUTE_BY_ASSET_REQUEST: 'GET_RECOMMEND_DISTRIBUTE_BY_ASSET_REQUEST',
    GET_RECOMMEND_DISTRIBUTE_BY_ASSET_SUCCESS: 'GET_RECOMMEND_DISTRIBUTE_BY_ASSET_SUCCESS',
    GET_RECOMMEND_DISTRIBUTE_BY_ASSET_FAILURE: 'GET_RECOMMEND_DISTRIBUTE_BY_ASSET_FAILURE',
}