export const TaskProcessConstants = {

	GET_ALL_XML_DIAGRAM_REQUEST: "GET_ALL_XML_DIAGRAM_REQUEST",
	GET_ALL_XML_DIAGRAM_SUCCESS: "GET_ALL_XML_DIAGRAM_SUCCESS",
	GET_ALL_XML_DIAGRAM_FAIL: "GET_ALL_XML_DIAGRAM_FAIL",

	GET_XML_DIAGRAM_BY_ID_REQUEST: "GET_XML_DIAGRAM_BY_ID_REQUEST",
	GET_XML_DIAGRAM_BY_ID_SUCCESS: "GET_XML_DIAGRAM_BY_ID_SUCCESS",
	GET_XML_DIAGRAM_BY_ID_FAIL: "GET_XML_DIAGRAM_BY_ID_FAIL",

	CREATE_XML_DIAGRAM_REQUEST: "CREATE_XML_DIAGRAM_REQUEST",
	CREATE_XML_DIAGRAM_SUCCESS: "CREATE_XML_DIAGRAM_SUCCESS",
	CREATE_XML_DIAGRAM_FAIL: "CREATE_XML_DIAGRAM_FAIL",

	IMPORT_PROCESS_TEMPLATE_REQUEST: "IMPORT_PROCESS_TEMPLATE_REQUEST",
	IMPORT_PROCESS_TEMPLATE_SUCCESS: "IMPORT_PROCESS_TEMPLATE_SUCCESS",
	IMPORT_PROCESS_TEMPLATE_FAIL: "IMPORT_PROCESS_TEMPLATE_FAIL",

	EDIT_XML_DIAGRAM_REQUEST: "EDIT_XML_DIAGRAM_REQUEST",
	EDIT_XML_DIAGRAM_SUCCESS: "EDIT_XML_DIAGRAM_SUCCESS",
	EDIT_XML_DIAGRAM_FAIL: "EDIT_XML_DIAGRAM_FAIL",

	DELETE_XML_DIAGRAM_REQUEST: "DELETE_XML_DIAGRAM_REQUEST",
	DELETE_XML_DIAGRAM_SUCCESS: "DELETE_XML_DIAGRAM_SUCCESS",
	DELETE_XML_DIAGRAM_FAIL: "DELETE_XML_DIAGRAM_FAIL",

	GET_XML_PROCESS_BY_ID_REQUEST: "GET_XML_PROCESS_BY_ID_REQUEST",
	GET_XML_PROCESS_BY_ID_SUCCESS: "GET_XML_PROCESS_BY_ID_SUCCESS",
	GET_XML_PROCESS_BY_ID_FAIL: "GET_XML_PROCESS_BY_ID_FAIL",

	CREATE_TASK_BY_PROCESS_REQUEST: "CREATE_TASK_BY_PROCESS_REQUEST",
	CREATE_TASK_BY_PROCESS_SUCCESS: "CREATE_TASK_BY_PROCESS_SUCCESS",
	CREATE_TASK_BY_PROCESS_FAIL: "CREATE_TASK_BY_PROCESS_FAIL",

	CREATE_TASK_BY_PROCESS_TEMPLATE_REQUEST: "CREATE_TASK_BY_PROCESS_TEMPLATE_REQUEST",
	CREATE_TASK_BY_PROCESS_TEMPLATE_SUCCESS: "CREATE_TASK_BY_PROCESS_TEMPLATE_SUCCESS",
	CREATE_TASK_BY_PROCESS_TEMPLATE_FAIL: "CREATE_TASK_BY_PROCESS_TEMPLATE_FAIL",

	GET_ALL_TASK_PROCESS_REQUEST: "GET_ALL_TASK_PROCESS_REQUEST",
	GET_ALL_TASK_PROCESS_SUCCESS: "GET_ALL_TASK_PROCESS_SUCCESS",
	GET_ALL_TASK_PROCESS_FAIL: "GET_ALL_TASK_PROCESS_FAIL",
	DELETE_TASK_PROCESS_REQUEST: "DELETE_TASK_PROCESS_REQUEST",
	DELETE_TASK_PROCESS_SUCCESS: "DELETE_TASK_PROCESS_SUCCESS",
	DELETE_TASK_PROCESS_FAIL: "DELETE_TASK_PROCESS_FAIL",
	UPDATE_DIAGRAM_REQUEST: 'UPDATE_DIAGRAM_REQUEST',
	UPDATE_DIAGRAM_SUCCESS: 'UPDATE_DIAGRAM_SUCCESS',
	UPDATE_DIAGRAM_FAIL: 'UPDATE_DIAGRAM_FAIL',

	EDIT_PROCESS_INFO_REQUEST: 'EDIT_PROCESS_INFO_REQUEST',
	EDIT_PROCESS_INFO_SUCCESS: 'EDIT_PROCESS_INFO_SUCCESS',
	EDIT_PROCESS_INFO_FAIL: 'EDIT_PROCESS_INFO_FAIL',

	CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
	CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
	CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',

	CREATE_COMMENT_OF_COMMENT_REQUEST: 'CREATE_COMMENT_OF_COMMENT_REQUEST',
	CREATE_COMMENT_OF_COMMENT_SUCCESS: 'CREATE_COMMENT_OF_COMMENT_SUCCESS',
	CREATE_COMMENT_OF_COMMENT_FAILURE: 'CREATE_COMMENT_OF_COMMENT_FAILURE',

	EDIT_COMMENT_REQUEST: 'EDIT_COMMENT_REQUEST',
	EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
	EDIT_COMMENT_FAILURE: 'EDIT_COMMENT_FAILURE',

	DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
	DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
	DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

	EDIT_COMMENT_OF_COMMENT_REQUEST: 'EDIT_COMMENT_OF_COMMENT_REQUEST',
	EDIT_COMMENT_OF_COMMENT_SUCCESS: 'EDIT_COMMENT_OF_COMMENT_SUCCESS',
	EDIT_COMMENT_OF_COMMENT_FAILURE: 'EDIT_COMMENT_OF_COMMENT_FAILURE',

	DELETE_COMMENT_OF_COMMENT_REQUEST: 'DELETE_COMMENT_OF_COMMENT_REQUEST',
	DELETE_COMMENT_OF_COMMENT_SUCCESS: 'DELETE_COMMENT_OF_COMMENT_SUCCESS',
	DELETE_COMMENT_OF_COMMENT_FAILURE: 'DELETE_COMMENT_OF_COMMENT_FAILURE',

	DELETE_FILE_COMMENT_REQUEST: 'DELETE_FILE_COMMENT_REQUEST',
	DELETE_FILE_COMMENT_SUCCESS: 'DELETE_FILE_COMMENT_SUCCESS',
	DELETE_FILE_COMMENT_FAILURE: 'DELETE_FILE_COMMENT_FAILURE',

	DELETE_FILE_CHILD_COMMENT_REQUEST: 'DELETE_FILE_CHILD_COMMENT_REQUEST',
	DELETE_FILE_CHILD_COMMENT_SUCCESS: 'DELETE_FILE_CHILD_COMMENT_SUCCESS',
	DELETE_FILE_CHILD_COMMENT_FAILURE: 'DELETE_FILE_CHILD_COMMENT_FAILURE',

}