export const requestConstants = {
    GET_ALL_REQUEST_REQUEST: 'GET_ALL_REQUEST_REQUEST',
    GET_ALL_REQUEST_SUCCESS: 'GET_ALL_REQUEST_SUCCESS',
    GET_ALL_REQUEST_FAILURE: 'GET_ALL_REQUEST_FAILURE',

    CREATE_REQUEST_REQUEST: 'CREATE_REQUEST_REQUEST',
    CREATE_REQUEST_SUCCESS: 'CREATE_REQUEST_SUCCESS',
    CREATE_REQUEST_FAILURE: 'CREATE_REQUEST_FAILURE',

    GET_DETAIL_REQUEST_REQUEST: 'GET_DETAIL_REQUEST_REQUEST',
    GET_DETAIL_REQUEST_SUCCESS: 'GET_DETAIL_REQUEST_SUCCESS',
    GET_DETAIL_REQUEST_FAILURE: 'GET_DETAIL_REQUEST_FAILURE',

    UPDATE_REQUEST_REQUEST: 'UPDATE_REQUEST_REQUEST',
    UPDATE_REQUEST_SUCCESS: 'UPDATE_REQUEST_SUCCESS',
    UPDATE_REQUEST_FAILURE: 'UPDATE_REQUEST_FAILURE',

    GET_NUMBER_REQUEST_REQUEST: 'GET_NUMBER_REQUEST_REQUEST',
    GET_NUMBER_REQUEST_SUCCESS: 'GET_NUMBER_REQUEST_SUCCESS',
    GET_NUMBER_REQUEST_FAILURE: 'GET_NUMBER_REQUEST_FAILURE',
}
