export const AssetConstants = {
    GETALL_REQUEST: 'ASSET_GETALL_REQUEST',
    GETALL_SUCCESS: 'ASSET_GETALL_SUCCESS',
    GETALL_FAILURE: 'ASSET_GETALL_FAILURE',

    GET_LIST_BUILDING_AS_TREE_REQUEST: 'ASSET_GET_LIST_BUILDING_AS_TREE_REQUEST',
    GET_LIST_BUILDING_AS_TREE_SUCCESS: 'ASSET_GET_LIST_BUILDING_AS_TREE_SUCCESS',
    GET_LIST_BUILDING_AS_TREE_FAILURE: 'ASSET_GET_LIST_BUILDING_AS_TREE_FAILURE',

    ADDASSET_REQUEST: 'ASSET_ADDASSET_REQUEST',
    ADDASSET_SUCCESS: 'ASSET_ADDASSET_SUCCESS',
    ADDASSET_FAILURE: 'ASSET_ADDASSET_FAILURE',

    UPDATE_INFOR_ASSET_REQUEST: 'ASSET_UPDATE_INFOR_ASSET_REQUEST',
    UPDATE_INFOR_ASSET_SUCCESS: 'ASSET_UPDATE_INFOR_ASSET_SUCCESS',
    UPDATE_INFOR_ASSET_FAILURE: 'ASSET_UPDATE_INFOR_ASSET_FAILURE',

    DELETE_ASSET_REQUEST: 'DELETE_ASSET_REQUEST',
    DELETE_ASSET_SUCCESS: 'DELETE_ASSET_SUCCESS',
    DELETE_ASSET_FAILURE: 'DELETE_ASSET_FAILURE',

    CREATE_USAGE_REQUEST: 'CREATE_NEW_USAGE_REQUEST',
    CREATE_USAGE_SUCCESS: 'CREATE_NEW_USAGE_SUCCESS',
    CREATE_USAGE_FAILURE: 'CREATE_NEW_USAGE_FAILURE',


    GET_ASSET_GROUP_SUCCESS: 'ASSET_GET_ASSET_GROUP_SUCCESS',
    GET_ASSET_GROUP_REQUEST: 'ASSET_GET_ASSET_GROUP_REQUEST',
    GET_ASSET_GROUP_FAILURE: 'ASSET_GET_ASSET_GROUP_FAILURE',

    GET_ASSET_STATISTIC_SUCCESS : 'ASSET_GET_ASSET_STATISTIC_SUCCESS',
    GET_ASSET_STATISTIC_REQUEST : 'ASSET_GET_ASSET_STATISTIC_REQUEST',
    GET_ASSET_STATISTIC_FAILURE : 'ASSET_GET_ASSET_STATISTIC_FAILURE',

    GET_ASSET_PURCHASE_SUCCESS : 'ASSET_GET_ASSET_PURCHASE_SUCCESS',
    GET_ASSET_PURCHASE_REQUEST : 'ASSET_GET_ASSET_PURCHASE_REQUEST',
    GET_ASSET_PURCHASE_FAILURE : 'ASSET_GET_ASSET_PURCHASE_FAILURE',

    GET_ASSET_DISPOSAL_SUCCESS : 'ASSET_GET_ASSET_DISPOSAL_SUCCESS',
    GET_ASSET_DISPOSAL_REQUEST : 'ASSET_GET_ASSET_DISPOSAL_REQUEST',
    GET_ASSET_DISPOSAL_FAILURE : 'ASSET_GET_ASSET_DISPOSAL_FAILURE',

    GET_ASSET_INCIDENT_SUCCESS : 'ASSET_GET_ASSET_INCIDENT_SUCCESS',
    GET_ASSET_INCIDENT_REQUEST : 'ASSET_GET_ASSET_INCIDENT_REQUEST',
    GET_ASSET_INCIDENT_FAILURE : 'ASSET_GET_ASSET_INCIDENT_FAILURE',

    GET_ASSET_MAINTENANCE_SUCCESS : 'ASSET_GET_ASSET_MAINTENANCE_SUCCESS',
    GET_ASSET_MAINTENANCE_REQUEST : 'ASSET_GET_ASSET_MAINTENANCE_REQUEST',
    GET_ASSET_MAINTENANCE_FAILURE : 'ASSET_GET_ASSET_MAINTENANCE_FAILURE',
};