export const SystemLinkConstants = {
    GET_LINKS_DEFAULT_REQUEST: 'GET_LINKS_DEFAULT_REQUEST',
    GET_LINKS_DEFAULT_SUCCESS: 'GET_LINKS_DEFAULT_SUCCESS',
    GET_LINKS_DEFAULT_FAILURE: 'GET_LINKS_DEFAULT_FAILURE',

    GET_LINKS_DEFAULT_CATEGORIES_REQUEST: 'GET_LINKS_DEFAULT_CATEGORIES_REQUEST',
    GET_LINKS_DEFAULT_CATEGORIES_SUCCESS: 'GET_LINKS_DEFAULT_CATEGORIES_SUCCESS',
    GET_LINKS_DEFAULT_CATEGORIES_FAILURE: 'GET_LINKS_DEFAULT_CATEGORIES_FAILURE',

    GET_LINKS_DEFAULT_PAGINATE_REQUEST: 'GET_LINKS_DEFAULT_PAGINATE_REQUEST',
    GET_LINKS_DEFAULT_PAGINATE_SUCCESS: 'GET_LINKS_DEFAULT_PAGINATE_SUCCESS',
    GET_LINKS_DEFAULT_PAGINATE_FAILURE: 'GET_LINKS_DEFAULT_PAGINATE_FAILURE',

    SHOW_LINK_DEFAULT_REQUEST: 'SHOW_LINK_DEFAULT_REQUEST',
    SHOW_LINK_DEFAULT_SUCCESS: 'SHOW_LINK_DEFAULT_SUCCESS',
    SHOW_LINK_DEFAULT_FAILURE: 'SHOW_LINK_DEFAULT_FAILURE',

    CREATE_LINK_DEFAULT_REQUEST: 'CREATE_LINK_DEFAULT_REQUEST',
    CREATE_LINK_DEFAULT_SUCCESS: 'CREATE_LINK_DEFAULT_SUCCESS',
    CREATE_LINK_DEFAULT_FAILURE: 'CREATE_LINK_DEFAULT_FAILURE',

    EDIT_LINK_DEFAULT_REQUEST: 'EDIT_LINK_DEFAULT_REQUEST',
    EDIT_LINK_DEFAULT_SUCCESS: 'EDIT_LINK_DEFAULT_SUCCESS',
    EDIT_LINK_DEFAULT_FAILURE: 'EDIT_LINK_DEFAULT_FAILURE',

    DELETE_LINK_DEFAULT_REQUEST: 'DELETE_LINK_DEFAULT_REQUEST',
    DELETE_LINK_DEFAULT_SUCCESS: 'DELETE_LINK_DEFAULT_SUCCESS',
    DELETE_LINK_DEFAULT_FAILURE: 'DELETE_LINK_DEFAULT_FAILURE',
}