export const kpiMemberConstants = {
    GETALL_KPIMEMBER_OfUNIT_REQUEST: 'GETALL_KPIMEMBER_OfUNIT_REQUEST',
    GETALL_KPIMEMBER_OfUNIT_SUCCESS: 'GETALL_KPIMEMBER_OfUNIT_SUCCESS',
    GETALL_KPIMEMBER_OfUNIT_FAILURE: 'GETALL_KPIMEMBER_OfUNIT_FAILURE',

    GETALL_KPIMEMBER_REQUEST: 'GETALL_KPIMEMBER_REQUEST',
    GETALL_KPIMEMBER_SUCCESS: 'GETALL_KPIMEMBER_SUCCESS',
    GETALL_KPIMEMBER_FAILURE: 'GETALL_KPIMEMBER_FAILURE',

    GET_KPIMEMBER_BYMONTH_REQUEST: 'GET_KPIMEMBER_BYMONTH_REQUEST',
    GET_KPIMEMBER_BYMONTH_SUCCESS: 'GET_KPIMEMBER_BYMONTH_SUCCESS',
    GET_KPIMEMBER_BYMONTH_FAILURE: 'GET_KPIMEMBER_BYMONTH_FAILURE',

    GET_KPIMEMBER_BYID_REQUEST: 'GET_KPIMEMBER_BYID_REQUEST',
    GET_KPIMEMBER_BYID_SUCCESS: 'GET_KPIMEMBER_BYID_SUCCESS',
    GET_KPIMEMBER_BYID_FAILURE: 'GET_KPIMEMBER_BYID_FAILURE',

    APPROVE_KPIMEMBER_REQUEST: 'APPROVE_KPIMEMBER_REQUEST',
    APPROVE_KPIMEMBER_SUCCESS: 'APPROVE_KPIMEMBER_SUCCESS',
    APPROVE_KPIMEMBER_FAILURE: 'APPROVE_KPIMEMBER_FAILURE',

    EDITTARGET_KPIMEMBER_REQUEST: 'EDITTARGET_KPIMEMBER_REQUEST',
    EDITTARGET_KPIMEMBER_SUCCESS: 'EDITTARGET_KPIMEMBER_SUCCESS',
    EDITTARGET_KPIMEMBER_FAILURE: 'EDITTARGET_KPIMEMBER_FAILURE',

    EDITSTATUS_TARGET_KPIMEMBER_REQUEST: 'EDITSTATUS_TARGET_KPIMEMBER_REQUEST',
    EDITSTATUS_TARGET_KPIMEMBER_SUCCESS: 'EDITSTATUS_TARGET_KPIMEMBER_SUCCESS',
    EDITSTATUS_TARGET_KPIMEMBER_FAILURE: 'EDITSTATUS_TARGET_KPIMEMBER_FAILURE',

    GET_TASK_BYID_REQUEST: 'GET_TASK_BYID_REQUEST',
    GET_TASK_BYID_SUCCESS: 'GET_TASK_BYID_SUCCESS',
    GET_TASK_BYID_FAILURE: 'GET_TASK_BYID_FAILURE',

    SET_POINTKPI_REQUEST: 'SET_POINTKPI_REQUEST',
    SET_POINTKPI_SUCCESS: 'SET_POINTKPI_SUCCESS',
    SET_POINTKPI_FAILURE: 'SET_POINTKPI_FAILURE',

    TASK_IMPORTANT_LEVEL_REQUEST: 'TASK_IMPORTANT_LEVEL_REQUEST',
    TASK_IMPORTANT_LEVEL_SUCCESS: 'TASK_IMPORTANT_LEVEL_SUCCESS',
    TASK_IMPORTANT_LEVEL_FAILURE: 'TASK_IMPORTANT_LEVEL_FAILURE',

    GET_TASK_BY_LIST_KPI_REQUEST: 'GET_TASK_BY_LIST_KPI_REQUEST',
    GET_TASK_BY_LIST_KPI_SUCCESS: 'GET_TASK_BY_LIST_KPI_SUCCESS',
    GET_TASK_BY_LIST_KPI_FAILURE: 'GET_TASK_BY_LIST_KPI_FAILURE',

    CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
    CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
    CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',

    CREATE_COMMENT_OF_COMMENT_REQUEST:'CREATE_COMMENT_OF_COMMENT_REQUEST',
    CREATE_COMMENT_OF_COMMENT_SUCCESS: 'CREATE_COMMENT_OF_COMMENT_SUCCESS',
    CREATE_COMMENT_OF_COMMENT_FAILURE: 'CREATE_COMMENT_OF_COMMENT_FAILURE',

    EDIT_COMMENT_REQUEST:'EDIT_COMMENT_REQUEST',
    EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
    EDIT_COMMENT_FAILURE: 'EDIT_COMMENT_FAILURE',

    DELETE_COMMENT_REQUEST:'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

    EDIT_COMMENT_OF_COMMENT_REQUEST:'EDIT_COMMENT_OF_COMMENT_REQUEST',
    EDIT_COMMENT_OF_COMMENT_SUCCESS: 'EDIT_COMMENT_OF_COMMENT_SUCCESS',
    EDIT_COMMENT_OF_COMMENT_FAILURE: 'EDIT_COMMENT_OF_COMMENT_FAILURE',

    DELETE_COMMENT_OF_COMMENT_REQUEST:'DELETE_COMMENT_OF_COMMENT_REQUEST',
    DELETE_COMMENT_OF_COMMENT_SUCCESS: 'DELETE_COMMENT_OF_COMMENT_SUCCESS',
    DELETE_COMMENT_OF_COMMENT_FAILURE: 'DELETE_COMMENT_OF_COMMENT_FAILURE',

    DELETE_FILE_COMMENT_REQUEST: 'DELETE_FILE_COMMENT_REQUEST',
    DELETE_FILE_COMMENT_SUCCESS: 'DELETE_FILE_COMMENT_SUCCESS',
    DELETE_FILE_COMMENT_FAILURE: 'DELETE_FILE_COMMENT_FAILURE',

    DELETE_FILE_CHILD_COMMENT_REQUEST: 'DELETE_FILE_CHILD_COMMENT_REQUEST',
    DELETE_FILE_CHILD_COMMENT_SUCCESS: 'DELETE_FILE_CHILD_COMMENT_SUCCESS',
    DELETE_FILE_CHILD_COMMENT_FAILURE: 'DELETE_FILE_CHILD_COMMENT_FAILURE',
    
    SET_POINT_ALL_KPI_REQUEST: 'SET_POINT_ALL_KPI_REQUEST',
    SET_POINT_ALL_KPI_SUCCESS: 'SET_POINT_ALL_KPI_SUCCESS',
    SET_POINT_ALL_KPI_FAILURE: 'SET_POINT_ALL_KPI_FAILURE',

}