export const attributeConstants = {
    GET_ALL_ATTRIBUTES_REQUEST: "GET_ALL_ATTRIBUTES_REQUEST",
    GET_ALL_ATTRIBUTES_SUCCESS: "GET_ALL_ATTRIBUTES_SUCCESS",
    GET_ALL_ATTRIBUTES_FAILURE: "GET_ALL_ATTRIBUTES_FAILURE",

    DELETE_ATTRIBUTE_REQUEST: "DELETE_DELETE_ATTRIBUTE_REQUEST",
    DELETE_ATTRIBUTE_SUCCESS: "DELETE_ATTRIBUTE_SUCCESS",
    DELETE_ATTRIBUTE_FAILURE: "DELETE_ATTRIBUTE_FAILURE",

    CREATE_ATTRIBUTE_REQUEST: "CREATE_ATTRIBUTE_REQUEST",
    CREATE_ATTRIBUTE_SUCCESS: "CREATE_ATTRIBUTE_SUCCESS",
    CREATE_ATTRIBUTE_FAILURE: "CREATE_ATTRIBUTE_FAILURE",

    EDIT_ATTRIBUTE_REQUEST: "EDIT_ATTRIBUTE_REQUEST",
    EDIT_ATTRIBUTE_SUCCESS: "EDIT_ATTRIBUTE_SUCCESS",
    EDIT_ATTRIBUTE_FAILURE: "EDIT_ATTRIBUTE_FAILURE",

    GET_DETAIL_ATTRIBUTE_REQUEST: "GET_DETAIL_ATTRIBUTE_REQUEST",
    GET_DETAIL_ATTRIBUTE_SUCCESS: "GET_DETAIL_ATTRIBUTE_SUCCESS",
    GET_DETAIL_ATTRIBUTE_FAILURE: "GET_DETAIL_ATTRIBUTE_FAILURE"


}