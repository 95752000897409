export const NotificationConstants = {
    GET_MANUAL_NOTIFICATIONS_REQUEST: 'GET_MANUAL_NOTIFICATIONS_REQUEST',
    GET_MANUAL_NOTIFICATIONS_SUCCESS: 'GET_MANUAL_NOTIFICATIONS_SUCCESS',
    GET_MANUAL_NOTIFICATIONS_FAILE: 'GET_MANUAL_NOTIFICATIONS_FAILE',

    PAGINATE_MANUAL_NOTIFICATIONS_REQUEST: 'PAGINATE_MANUAL_NOTIFICATIONS_REQUEST',
    PAGINATE_MANUAL_NOTIFICATIONS_SUCCESS: 'PAGINATE_MANUAL_NOTIFICATIONS_SUCCESS',
    PAGINATE_MANUAL_NOTIFICATIONS_FAILE: 'PAGINATE_MANUAL_NOTIFICATIONS_FAILE',

    GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
    GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAILE: 'GET_NOTIFICATIONS_FAILE',

    PAGINATE_NOTIFICATIONS_REQUEST: 'PAGINATE_NOTIFICATIONS_REQUEST',
    PAGINATE_NOTIFICATIONS_SUCCESS: 'PAGINATE_NOTIFICATIONS_SUCCESS',
    PAGINATE_NOTIFICATIONS_FAILE: 'PAGINATE_NOTIFICATIONS_FAILE',

    CREATE_NOTIFICATION_REQUEST: 'CREATE_NOTIFICATION_REQUEST',
    CREATE_NOTIFICATION_SUCCESS: 'CREATE_NOTIFICATION_SUCCESS',
    CREATE_NOTIFICATION_FAILE: 'CREATE_NOTIFICATION_FAILE',

    READED_NOTIFICATION_REQUEST: 'READED_NOTIFICATION_REQUEST',
    READED_NOTIFICATION_SUCCESS: 'READED_NOTIFICATION_SUCCESS',
    READED_NOTIFICATION_FAILE: 'READED_NOTIFICATION_FAILE',

    DELETE_MANUAL_NOTIFICATION_REQUEST: 'DELETE_MANUAL_NOTIFICATION_REQUEST',
    DELETE_MANUAL_NOTIFICATION_SUCCESS: 'DELETE_MANUAL_NOTIFICATION_SUCCESS',
    DELETE_MANUAL_NOTIFICATION_FAILE: 'DELETE_MANUAL_NOTIFICATION_FAILE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILE: 'DELETE_NOTIFICATION_FAILE',

    SET_LEVEL_TO_QUERY_NOTIFICATION_RECEIVERED: 'SET_LEVEL_TO_QUERY_NOTIFICATION_RECEIVERED',
    SET_LEVEL_TO_QUERY_NOTIFICATION_SENT: 'SET_LEVEL_TO_QUERY_NOTIFICATION_SENT',

    RECEIVE_NOTIFICATION_SUCCESS: 'RECEIVE_NOTIFICATION_SUCCESS',
}