export const StockConstants = {
    GET_STOCK_REQUEST: 'GET_STOCK_REQUEST',
    GET_STOCK_SUCCESS: 'GET_STOCK_SUCCESS',
    GET_STOCK_FAILURE: 'GET_STOCK_FAILURE',

    GET_DETAIL_STOCK_REQUEST: 'GET_DETAIL_STOCK_REQUEST',
    GET_DETAIL_STOCK_SUCCESS: 'GET_DETAIL_STOCK_SUCCESS',
    GET_DETAIL_STOCK_FAILURE: 'GET_DETAIL_STOCK_FAILURE',

    PAGINATE_STOCK_REQUEST: 'PAGINATE_STOCK_REQUEST',
    PAGINATE_STOCK_SUCCESS: 'PAGINATE_STOCK_SUCCESS',
    PAGINATE_STOCK_FAILURE: 'PAGINATE_STOCK_FAILURE',

    CREATE_STOCK_REQUEST: 'CREATE_STOCK_REQUEST',
    CREATE_STOCK_SUCCESS: 'CREATE_STOCK_SUCCESS',
    CREATE_STOCK_FAILURE: 'CREATE_STOCK_FAILURE',

    UPDATE_STOCK_REQUEST: 'UPDATE_STOCK_REQUEST',
    UPDATE_STOCK_SUCCESS: 'UPDATE_STOCK_SUCCESS',
    UPDATE_STOCK_FAILURE: 'UPDATE_STOCK_FAILURE',

    DELETE_STOCK_REQUEST: 'DELETE_STOCK_REQUEST',
    DELETE_STOCK_SUCCESS: 'DELETE_STOCK_SUCCESS',
    DELETE_STOCK_FAILURE: 'DELETE_STOCK_FAILURE',

    IMPORT_STOCK_REQUEST: 'IMPORT_STOCK_REQUEST',
    IMPORT_STOCK_SUCCESS: 'IMPORT_STOCK_SUCCESS',
    IMPORT_STOCK_FAILURE: 'IMPORT_STOCK_FAILURE',
}
