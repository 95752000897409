export const LotConstants = {

    GET_LOT_REQUEST: 'GET_LOT_REQUEST',
    GET_LOT_SUCCESS: 'GET_LOT_SUCCESS',
    GET_LOT_FAILURE: 'GET_LOT_FAILURE',

    GET_LOT_PAGINATE_REQUEST: 'GET_LOT_PAGINATE_REQUEST',
    GET_LOT_PAGINATE_SUCCESS: 'GET_LOT_PAGINATE_SUCCESS',
    GET_LOT_PAGINATE_FAILURE: 'GET_LOT_PAGINATE_FAILURE',

    GET_LOT_DETAIL_REQUEST: 'GET_LOT_DETAIL_REQUEST',
    GET_LOT_DETAIL_SUCCESS: 'GET_LOT_DETAIL_SUCCESS',
    GET_LOT_DETAIL_FAILURE: 'GET_LOT_DETAIL_FAILURE',

    GET_LOT_BY_GOOD_REQUEST: 'GET_LOT_BY_GOOD_REQUEST',
    GET_LOT_BY_GOOD_SUCCESS: 'GET_LOT_BY_GOOD_SUCCESS',
    GET_LOT_BY_GOOD_FAILURE: 'GET_LOT_BY_GOOD_FAILURE',

    EDIT_LOT_REQUEST: 'EDIT_LOT_REQUEST',
    EDIT_LOT_SUCCESS: 'EDIT_LOT_SUCCESS',
    EDIT_LOT_FAILURE: 'EDIT_LOT_FAILURE',

    DELETE_LOT_REQUEST: 'DELETE_LOT_REQUEST',
    DELETE_LOT_SUCCESS: 'DELETE_LOT_SUCCESS',
    DELETE_LOT_FAILURE: 'DELETE_LOT_FAILURE',

    CREATE_OR_EDIT_LOT_REQUEST: 'CREATE_OR_EDIT_LOT_REQUEST',
    CREATE_OR_EDIT_LOT_SUCCESS: 'CREATE_OR_EDIT_LOT_SUCCESS',
    CREATE_OR_EDIT_LOT_FAILURE: 'CREATE_OR_EDIT_LOT_FAILURE',

    GET_ALL_MANUFACTURING_LOT_REQUEST: 'GET_ALL_MANUFACTURING_LOT_REQUEST',
    GET_ALL_MANUFACTURING_LOT_SUCCESS: 'GET_ALL_MANUFACTURING_LOT_SUCCESS',
    GET_ALL_MANUFACTURING_LOT_FAILURE: 'GET_ALL_MANUFACTURING_LOT_FAILURE',

    CREATE_MANUFACTURING_LOT_REQUEST: 'CREATE_MANUFACTURING_LOT_REQUEST',
    CREATE_MANUFACTURING_LOT_SUCCESS: 'CREATE_MANUFACTURING_LOT_SUCCESS',
    CREATE_MANUFACTURING_LOT_FAILURE: 'CREATE_MANUFACTURING_LOT_FAILURE',

    GET_DETAIL_MANUFACTURING_LOT_REQUEST: 'GET_DETAIL_MANUFACTURING_LOT_REQUEST',
    GET_DETAIL_MANUFACTURING_LOT_SUCCESS: 'GET_DETAIL_MANUFACTURING_LOT_SUCCESS',
    GET_DETAIL_MANUFACTURING_LOT_FAILURE: 'GET_DETAIL_MANUFACTURING_LOT_FAILURE',

    EDIT_MANUFACTURING_LOT_REQUEST: 'EDIT_MANUFACTURING_LOT_REQUEST',
    EDIT_MANUFACTURING_LOT_SUCCESS: 'EDIT_MANUFACTURING_LOT_SUCCESS',
    EDIT_MANUFACTURING_LOT_FAILURE: 'EDIT_MANUFACTURING_LOT_FAILURE',

    GET_INVENTORY_BY_GOOD_IDS_REQUEST: 'GET_INVENTORY_BY_GOOD_IDS_REQUEST',
    GET_INVENTORY_BY_GOOD_IDS_SUCCESS: 'GET_INVENTORY_BY_GOOD_IDS_SUCCESS',
    GET_INVENTORY_BY_GOOD_IDS_FAILURE: 'GET_INVENTORY_BY_GOOD_IDS_FAILURE',

    GET_INVENTORY_BY_GOOD_ID_REQUEST: 'GET_INVENTORY_BY_GOOD_ID_REQUEST',
    GET_INVENTORY_BY_GOOD_ID_SUCCESS: 'GET_INVENTORY_BY_GOOD_ID_SUCCESS',
    GET_INVENTORY_BY_GOOD_ID_FAILURE: 'GET_INVENTORY_BY_GOOD_ID_FAILURE',

    GET_INVENTORY_DASHBOARD_REQUEST: 'GET_INVENTORY_DASHBOARD_REQUEST',
    GET_INVENTORY_DASHBOARD_SUCCESS: 'GET_INVENTORY_DASHBOARD_SUCCESS',
    GET_INVENTORY_DASHBOARD_FAILURE: 'GET_INVENTORY_DASHBOARD_FAILURE',

    GET_INVENTORY_BY_GOOD_AND_STOCK_REQUEST: 'GET_INVENTORY_BY_GOOD_AND_STOCK_REQUEST',
    GET_INVENTORY_BY_GOOD_AND_STOCK_SUCCESS: 'GET_INVENTORY_BY_GOOD_AND_STOCK_SUCCESS',
    GET_INVENTORY_BY_GOOD_AND_STOCK_FAILURE: 'GET_INVENTORY_BY_GOOD_AND_STOCK_FAILURE',

    GET_MANUFACTURING_LOT_NUMBER_BY_STATUS_REQUEST: 'GET_MANUFACTURING_LOT_NUMBER_BY_STATUS_REQUEST',
    GET_MANUFACTURING_LOT_NUMBER_BY_STATUS_FAILURE: 'GET_MANUFACTURING_LOT_NUMBER_BY_STATUS_FAILURE',
    GET_MANUFACTURING_LOT_NUMBER_BY_STATUS_SUCCESS: 'GET_MANUFACTURING_LOT_NUMBER_BY_STATUS_SUCCESS',
}
