export const AssetTypeConstants = {
    GET_ASSET_TYPE_REQUEST: 'GET_ASSET_TYPE_REQUEST',
    GET_ASSET_TYPE_SUCCESS: 'GET_ASSET_TYPE_SUCCESS',
    GET_ASSET_TYPE_FAILURE: 'GET_ASSET_TYPE_FAILURE',

    GET_ALL_ASSET_TYPE_REQUEST: 'GET_ALL_ASSET_TYPE_REQUEST',
    GET_ALL_ASSET_TYPE_SUCCESS: 'GET_ALL_ASSET_TYPE_SUCCESS',
    GET_ALL_ASSET_TYPE_FAILE: 'GET_ALL_ASSET_TYPE_FAILE',

    CREATE_ASSET_TYPE_REQUEST: 'CREATE_ASSET_TYPE_REQUEST',
    CREATE_ASSET_TYPE_SUCCESS: 'CREATE_ASSET_TYPE_SUCCESS',
    CREATE_ASSET_TYPE_FAILE: 'CREATE_ASSET_TYPE_FAILE',

    EDIT_ASSET_TYPE_REQUEST: 'EDIT_ASSET_TYPE_REQUEST',
    EDIT_ASSET_TYPE_SUCCESS: 'EDIT_ASSET_TYPE_SUCCESS',
    EDIT_ASSET_TYPE_FAILE: 'EDIT_ASSET_TYPE_FAILE',

    DELETE_ASSET_TYPE_REQUEST: 'DELETE_ASSET_TYPE_REQUEST',
    DELETE_ASSET_TYPE_SUCCESS: 'DELETE_ASSET_TYPE_SUCCESS',
    DELETE_ASSET_TYPE_FAILE: 'DELETE_ASSET_TYPE_FAILE',

    IMPORT_ASSET_TYPE_REQUEST: 'IMPORT_ASSET_TYPE_REQUEST',
    IMPORT_ASSET_TYPE_SUCCESS: 'IMPORT_ASSET_TYPE_SUCCESS',
    IMPORT_ASSET_TYPE_FAILE: 'IMPORT_ASSET_TYPE_FAILE',

}
export const Users = {
    GET_ALL_USERS : 'GET_ALL_USERS'
}
