export const policyConstants = {
    GET_ALL_POLICIES_REQUEST: "GET_ALL_POLICIES_REQUEST",
    GET_ALL_POLICIES_SUCCESS: "GET_ALL_POLICIES_SUCCESS",
    GET_ALL_POLICIES_FAILURE: "GET_ALL_POLICIES_FAILURE",

    DELETE_POLICY_REQUEST: "DELETE_DELETE_POLICY_REQUEST",
    DELETE_POLICY_SUCCESS: "DELETE_POLICY_SUCCESS",
    DELETE_POLICY_FAILURE: "DELETE_POLICY_FAILURE",

    CREATE_POLICY_REQUEST: "CREATE_POLICY_REQUEST",
    CREATE_POLICY_SUCCESS: "CREATE_POLICY_SUCCESS",
    CREATE_POLICY_FAILURE: "CREATE_POLICY_FAILURE",

    EDIT_POLICY_REQUEST: "EDIT_POLICY_REQUEST",
    EDIT_POLICY_SUCCESS: "EDIT_POLICY_SUCCESS",
    EDIT_POLICY_FAILURE: "EDIT_POLICY_FAILURE",

    GET_DETAIL_POLICY_REQUEST: "GET_DETAIL_POLICY_REQUEST",
    GET_DETAIL_POLICY_SUCCESS: "GET_DETAIL_POLICY_SUCCESS",
    GET_DETAIL_POLICY_FAILURE: "GET_DETAIL_POLICY_FAILURE"


}