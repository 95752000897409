export const SalaryConstants = {
    GET_SALARY_REQUEST: 'GET_SALARY_REQUEST',
    GET_SALARY_SUCCESS: 'GET_SALARY_SUCCESS',
    GET_SALARY_FAILURE: 'GET_SALARY_FAILURE',

    CREATE_SALARY_REQUEST: 'CREATE_NEW_SALARY_REQUEST',
    CREATE_SALARY_SUCCESS: 'CREATE_NEW_SALARY_SUCCESS',
    CREATE_SALARY_FAILURE: 'CREATE_NEW_SALARY_FAILURE',

    DELETE_SALARY_REQUEST: 'DELETE_SALARY_REQUEST',
    DELETE_SALARY_SUCCESS:  'DELETE_SALARY_SUCCESS',
    DELETE_SALARY_FAILURE:  'DELETE_SALARY_FAILURE',

    UPDATE_SALARY_REQUEST: 'UPDATE_SALARY_REQUEST',
    UPDATE_SALARY_SUCCESS: 'UPDATE_SALARY_SUCCESS',
    UPDATE_SALARY_FAILURE: 'UPDATE_SALARY_FAILURE',

    IMPORT_SALARY_REQUEST: "IMPORT_SALARY_EMPLOYEE_REQUEST",
    IMPORT_SALARY_SUCCESS: "IMPORT_SALARY_EMPLOYEE_SUCCESS",
    IMPORT_SALARY_FAILURE: "IMPORT_SALARY_EMPLOYEE_FAILURE",

    GET_ALL_SALARY_CHART_REQUEST: "GET_ALL_SALARY_CHART_REQUEST",
    GET_ALL_SALARY_CHART_SUCCESS: "GET_ALL_SALARY_CHART_SUCCESS",
    GET_ALL_SALARY_CHART_FAILURE: "GET_ALL_SALARY_CHART_FAILURE",

};