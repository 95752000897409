export const CrmGroupConstants = {
    GET_CRM_GROUPS_REQUEST: 'GET_CRM_GROUPS_REQUEST',
    GET_CRM_GROUPS_SUCCESS: 'GET_CRM_GROUPS_SUCCESS',
    GET_CRM_GROUPS_FAILE: 'GET_CRM_GROUPS_FAILE',

    GET_CRM_GROUP_REQUEST: 'GET_CRM_GROUP_REQUEST',
    GET_CRM_GROUP_SUCCESS: 'GET_CRM_GROUP_SUCCESS',
    GET_CRM_GROUP_FAILE: 'GET_CRM_GROUP_FAILE',

    CREATE_CRM_GROUP_REQUEST: 'CREATE_CRM_GROUP_REQUEST',
    CREATE_CRM_GROUP_SUCCESS: 'CREATE_CRM_GROUP_SUCCESS',
    CREATE_CRM_GROUP_FAILE: 'CREATE_CRM_GROUP_FAILE',

    EDIT_CRM_GROUP_REQUEST: 'EDIT_CRM_GROUP_REQUEST',
    EDIT_CRM_GROUP_SUCCESS: 'EDIT_CRM_GROUP_SUCCESS',
    EDIT_CRM_GROUP_FAILE: 'EDIT_CRM_GROUP_FAILE',

    DELETE_CRM_GROUP_REQUEST: 'DELETE_CRM_GROUP_REQUEST',
    DELETE_CRM_GROUP_SUCCESS: 'DELETE_CRM_GROUP_SUCCESS',
    DELETE_CRM_GROUP_FAILE: 'DELETE_CRM_GROUP_FAILE',

    ADD_CRM_GROUP_PROMOTION_REQUEST: 'ADD_CRM_GROUP_PROMOTION_REQUEST',
    ADD_CRM_GROUP_PROMOTION_SUCCESS: 'ADD_CRM_GROUP_PROMOTION_SUCCESS',
    ADD_CRM_GROUP_PROMOTION_FAILE: 'ADD_CRM_GROUP_PROMOTION_FAILE',

    EDIT_CRM_GROUP_PROMOTION_REQUEST: 'EDIT_CRM_GROUP_PROMOTION_REQUEST',
    EDIT_CRM_GROUP_PROMOTION_SUCCESS: 'EDIT_CRM_GROUP_PROMOTION_SUCCESS',
    EDIT_CRM_GROUP_PROMOTION_FAILE: 'EDIT_CRM_GROUP_PROMOTION_FAILE',

    DELETE_CRM_GROUP_PROMOTION_REQUEST: 'DELETE_CRM_GROUP_PROMOTION_REQUEST',
    DELETE_CRM_GROUP_PROMOTION_SUCCESS: 'DELETE_CRM_GROUP_PROMOTION_SUCCESS',
    DELETE_CRM_GROUP_PROMOTION_FAILE: 'DELETE_CRM_GROUP_PROMOTION_FAILE',

    GET_CRM_MEMBERS_IN_GROUP_REQUEST: 'GET_CRM_MEMBERS_IN_GROUP_REQUEST',
    GET_CRM_MEMBERS_IN_GROUP_SUCCESS: 'GET_CRM_MEMBERS_IN_GROUP_SUCCESS',
    GET_CRM_MEMBERS_IN_GROUP_FAILE: 'GET_CRM_MEMBERS_IN_GROUP_FAILE'
}