export const createUnitKpiConstants = {
    GETCURRENT_KPIUNIT_REQUEST: 'GETCURRENT_KPIUNIT_REQUEST',
    GETCURRENT_KPIUNIT_SUCCESS: 'GETCURRENT_KPIUNIT_SUCCESS',
    GETCURRENT_KPIUNIT_FAILURE: 'GETCURRENT_KPIUNIT_FAILURE',

    GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_REQUEST: 'GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_REQUEST',
    GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_SUCCESS: 'GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_SUCCESS',
    GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_FAILURE: 'GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_FAILURE',

    GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_OF_CHILDUNIT_REQUEST: 'GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_OF_CHILDUNIT_REQUEST',
    GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_OF_CHILDUNIT_SUCCESS: 'GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_OF_CHILDUNIT_SUCCESS',
    GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_OF_CHILDUNIT_FAILURE: 'GET_ALL_ORGANIZATIONALUNIT_KPI_SET_BY_TIME_OF_CHILDUNIT_FAILURE',
    
    EDIT_KPIUNIT_REQUEST: 'EDIT_KPIUNIT_REQUEST',
    EDIT_KPIUNIT_SUCCESS: 'EDIT_KPIUNIT_SUCCESS',
    EDIT_KPIUNIT_FAILURE: 'EDIT_KPIUNIT_FAILURE',

    DELETE_KPIUNIT_REQUEST: 'DELETE_KPIUNIT_REQUEST',
    DELETE_KPIUNIT_SUCCESS: 'DELETE_KPIUNIT_SUCCESS',
    DELETE_KPIUNIT_FAILURE: 'DELETE_KPIUNIT_FAILURE',

    DELETETARGET_KPIUNIT_REQUEST: 'DELETETARGET_KPIUNIT_REQUEST',
    DELETETARGET_KPIUNIT_SUCCESS: 'DELETETARGET_KPIUNIT_SUCCESS',
    DELETETARGET_KPIUNIT_FAILURE: 'DELETETARGET_KPIUNIT_FAILURE',

    GETPARENT_KPIUNIT_REQUEST: 'GETPARENT_KPIUNIT_REQUEST',
    GETPARENT_KPIUNIT_SUCCESS: 'GETPARENT_KPIUNIT_SUCCESS',
    GETPARENT_KPIUNIT_FAILURE: 'GETPARENT_KPIUNIT_FAILURE',

    ADDTARGET_KPIUNIT_REQUEST: 'ADDTARGET_KPIUNIT_REQUEST',
    ADDTARGET_KPIUNIT_SUCCESS: 'ADDTARGET_KPIUNIT_SUCCESS',
    ADDTARGET_KPIUNIT_FAILURE: 'ADDTARGET_KPIUNIT_FAILURE',

    EDITTARGET_KPIUNIT_REQUEST: 'EDITTARGET_KPIUNIT_REQUEST',
    EDITTARGET_KPIUNIT_SUCCESS: 'EDITTARGET_KPIUNIT_SUCCESS',
    EDITTARGET_KPIUNIT_FAILURE: 'EDITTARGET_KPIUNIT_FAILURE',

    ADD_KPIUNIT_REQUEST: 'ADD_KPIUNIT_REQUEST',
    ADD_KPIUNIT_SUCCESS: 'ADD_KPIUNIT_SUCCESS',
    ADD_KPIUNIT_FAILURE: 'ADD_KPIUNIT_FAILURE',

    CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
    CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
    CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',

    CREATE_COMMENT_OF_COMMENT_REQUEST:'CREATE_COMMENT_OF_COMMENT_REQUEST',
    CREATE_COMMENT_OF_COMMENT_SUCCESS: 'CREATE_COMMENT_OF_COMMENT_SUCCESS',
    CREATE_COMMENT_OF_COMMENT_FAILURE: 'CREATE_COMMENT_OF_COMMENT_FAILURE',

    EDIT_COMMENT_REQUEST:'EDIT_COMMENT_REQUEST',
    EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
    EDIT_COMMENT_FAILURE: 'EDIT_COMMENT_FAILURE',

    DELETE_COMMENT_REQUEST:'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

    EDIT_COMMENT_OF_COMMENT_REQUEST:'EDIT_COMMENT_OF_COMMENT_REQUEST',
    EDIT_COMMENT_OF_COMMENT_SUCCESS: 'EDIT_COMMENT_OF_COMMENT_SUCCESS',
    EDIT_COMMENT_OF_COMMENT_FAILURE: 'EDIT_COMMENT_OF_COMMENT_FAILURE',

    DELETE_COMMENT_OF_COMMENT_REQUEST:'DELETE_COMMENT_OF_COMMENT_REQUEST',
    DELETE_COMMENT_OF_COMMENT_SUCCESS: 'DELETE_COMMENT_OF_COMMENT_SUCCESS',
    DELETE_COMMENT_OF_COMMENT_FAILURE: 'DELETE_COMMENT_OF_COMMENT_FAILURE',

    DELETE_FILE_COMMENT_REQUEST: 'DELETE_FILE_COMMENT_REQUEST',
    DELETE_FILE_COMMENT_SUCCESS: 'DELETE_FILE_COMMENT_SUCCESS',
    DELETE_FILE_COMMENT_FAILURE: 'DELETE_FILE_COMMENT_FAILURE',

    DELETE_FILE_CHILD_COMMENT_REQUEST: 'DELETE_FILE_CHILD_COMMENT_REQUEST',
    DELETE_FILE_CHILD_COMMENT_SUCCESS: 'DELETE_FILE_CHILD_COMMENT_SUCCESS',
    DELETE_FILE_CHILD_COMMENT_FAILURE: 'DELETE_FILE_CHILD_COMMENT_FAILURE',
}