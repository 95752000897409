export const SystemComponentConstants = {
    GET_ALL_COMPONENTS_DEFAULT_REQUEST: 'GET_ALL_COMPONENTS_DEFAULT_REQUEST',
    GET_ALL_COMPONENTS_DEFAULT_SUCCESS: 'GET_ALL_COMPONENTS_DEFAULT_SUCCESS',
    GET_ALL_COMPONENTS_DEFAULT_FAILURE: 'GET_ALL_COMPONENTS_DEFAULT_FAILURE',

    GET_ALL_COMPONENTS_DEFAULT_PAGINATE_REQUEST: 'GET_ALL_COMPONENTS_DEFAULT_PAGINATE_REQUEST',
    GET_ALL_COMPONENTS_DEFAULT_PAGINATE_SUCCESS: 'GET_ALL_COMPONENTS_DEFAULT_PAGINATE_SUCCESS',
    GET_ALL_COMPONENTS_DEFAULT_PAGINATE_FAILURE: 'GET_ALL_COMPONENTS_DEFAULT_PAGINATE_FAILURE',

    GET_COMPONENT_DEFAULT_REQUEST: 'GET_COMPONENT_DEFAULT_REQUEST',
    GET_COMPONENT_DEFAULT_SUCCESS: 'GET_COMPONENT_DEFAULT_SUCCESS',
    GET_COMPONENT_DEFAULT_FAILURE: 'GET_COMPONENT_DEFAULT_FAILURE',

    CREATE_COMPONENT_DEFAULT_REQUEST: 'CREATE_COMPONENT_DEFAULT_REQUEST',
    CREATE_COMPONENT_DEFAULT_SUCCESS: 'CREATE_COMPONENT_DEFAULT_SUCCESS',
    CREATE_COMPONENT_DEFAULT_FAILURE: 'CREATE_COMPONENT_DEFAULT_FAILURE',

    EDIT_COMPONENT_DEFAULT_REQUEST: 'EDIT_COMPONENT_DEFAULT_REQUEST',
    EDIT_COMPONENT_DEFAULT_SUCCESS: 'EDIT_COMPONENT_DEFAULT_SUCCESS',
    EDIT_COMPONENT_DEFAULT_FAILURE: 'EDIT_COMPONENT_DEFAULT_FAILURE',

    DELETE_COMPONENT_DEFAULT_REQUEST: 'DELETE_COMPONENT_DEFAULT_REQUEST',
    DELETE_COMPONENT_DEFAULT_SUCCESS: 'DELETE_COMPONENT_DEFAULT_SUCCESS',
    DELETE_COMPONENT_DEFAULT_FAILURE: 'DELETE_COMPONENT_DEFAULT_FAILURE',
}