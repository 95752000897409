export const IncidentConstants = {
    
    GET_INCIDENT_REQUEST: 'GET_INCIDENT_REQUEST',
    GET_INCIDENT_SUCCESS: 'GET_INCIDENT_SUCCESS',
    GET_INCIDENT_FAILURE: 'GET_INCIDENT_FAILURE',

    CREATE_INCIDENT_REQUEST: 'CREATE_NEW_INCIDENT_REQUEST',
    CREATE_INCIDENT_SUCCESS: 'CREATE_NEW_INCIDENT_SUCCESS',
    CREATE_INCIDENT_FAILURE: 'CREATE_NEW_INCIDENT_FAILURE',

    CREATE_MAINTAINANCE_FOR_INCIDENT_REQUEST: 'CREATE_MAINTAINANCE_FOR_INCIDENT_REQUEST',
    CREATE_MAINTAINANCE_FOR_INCIDENT_SUCCESS: 'CREATE_MAINTAINANCE_FOR_INCIDENT_SUCCESS',
    CREATE_MAINTAINANCE_FOR_INCIDENT_FAILURE: 'CREATE_MAINTAINANCE_FOR_INCIDENT_FAILURE',

    UPDATE_INCIDENT_REQUEST: 'UPDATE_INCIDENT_REQUEST',
    UPDATE_INCIDENT_SUCCESS: 'UPDATE_INCIDENT_SUCCESS',
    UPDATE_INCIDENT_FAILURE: 'UPDATE_INCIDENT_FAILURE',

    DELETE_INCIDENT_REQUEST: 'DELETE_INCIDENT_REQUEST',
    DELETE_INCIDENT_SUCCESS: 'DELETE_INCIDENT_SUCCESS',
    DELETE_INCIDENT_FAILURE: 'DELETE_INCIDENT_FAILURE',

};
