export const SuppliesConstants = {
    SEARCH_SUPPLIES_REQUEST: 'SEARCH_SUPPLIES_REQUEST',
    SEARCH_SUPPLIES_SUCCESS: 'SEARCH_SUPPLIES_SUCCESS',
    SEARCH_SUPPLIES_FAILURE: 'SEARCH_SUPPLIES_FAILURE',

    CREATE_SUPPLIES_REQUEST: 'CREATE_SUPPLIES_REQUEST',
    CREATE_SUPPLIES_SUCCESS: 'CREATE_SUPPLIES_SUCCESS',
    CREATE_SUPPLIES_FAILURE: 'CREATE_SUPPLIES_FAILURE',

    UPDATE_SUPPLIES_REQUEST: 'UPDATE_SUPPLIES_REQUEST',
    UPDATE_SUPPLIES_SUCCESS: 'UPDATE_SUPPLIES_SUCCESS',
    UPDATE_SUPPLIES_FAILURE: 'UPDATE_SUPPLIES_FAILURE',

    DELETE_SUPPLIES_REQUEST: 'DELETE_SUPPLIES_REQUEST',
    DELETE_SUPPLIES_SUCCESS: 'DELETE_SUPPLIES_SUCCESS',
    DELETE_SUPPLIES_FAILURE: 'DELETE_SUPPLIES_FAILURE',

    GET_SUPPLIES_BY_ID_REQUEST: 'GET_SUPPLIES_BY_ID_REQUEST',
    GET_SUPPLIES_BY_ID_SUCCESS: 'GET_SUPPLIES_BY_ID_SUCCESS',
    GET_SUPPLIES_BY_ID_FAILURE: 'GET_SUPPLIES_BY_ID_FAILURE',

}