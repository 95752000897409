export const SystemSettingConstants = {
    GET_BACKUPS_REQUEST: 'GET_BACKUPS_REQUEST',
    GET_BACKUPS_SUCCESS: 'GET_BACKUPS_SUCCESS',
    GET_BACKUPS_FAILURE: 'GET_BACKUPS_FAILURE',

    GET_CONFIG_BACKUP_REQUEST: 'GET_CONFIG_BACKUP_REQUEST',
    GET_CONFIG_BACKUP_SUCCESS: 'GET_CONFIG_BACKUP_SUCCESS',
    GET_CONFIG_BACKUP_FAILURE: 'GET_CONFIG_BACKUP_FAILURE',

    CREATE_BACKUP_REQUEST: 'CREATE_BACKUP_REQUEST',
    CREATE_BACKUP_SUCCESS: 'CREATE_BACKUP_SUCCESS',
    CREATE_BACKUP_FAILURE: 'CREATE_BACKUP_FAILURE',

    CONFIG_BACKUP_REQUEST: 'CONFIG_BACKUP_REQUEST',
    CONFIG_BACKUP_SUCCESS: 'CONFIG_BACKUP_SUCCESS',
    CONFIG_BACKUP_FAILURE: 'CONFIG_BACKUP_FAILURE',

    EDIT_BACKUP_INFO_REQUEST: 'EDIT_BACKUP_INFO_REQUEST',
    EDIT_BACKUP_INFO_SUCCESS: 'EDIT_BACKUP_INFO_SUCCESS',
    EDIT_BACKUP_INFO_FAILURE: 'EDIT_BACKUP_INFO_FAILURE',

    DELETE_BACKUP_REQUEST: 'DELETE_BACKUP_REQUEST',
    DELETE_BACKUP_SUCCESS: 'DELETE_BACKUP_SUCCESS',
    DELETE_BACKUP_FAILURE: 'DELETE_BACKUP_FAILURE',

    DOWNLOAD_BACKUP_VERSION_REQUEST: 'DOWNLOAD_BACKUP_VERSION_REQUEST',
    DOWNLOAD_BACKUP_VERSION_SUCCESS: 'DOWNLOAD_BACKUP_VERSION_SUCCESS',
    DOWNLOAD_BACKUP_VERSION_FAILURE: 'DOWNLOAD_BACKUP_VERSION_FAILURE',

    RESTORE_REQUEST: 'RESTORE_REQUEST',
    RESTORE_SUCCESS: 'RESTORE_SUCCESS',
    RESTORE_FAILURE: 'RESTORE_FAILURE',

    UPLOAD_BACKUP_FILE_REQUEST: 'UPLOAD_BACKUP_FILE_REQUEST',
    UPLOAD_BACKUP_FILE_SUCCESS: 'UPLOAD_BACKUP_FILE_SUCCESS',
    UPLOAD_BACKUP_FILE_FAILURE: 'UPLOAD_BACKUP_FILE_FAILURE'
}