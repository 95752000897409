export const ProjectTemplateConstants = {
    GET_PROJECTS_TEMPLATE_REQUEST: 'GET_PROJECTS_TEMPLATE_REQUEST',
    GET_PROJECTS_TEMPLATE_SUCCESS: 'GET_PROJECTS_TEMPLATE_SUCCESS',
    GET_PROJECTS_TEMPLATE_FAILE: 'GET_PROJECTS_TEMPLATE_FAILE',

    CREATE_PROJECTS_TEMPLATE_REQUEST: 'CREATE_PROJECTS_TEMPLATE_REQUEST',
    CREATE_PROJECTS_TEMPLATE_SUCCESS: 'CREATE_PROJECTS_TEMPLATE_SUCCESS',
    CREATE_PROJECTS_TEMPLATE_FAILE: 'CREATE_PROJECTS_TEMPLATE_FAILE',

    EDIT_PROJECTS_TEMPLATE_REQUEST: 'EDIT_PROJECTS_TEMPLATE_REQUEST',
    EDIT_PROJECTS_TEMPLATE_SUCCESS: 'EDIT_PROJECTS_TEMPLATE_SUCCESS',
    EDIT_PROJECTS_TEMPLATE_FAILE: 'EDIT_PROJECTS_TEMPLATE_FAILE',

    CREATE_PROJECT_BY_TEMPLATE_REQUEST: 'CREATE_PROJECT_BY_TEMPLATE_REQUEST',
    CREATE_PROJECT_BY_TEMPLATE_SUCCESS: 'CREATE_PROJECT_BY_TEMPLATE_SUCCESS',
    CREATE_PROJECT_BY_TEMPLATE_FAILE: 'CREATE_PROJECT_BY_TEMPLATE_FAILE',

    DELETE_PROJECTS_TEMPLATE_REQUEST: 'DELETE_PROJECTS_TEMPLATE_REQUEST',
    DELETE_PROJECTS_TEMPLATE_SUCCESS: 'DELETE_PROJECTS_TEMPLATE_SUCCESS',
    DELETE_PROJECTS_TEMPLATE_FAILE: 'DELETE_PROJECTS_TEMPLATE_FAILE',

    GET_SALARY_MEMBER_PROJECTS_TEMPLATE: 'GET_SALARY_MEMBER_PROJECTS_TEMPLATE',
    GET_SALARY_MEMBER_PROJECTS_TEMPLATE_SUCCESS: 'GET_SALARY_MEMBER_PROJECTS_TEMPLATE_SUCCESS',
    GET_SALARY_MEMBER_PROJECTS_TEMPLATE_FAILE: 'GET_SALARY_MEMBER_PROJECTS_TEMPLATE_FAILE',
}