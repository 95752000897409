export const GoodConstants = {
    GET_GOOD_BY_TYPE_REQUEST: 'GET_GOOD_BY_TYPE_REQUEST',
    GET_GOOD_BY_TYPE_SUCCESS: 'GET_GOOD_BY_TYPE_SUCCESS',
    GET_GOOD_BY_TYPE_FAILURE: 'GET_GOOD_BY_TYPE_FAILURE',

    GET_NUMBER_GOODS_REQUEST: 'GET_NUMBER_GOODS_REQUEST',
    GET_NUMBER_GOODS_SUCCESS: 'GET_NUMBER_GOODS_SUCCESS',
    GET_NUMBER_GOODS_FAILURE: 'GET_NUMBER_GOODS_FAILURE',

    GET_GOOD_DETAIL_REQUEST: 'GET_GOOD_DETAIL_REQUEST',
    GET_GOOD_DETAIL_SUCCESS: 'GET_GOOD_DETAIL_SUCCESS',
    GET_GOOD_DETAIL_FAILURE: 'GET_GOOD_DETAIL_FAILURE',

    GETALL_GOOD_BY_TYPE_REQUEST: 'GETALL_GOOD_BY_TYPE_REQUEST',
    GETALL_GOOD_BY_TYPE_SUCCESS: 'GETALL_GOOD_BY_TYPE_SUCCESS',
    GETALL_GOOD_BY_TYPE_FAILURE: 'GETALL_GOOD_BY_TYPE_FAILURE',

    GETALL_GOOD_BY_CATEGORY_REQUEST: 'GETALL_GOOD_BY_CATEGORY_REQUEST',
    GETALL_GOOD_BY_CATEGORY_SUCCESS: 'GETALL_GOOD_BY_CATEGORY_SUCCESS',
    GETALL_GOOD_BY_CATEGORY_FAILURE: 'GETALL_GOOD_BY_CATEGORY_FAILURE',

    GETALL_GOODS_REQUEST: 'GETALL_GOODS_REQUEST',
    GETALL_GOODS_SUCCESS: 'GETALL_GOODS_SUCCESS',
    GETALL_GOODS_FAILURE: 'GETALL_GOODS_FAILURE',

    PAGINATE_GOOD_BY_TYPE_REQUEST: 'PAGINATE_GOOD_BY_TYPE_REQUEST',
    PAGINATE_GOOD_BY_TYPE_SUCCESS: 'PAGINATE_GOOD_BY_TYPE_SUCCESS',
    PAGINATE_GOOD_BY_TYPE_FAILURE: 'PAGINATE_GOOD_BY_TYPE_FAILURE',

    CREATE_GOOD_REQUEST: 'CREATE_GOOD_REQUEST',
    CREATE_GOOD_SUCCESS: 'CREATE_GOOD_SUCCESS',
    CREATE_GOOD_FAILURE: 'CREATE_GOOD_FAILURE',

    UPDATE_GOOD_REQUEST: 'UPDATE_GOOD_REQUEST',
    UPDATE_GOOD_SUCCESS: 'UPDATE_GOOD_SUCCESS',
    UPDATE_GOOD_FAILURE: 'UPDATE_GOOD_FAILURE',

    DELETE_GOOD_REQUEST: 'DELETE_GOOD_REQUEST',
    DELETE_GOOD_SUCCESS: 'DELETE_GOOD_SUCCESS',
    DELETE_GOOD_FAILURE: 'DELETE_GOOD_FAILURE',

    IMPORT_GOOD_REQUEST: 'IMPORT_GOOD_REQUEST',
    IMPORT_GOOD_SUCCESS: 'IMPORT_GOOD_SUCCESS',
    IMPORT_GOOD_FAILURE: 'IMPORT_GOOD_FAILURE',

    GET_ITEMS_FOR_GOOD_REQUEST: 'GET_ITEMS_FOR_GOOD_REQUEST',
    GET_ITEMS_FOR_GOOD_SUCCESS: 'GET_ITEMS_FOR_GOOD_SUCCESS',
    GET_ITEMS_FOR_GOOD_FAILURE: 'GET_ITEMS_FOR_GOOD_FAILURE',

    GET_GOOD_BY_MANAGE_WORK_ROLE_REQUEST: 'GET_GOOD_BY_MANAGE_WORK_ROLE_REQUEST',
    GET_GOOD_BY_MANAGE_WORK_ROLE_SUCCESS: 'GET_GOOD_BY_MANAGE_WORK_ROLE_SUCCESS',
    GET_GOOD_BY_MANAGE_WORK_ROLE_FAILURE: 'GET_GOOD_BY_MANAGE_WORK_ROLE_FAILURE',

    GET_MANUFACTURING_WORKS_BY_PRODUCT_ID_REQUEST: 'GET_MANUFACTURING_WORKS_BY_PRODUCT_ID_REQUEST',
    GET_MANUFACTURING_WORKS_BY_PRODUCT_ID_SUCCESS: 'GET_MANUFACTURING_WORKS_BY_PRODUCT_ID_SUCCESS',
    GET_MANUFACTURING_WORKS_BY_PRODUCT_ID_FAILURE: 'GET_MANUFACTURING_WORKS_BY_PRODUCT_ID_FAILURE',
}
