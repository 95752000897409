export const FieldsConstants = {
    GET_FIELDS_REQUEST: 'GET_FIELDS_REQUEST',
    GET_FIELDS_SUCCESS: 'GET_FIELDS_SUCCESS',
    GET_FIELDS_FAILURE: 'GET_FIELDS_FAILURE',

    CREATE_FIELDS_REQUEST: 'CREATE_NEW_FIELDS_REQUEST',
    CREATE_FIELDS_SUCCESS: 'CREATE_NEW_FIELDS_SUCCESS',
    CREATE_FIELDS_FAILURE: 'CREATE_NEW_FIELDS_FAILURE',

    DELETE_FIELDS_REQUEST: 'DELETE_FIELDS_REQUEST',
    DELETE_FIELDS_SUCCESS: 'DELETE_FIELDS_SUCCESS',
    DELETE_FIELDS_FAILURE: 'DELETE_FIELDS_FAILURE',

    UPDATE_FIELDS_REQUEST: 'UPDATE_FIELDS_REQUEST',
    UPDATE_FIELDS_SUCCESS: 'UPDATE_FIELDS_SUCCESS',
    UPDATE_FIELDS_FAILURE: 'UPDATE_FIELDS_FAILURE',
};