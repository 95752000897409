export const managerConstants = {
    GETALL_KPIUNIT_REQUEST: 'GETALL_KPIUNIT_REQUEST',
    GETALL_KPIUNIT_SUCCESS: 'GETALL_KPIUNIT_SUCCESS',
    GETALL_KPIUNIT_FAILURE: 'GETALL_KPIUNIT_FAILURE',

    GETCHILDTARGET_CURRENTTARGET_REQUEST: 'GETCHILDTARGET_CURRENTTARGET_REQUEST',
    GETCHILDTARGET_CURRENTTARGET_SUCCESS: 'GETCHILDTARGET_CURRENTTARGET_SUCCESS',
    GETCHILDTARGET_CURRENTTARGET_FAILURE: 'GETCHILDTARGET_CURRENTTARGET_FAILURE',

    COPY_KPIUNIT_REQUEST: 'COPY_KPIUNIT_REQUEST',
    COPY_KPIUNIT_SUCCESS: 'COPY_KPIUNIT_SUCCESS',
    COPY_KPIUNIT_FAILURE: 'COPY_KPIUNIT_FAILURE',

    CALCULATE_KPIUNIT_REQUEST: 'CALCULATE_KPIUNIT_REQUEST',
    CALCULATE_KPIUNIT_SUCCESS: 'CALCULATE_KPIUNIT_SUCCESS',
    CALCULATE_KPIUNIT_FAILURE: 'CALCULATE_KPIUNIT_FAILURE',

    CHECK_EXIST_KPI_REQUEST: 'CHECK_EXIST_KPI_REQUEST',
    CHECK_EXIST_KPI_SUCCESS: 'CHECK_EXIST_KPI_SUCCESS',
    CHECK_EXIST_KPI_FAILURE: 'CHECK_EXIST_KPI_FAILURE',

}