
export const workScheduleConstants = {
    GET_ALL_WORK_SCHEDULE_REQUEST: 'GET_ALL_WORK_SCHEDULE_REQUEST',
    GET_ALL_WORK_SCHEDULE_FAILURE: 'GET_ALL_WORK_SCHEDULE_FAILURE',
    GET_ALL_WORK_SCHEDULE_SUCCESS: 'GET_ALL_WORK_SCHEDULE_SUCCESS',

    CREATE_WORK_SCHEDULE_REQUEST: 'CREATE_WORK_SCHEDULE_REQUEST',
    CREATE_WORK_SCHEDULE_FAILURE: 'CREATE_WORK_SCHEDULE_FAILURE',
    CREATE_WORK_SCHEDULE_SUCCESS: 'CREATE_WORK_SCHEDULE_SUCCESS',

    SET_CURRENT_MONTH: 'SET_CURRENT_MONTH',

    GET_ALL_WORK_SCHEDULE_WORKER_REQUEST: 'GET_ALL_WORK_SCHEDULE_WORKER_REQUEST',
    GET_ALL_WORK_SCHEDULE_WORKER_FAILURE: 'GET_ALL_WORK_SCHEDULE_WORKER_FAILURE',
    GET_ALL_WORK_SCHEDULE_WORKER_SUCCESS: 'GET_ALL_WORK_SCHEDULE_WORKER_SUCCESS',

    GET_ALL_WORK_SCHEDULE_BY_MILL_ID_REQUEST: 'GET_ALL_WORK_SCHEDULE_BY_MILL_ID_REQUEST',
    GET_ALL_WORK_SCHEDULE_BY_MILL_ID_FAILURE: 'GET_ALL_WORK_SCHEDULE_BY_MILL_ID_FAILURE',
    GET_ALL_WORK_SCHEDULE_BY_MILL_ID_SUCCESS: 'GET_ALL_WORK_SCHEDULE_BY_MILL_ID_SUCCESS',

    GEt_ALL_WORK_SCHEDULE_OF_MANUFACTURING_WORK_REQUEST: 'GEt_ALL_WORK_SCHEDULE_OF_MANUFACTURING_WORK_REQUEST',
    GEt_ALL_WORK_SCHEDULE_OF_MANUFACTURING_WORK_FAILURE: 'GEt_ALL_WORK_SCHEDULE_OF_MANUFACTURING_WORK_FAILURE',
    GEt_ALL_WORK_SCHEDULE_OF_MANUFACTURING_WORK_SUCCESS: 'GEt_ALL_WORK_SCHEDULE_OF_MANUFACTURING_WORK_SUCCESS',

    GET_ALL_WORKER_BY_ARRAY_WORK_SCHEDULES_REQUEST: 'GET_ALL_WORKER_BY_ARRAY_WORK_SCHEDULES_REQUEST',
    GET_ALL_WORKER_BY_ARRAY_WORK_SCHEDULES_FAILURE: 'GET_ALL_WORKER_BY_ARRAY_WORK_SCHEDULES_FAILURE',
    GET_ALL_WORKER_BY_ARRAY_WORK_SCHEDULES_SUCCEESS: 'GET_ALL_WORKER_BY_ARRAY_WORK_SCHEDULES_SUCCEESS',

}