export const LinkConstants = {
    GET_LINKS_REQUEST: 'GET_LINKS_REQUEST',
    GET_LINKS_SUCCESS: 'GET_LINKS_SUCCESS',
    GET_LINKS_FAILE: 'GET_LINKS_FAILE',

    GET_LINKS_PAGINATE_REQUEST: 'GET_LINKS_PAGINATE_REQUEST',
    GET_LINKS_PAGINATE_SUCCESS: 'GET_LINKS_PAGINATE_SUCCESS',
    GET_LINKS_PAGINATE_FAILE: 'GET_LINKS_PAGINATE_FAILE',

    SHOW_LINK_REQUEST: 'SHOW_LINK_REQUEST',
    SHOW_LINK_SUCCESS: 'SHOW_LINK_SUCCESS',
    SHOW_LINK_FAILE: 'SHOW_LINK_FAILE',

    CREATE_LINK_REQUEST: 'CREATE_LINK_REQUEST',
    CREATE_LINK_SUCCESS: 'CREATE_LINK_SUCCESS',
    CREATE_LINK_FAILE: 'CREATE_LINK_FAILE',

    EDIT_LINK_REQUEST: 'EDIT_LINK_REQUEST',
    EDIT_LINK_SUCCESS: 'EDIT_LINK_SUCCESS',
    EDIT_LINK_FAILE: 'EDIT_LINK_FAILE',

    DELETE_LINK_REQUEST: 'DELETE_LINK_REQUEST',
    DELETE_LINK_SUCCESS: 'DELETE_LINK_SUCCESS',
    DELETE_LINK_FAILE: 'DELETE_LINK_FAILE',

    CREATE_LINK_ATTRIBUTE_REQUEST: 'CREATE_LINK_ATTRIBUTE_REQUEST',
    CREATE_LINK_ATTRIBUTE_SUCCESS: 'CREATE_LINK_ATTRIBUTE_SUCCESS',
    CREATE_LINK_ATTRIBUTE_FAILE: 'CREATE_LINK_ATTRIBUTE_FAILE'

    // IMPORT_LINK_PRIVILEGE_REQUEST: 'IMPORT_LINK_PRIVILEGE_REQUEST',
    // IMPORT_LINK_PRIVILEGE_SUCCESS: 'IMPORT_LINK_PRIVILEGE_SUCCESS',
    // IMPORT_LINK_PRIVILEGE_FAILE: 'IMPORT_LINK_PRIVILEGE_FAILE',
}