export const ComponentConstants = {
    GET_COMPONENTS_REQUEST: 'GET_COMPONENTS_REQUEST',
    GET_COMPONENTS_SUCCESS: 'GET_COMPONENTS_SUCCESS',
    GET_COMPONENTS_FAILE: 'GET_COMPONENTS_FAILE',

    GET_COMPONENTS_PAGINATE_REQUEST: 'GET_COMPONENTS_PAGINATE_REQUEST',
    GET_COMPONENTS_PAGINATE_SUCCESS: 'GET_COMPONENTS_PAGINATE_SUCCESS',
    GET_COMPONENTS_PAGINATE_FAILE: 'GET_COMPONENTS_PAGINATE_FAILE',

    SHOW_COMPONENT_REQUEST: 'SHOW_COMPONENT_REQUEST',
    SHOW_COMPONENT_SUCCESS: 'SHOW_COMPONENT_SUCCESS',
    SHOW_COMPONENT_FAILE: 'SHOW_COMPONENT_FAILE',

    CREATE_COMPONENT_REQUEST: 'CREATE_COMPONENT_REQUEST',
    CREATE_COMPONENT_SUCCESS: 'CREATE_COMPONENT_SUCCESS',
    CREATE_COMPONENT_FAILE: 'CREATE_COMPONENT_FAILE',

    EDIT_COMPONENT_REQUEST: 'EDIT_COMPONENT_REQUEST',
    EDIT_COMPONENT_SUCCESS: 'EDIT_COMPONENT_SUCCESS',
    EDIT_COMPONENT_FAILE: 'EDIT_COMPONENT_FAILE',

    DELETE_COMPONENT_REQUEST: 'DELETE_COMPONENT_REQUEST',
    DELETE_COMPONENT_SUCCESS: 'DELETE_COMPONENT_SUCCESS',
    DELETE_COMPONENT_FAILE: 'DELETE_COMPONENT_FAILE',

    CREATE_COMPONENT_ATTRIBUTE_REQUEST: 'CREATE_COMPONENT_ATTRIBUTE_REQUEST',
    CREATE_COMPONENT_ATTRIBUTE_SUCCESS: 'CREATE_COMPONENT_ATTRIBUTE_SUCCESS',
    CREATE_COMPONENT_ATTRIBUTE_FAILE: 'CREATE_COMPONENT_ATTRIBUTE_FAILE'
}