export const ProjectPhaseConstants = {
    GET_PROJECT_PHASE_REQUEST: 'GET_PROJECT_PHASE_REQUEST',
    GET_PROJECT_PHASE_SUCCESS: 'GET_PROJECT_PHASE_SUCCESS',
    GET_PROJECT_PHASE_FAIL: 'GET_PROJECT_PHASE_FAIL',
    GET_PERFORM_PHASE_REQUEST: 'GET_PERFORM_PHASE_REQUEST',
    GET_PERFORM_PHASE_SUCCESS: 'GET_PERFORM_PHASE_SUCCESS',
    GET_PERFORM_PHASE_FAIL: 'GET_PERFORM_PHASE_FAIL',
    EDIT_PHASE_REQUEST: 'EDIT_PHASE_REQUEST',
    EDIT_PHASE_SUCCESS: 'EDIT_PHASE_SUCCESS',
    EDIT_PHASE_FAIL: 'EDIT_PHASE_FAIL',
    CREATE_PHASE_REQUEST: 'CREATE_PHASE_REQUEST',
    CREATE_PHASE_SUCCESS: 'CREATE_PHASE_SUCCESS',
    CREATE_PHASE_FAIL: 'CREATE_PHASE_FAIL',
    DELETE_PHASE_REQUEST: 'DELETE_PHASE_REQUEST',
    DELETE_PHASE_SUCCESS: 'DELETE_PHASE_SUCCESS',
    DELETE_PHASE_FAIL: 'DELETE_PHASE_FAIL',
    CREATE_MILESTONE_REQUEST: 'CREATE_MILESTONE_REQUEST',
    CREATE_MILESTONE_SUCCESS: 'CREATE_MILESTONE_SUCCESS',
    CREATE_MILESTONE_FAIL: 'CREATE_MILESTONE_FAIL',
    EDIT_MILESTONE_REQUEST: 'EDIT_MILESTONE_REQUEST',
    EDIT_MILESTONE_SUCCESS: 'EDIT_MILESTONE_SUCCESS',
    EDIT_MILESTONE_FAIL: 'EDIT_MILESTONE_FAIL',
    GET_PROJECT_MILESTONE_REQUEST: 'GET_PROJECT_MILESTONE_REQUEST',
    GET_PROJECT_MILESTONE_SUCCESS: 'GET_PROJECT_MILESTONE_SUCCESS',
    GET_PROJECT_MILESTONE_FAIL: 'GET_PROJECT_MILESTONE_FAIL',
    DELETE_MILESTONE_REQUEST: 'DELETE_MILESTONE_REQUEST',
    DELETE_MILESTONE_SUCCESS: 'DELETE_MILESTONE_SUCCESS',
    DELETE_MILESTONE_FAIL: 'DELETE_MILESTONE_FAIL',
}
