export const CourseConstants = {
    GET_LIST_COURSE_REQUEST: 'GET_LIST_COURSE_REQUEST',
    GET_LIST_COURSE_SUCCESS: 'GET_LIST_COURSE_SUCCESS',
    GET_LIST_COURSE_FAILURE: 'GET_LIST_COURSE_FAILURE',
    
    CREATE_COURSE_REQUEST: 'CREATE_NEW_COURSE_REQUEST',
    CREATE_COURSE_SUCCESS: 'CREATE_NEW_COURSE_SUCCESS',
    CREATE_COURSE_FAILURE: 'CREATE_NEW_COURSE_FAILURE',

    DELETE_COURSE_REQUEST: 'DELETE_COURSE_REQUEST',
    DELETE_COURSE_SUCCESS:  'DELETE_COURSE_SUCCESS',
    DELETE_COURSE_FAILURE:  'DELETE_COURSE_FAILURE',

    UPDATE_COURSE_REQUEST: 'UPDATE_COURSE_REQUEST',
    UPDATE_COURSE_SUCCESS: 'UPDATE_COURSE_SUCCESS',
    UPDATE_COURSE_FAILURE: 'UPDATE_COURSE_FAILURE',
};