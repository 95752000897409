export const commandConstants = {
    GET_ALL_MANUFACTURING_COMMAND_REQUEST: 'GET_ALL_MANUFACTURING_COMMAND_REQUEST',
    GET_ALL_MANUFACTURING_COMMAND_SUCCESS: 'GET_ALL_MANUFACTURING_COMMAND_SUCCESS',
    GET_ALL_MANUFACTURING_COMMAND_FAILURE: 'GET_ALL_MANUFACTURING_COMMAND_FAILURE',

    GET_DETAIL_MANUFACTURING_COMMAND_REQUEST: 'GET_DETAIL_MANUFACTURING_COMMAND_REQUEST',
    GET_DETAIL_MANUFACTURING_COMMAND_SUCCESS: 'GET_DETAIL_MANUFACTURING_COMMAND_SUCCESS',
    GET_DETAIL_MANUFACTURING_COMMAND_FAILURE: 'GET_DETAIL_MANUFACTURING_COMMAND_FAILURE',

    EDIT_MANUFACTURING_COMMAND_REQUEST: 'EDIT_MANUFACTURING_COMMAND_REQUEST',
    EDIT_MANUFACTURING_COMMAND_SUCCESS: 'EDIT_MANUFACTURING_COMMAND_SUCCESS',
    EDIT_MANUFACTURING_COMMAND_FAILURE: 'EDIT_MANUFACTURING_COMMAND_FAILURE',

    GET_NUMBER_COMMAND_REQUEST: 'GET_NUMBER_COMMAND_REQUEST',
    GET_NUMBER_COMMAND_SUCCESS: 'GET_NUMBER_COMMAND_SUCCESS',
    GET_NUMBER_COMMAND_FAILURE: 'GET_NUMBER_COMMAND_FAILURE',

    GET_NUMBER_COMMAND_BY_STATUS_REQUEST: 'GET_NUMBER_COMMAND_BY_STATUS_REQUEST',
    GET_NUMBER_COMMAND_BY_STATUS_SUCCESS: 'GET_NUMBER_COMMAND_BY_STATUS_SUCCESS',
    GET_NUMBER_COMMAND_BY_STATUS_FAILURE: 'GET_NUMBER_COMMAND_BY_STATUS_FAILURE',

    GET_TOP_TEN_PRODUCT_REQUEST: 'GET_TOP_TEN_PRODUCT_REQUEST',
    GET_TOP_TEN_PRODUCT_SUCCESS: 'GET_TOP_TEN_PRODUCT_SUCCESS',
    GET_TOP_TEN_PRODUCT_FAILURE: 'GET_TOP_TEN_PRODUCT_FAILURE',

    GET_FLUCTUATING_PRODUCT_REQUEST: 'GET_FLUCTUATING_PRODUCT_REQUEST',
    GET_FLUCTUATING_PRODUCT_SUCCESS: 'GET_FLUCTUATING_PRODUCT_SUCCESS',
    GET_FLUCTUATING_PRODUCT_FAILURE: 'GET_FLUCTUATING_PRODUCT_FAILURE',
}