export const kpiTemplateConstants = {
    GET_TEMPLATE_KPI_REQUEST: 'GET_TEMPLATE_KPI_REQUEST',
    GET_TEMPLATE_KPI_SUCCESS: 'GET_TEMPLATE_KPI_SUCCESS',
    GET_TEMPLATE_KPI_FAILURE: 'GET_TEMPLATE_KPI_FAILURE',

    GET_KPI_TEMPLATE_BYID_REQUEST: 'GET_KPI_TEMPLATE_BYID_REQUEST',
    GET_KPI_TEMPLATE_BYID_SUCCESS: 'GET_KPI_TEMPLATE_BYID_SUCCESS',
    GET_KPI_TEMPLATE_BYID_FAILURE: 'GET_KPI_TEMPLATE_BYID_FAILURE',

    GET_KPI_TEMPLATE_BYROLE_REQUEST: 'GET_KPI_TEMPLATE_BYROLE_REQUEST',
    GET_KPI_TEMPLATE_BYROLE_SUCCESS: 'GET_KPI_TEMPLATE_BYROLE_SUCCESS',
    GET_KPI_TEMPLATE_BYROLE_FAILURE: 'GET_KPI_TEMPLATE_BYROLE_FAILURE',

    GET_KPI_TEMPLATE_BYUSER_REQUEST: 'GET_KPI_TEMPLATE_BYUSER_REQUEST',
    GET_KPI_TEMPLATE_BYUSER_SUCCESS: 'GET_KPI_TEMPLATE_BYUSER_SUCCESS',
    GET_KPI_TEMPLATE_BYUSER_FAILURE: 'GET_KPI_TEMPLATE_BYUSER_FAILURE',

    ADD_KPI_TEMPLATE_REQUEST: 'ADD_KPI_TEMPLATE_REQUEST',
    ADD_KPI_TEMPLATE_SUCCESS: 'ADD_KPI_TEMPLATE_SUCCESS',
    ADD_KPI_TEMPLATE_FAILURE: 'ADD_KPI_TEMPLATE_FAILURE',

    EDIT_KPI_TEMPLATE_REQUEST: 'EDIT_KPI_TEMPLATE_REQUEST',
    EDIT_KPI_TEMPLATE_SUCCESS: 'EDIT_KPI_TEMPLATE_SUCCESS',
    EDIT_KPI_TEMPLATE_FAILURE: 'EDIT_KPI_TEMPLATE_FAILURE',

    DELETE_KPI_TEMPLATE_REQUEST: 'DELETE_KPI_TEMPLATE_REQUEST',
    DELETE_KPI_TEMPLATE_SUCCESS: 'DELETE_KPI_TEMPLATE_SUCCESS',
    DELETE_KPI_TEMPLATE_FAILURE: 'DELETE_KPI_TEMPLATE_FAILURE',

    IMPORT_KPI_TEMPLATE_REQUEST: 'IMPORT_KPI_TEMPLATE_REQUEST',
    IMPORT_KPI_TEMPLATE_SUCCESS: 'IMPORT_KPI_TEMPLATE_SUCCESS',
    IMPORT_KPI_TEMPLATE_FAILURE: 'IMPORT_KPI_TEMPLATE_FAILURE',
};